<template>
  <div class="QuestionaryReports">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.reports.title') }}</template>
      <template #subtitle>{{ subTitle }}</template>
    </AppPageHead>
    <div class="QuestionaryReports-Wrap">
      <div class="QuestionaryReports-Container">
        <div class="QuestionaryReports-Head">
          <AppListHead>
            <template #actions>
              <BaseSelect
                v-model="sort"
                :items="sortOptions"
                option-key="code"
                option-value="name"
                @input="onSorting"
              >
                <template #caret>
                  <BaseIcon name="switch-vertical" />
                </template>
              </BaseSelect>
              <BaseDropdown>
                <template #toggle>
                  <span
                    class="
                      Button-Filter Button Button--transparent Button--clean Button--width-auto
                    "
                  >
                    {{ $t('filter') }} <BaseIcon class="ms-2" name="filter-lines" />
                  </span>
                </template>
                <AppFilter @filter="onFilter" />
              </BaseDropdown>
            </template>
          </AppListHead>
        </div>
        <b-overlay
          spinner-type="grow"
          spinner-small
          rounded="sm"
          variant="white"
          :show="isReportListLoading"
          class="AppOverlay"
        >
          <div class="QuestionaryReports-List">
            <ReportListItem
              v-for="(report, index) in reportsList"
              :key="index"
              :report="report"
              @report="onShowModalReport(report)"
              @export="onExportReport(report)"
              @send="onShowModalOfSendReport(report)"
            />
          </div>
        </b-overlay>
        <ReportModalSend
          :id="nameModalOfSendReport"
          :key="`report-${reportKey}`"
          :report="currentReport"
          @send="onSendReport"
          @cancel="onHideModalOfSendReport"
        />
        <QuestionaryReport
          :id="nameModalOfReport"
          :report="selectedReport"
          @close="onHideModalReport"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseDropdown from '@/components/Base/BaseDropdown';
import BaseSelect from '@/components/Base/BaseSelect';

import AppFilter from '@/components/App/AppFilter';
import AppPageHead from '@/components/App/AppPageHead';
import AppListHead from '@/components/App/AppListHead';

import QuestionaryReport from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Modal/QuestionaryReport';
import ReportListItem from '@/views/Estimation/Reports/_components/ReportListItem';
import ReportModalSend from '@/views/Estimation/Reports/_components/Modal/ReportModalSend';

import { exportReport } from '@/mixins/DownloadFiles';

import Declension from '@/services/Helpers/HelperDeclension';

export default {
  name: 'AdminReports',
  components: {
    BaseIcon,
    BaseDropdown,
    BaseSelect,
    AppFilter,
    AppPageHead,
    AppListHead,
    ReportListItem,
    ReportModalSend,
    QuestionaryReport,
  },
  mixins: [exportReport],
  data: () => ({
    nameModalOfReport: 'modal-report',
    selectedReport: {},
    reportKey: 0,
    currentReport: {},
    reportsList: [],
    isReportListLoading: false,
    nameModalOfSendReport: 'modal-send-report',
    filter: {},
    search: '',
    sort: 'ASC',
  }),
  computed: {
    subTitle() {
      return `
        ${this.reportsList.length}
        ${Declension(this.reportsList.length, [
          this.$i18n.t('labels.reports[0]'),
          this.$i18n.t('labels.reports[1]'),
          this.$i18n.t('labels.reports[2]'),
        ])}
      `;
    },
    sortOptions() {
      return [
        { name: this.$t('admin.pages.directory.sort-date.asc'), code: 'ASC' },
        { name: this.$t('admin.pages.directory.sort-date.desc'), code: 'DESC' },
      ];
    },
  },
  mounted() {
    this.getReportLocalList();
  },
  methods: {
    ...mapActions({
      filterReportList: 'Reports/filterReportList',
      exportHandler: 'Reports/exportReportByQuestionaryIdAndEmployeeID',
      sendReportByQuestionaryIdAndEmployeeID: 'Reports/sendReportByQuestionaryIdAndEmployeeID',
      getReport: 'Reports/getReportsByQuestionaryIdAndEmployeeId',
    }),
    setStateReportsLoading(state = true) {
      this.isReportListLoading = state;
    },
    getReportLocalList() {
      this.setStateReportsLoading();

      const prepareFilter = {
        ...this.filter,
        ...(this.search ? { q: this.search } : {}),
        ...(this.sort ? { orderBy: this.sort } : {}),
      };

      this.filterReportList({ ...(prepareFilter || {}) }).then((response) => {
        this.reportsList = response;
        this.setStateReportsLoading(false);
      });
    },
    onFilter(filter) {
      this.filter = filter;
      this.getReportLocalList();
    },
    onSorting() {
      this.getReportLocalList();
    },
    onSearch() {
      this.getReportLocalList();
    },
    onExportReport(report) {
      // Метод дергает exportHandler
      this.exportReport(report);
    },
    onShowModalOfSendReport(report) {
      this.currentReport = report;
      this.$bvModal.show(this.nameModalOfSendReport);
    },
    onHideModalOfSendReport() {
      this.$bvModal.hide(this.nameModalOfSendReport);
      this.reportKey++;
    },
    onSendReport(form) {
      this.sendReportByQuestionaryIdAndEmployeeID({
        questionaryId: form.questionaryId,
        employeeId: form.employeeId,
        text: form.text,
        emailList: form.emailList,
      }).then(() => {
        this.$bvModal.hide(this.nameModalOfSendReport);
        this.reportKey++;
      });
    },
    onShowModalReport({ questionaryId, employeeId } = {}) {
      this.getReport({ questionaryId, employeeId }).then((report) => {
        this.selectedReport = report;
        this.$bvModal.show(this.nameModalOfReport);
      });
    },
    onHideModalReport() {
      this.$bvModal.hide(this.nameModalOfReport);
    },
  },
};
</script>
