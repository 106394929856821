<template>
  <div class="RestorePasswordSetNew">
    <RestorePasswordNewPassword v-if="!isResetSuccess" @success="isResetSuccess = $event" />
    <RestorePasswordResetSuccess v-else @success="isResetSuccess = $event" />
  </div>
</template>

<script>
import RestorePasswordNewPassword from '@/views/Auth/_components/RestorePasswordNewPassword';
import RestorePasswordResetSuccess from '@/views/Auth/_components/RestorePasswordResetSuccess';

export default {
  name: 'RestorePasswordSetNew',
  components: { RestorePasswordResetSuccess, RestorePasswordNewPassword },
  data: () => ({
    isResetSuccess: false,
  }),
};
</script>