<template>
  <b-overlay
    spinner-type="grow"
    spinner-small
    rounded="sm"
    variant="white"
    :show="isQuestionaryFormLoading"
    class="AppOverlay"
  >
    <div class="QuestionaryForm">
      <div
        v-click-disable="{ status: questionaryStatus, field: 'method' }"
        class="QuestionaryForm-Section QuestionaryForm-Methodology"
      >
        <div class="SectionName">{{ $t('admin.pages.journal.questionary.method') }}:</div>
        <b-form-radio-group
          v-model="form.method"
          class="Radio"
          :options="structure.questionaryMethods"
          value-field="id"
          text-field="name"
          buttons
          @change="onChangeQuestionaryMethod"
        ></b-form-radio-group>
      </div>
      <div class="QuestionaryForm-Section QuestionaryForm-Evaluation">
        <div class="SectionName">{{ $t('admin.pages.journal.questionary.datePeriod') }}:</div>
        <div class="DatePeriod">
          <BaseDatepicker
            id="dateStart"
            v-model="form.dateStart"
            v-click-disable="{ status: questionaryStatus, field: 'dateStart' }"
            :state="validateStateForm('dateStart')"
            :hide-past-days="true"
          />
          <BaseDatepicker
            id="dateEnd"
            v-model="form.dateEnd"
            :state="validateStateForm('dateEnd')"
            :hide-past-days="true"
          />
        </div>
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'type' }"
        class="QuestionaryForm-Section QuestionaryForm-Type"
      >
        <div class="SectionName">{{ $t('admin.pages.journal.questionary.type.title') }}:</div>
        <b-form-radio-group
          v-model="form.type"
          class="Radio"
          :options="structure.questionaryTypes"
          value-field="id"
          text-field="name"
          buttons
          @change="onChangeQuestionaryType"
        ></b-form-radio-group>
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'type' }"
        class="QuestionaryForm-Section QuestionaryForm-Position"
      >
        <div class="SectionName">{{ formTypeTitles[form.type] }}:</div>
        <div class="SelectList">
          <component
            :is="form.type"
            :key="typeQuestionaryKey"
            :count="countModelEmployeesList"
            :select-option="componentPropsByQuestionaryType.selectOption"
            :exclude-items="excludeParticipants"
            v-bind="componentPropsByQuestionaryType"
            @select-position="onSelectPosition"
            @select-subdivision="onSelectSubdivision"
            @select-surname="onSelectBySurname"
          />
          <QuestionaryEmployeeList
            v-if="form.type && form.type === 'surname'"
            :id="`${form.type}-employee`"
            :type="form.type"
            :items="employeesByQuestionaryType"
            :selected-options="selectedOptionsOfEmployees"
            :exclude-items="excludeParticipants"
            @update-employees="onUpdateEmployeesList"
          />
        </div>
      </div>
      <div
        v-if="form.type && form.type !== 'surname' && employeesByQuestionaryType.length"
        v-click-disable="{ status: questionaryStatus, field: 'employeeList' }"
        class="QuestionaryForm-Section QuestionaryForm-Employees"
      >
        <QuestionaryEmployeeList
          :id="`${form.type}-employee`"
          :type="form.type"
          :items="employeesByQuestionaryType"
          :selected-options="selectedOptionsOfEmployees"
          :exclude-items="excludeParticipants"
          @update-employees="onUpdateEmployeesList"
        />
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'competenceList' }"
        class="QuestionaryForm-Section QuestionaryForm-Competencies"
      >
        <QuestionaryCompetenceList
          :id="`${form.type}-competence`"
          :key="`${form.type}-competence-${competenceKey}`"
          :items="questionaryCompetencies"
          :items-by-positions="questionaryByPositions"
          :selected-options="selectedOptionsOfCompetencies"
          @update-competence="onUpdateCompetenceList"
        />
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'participantList' }"
        class="QuestionaryForm-Section QuestionaryForm-Participants"
      >
        <div
          v-for="(item, index) in participantList"
          :key="`participant-${index}`"
          class="QuestionaryForm-Participant"
        >
          <QuestionaryParticipantList
            :id="`participant-${index}`"
            :questionary-status="questionaryStatus"
            :participant="item"
            :selected-options="selectedOptionsOfParticipants[item.id]"
            :items="employees"
            :exclude-items="excludeParticipants"
            @update-participant-list="updateParticipantList"
          />
        </div>
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'periodType' }"
        class="QuestionaryForm-Section QuestionaryForm-Period"
      >
        <div class="SectionName">{{ $t('admin.pages.journal.questionary.period') }}:</div>
        <QuestionaryPeriod
          :key="`period-${periodQuestionaryKey}`"
          :select-option="form.periodType"
          @select-period="onSelectPeriod"
        />
      </div>
      <div
        v-click-disable="{ status: questionaryStatus, field: 'isAnonymous' }"
        class="QuestionaryForm-Section QuestionaryForm-Other"
      >
        <b-form-checkbox v-model="form.isAnonymous" class="Checkbox">
          {{ $t('admin.pages.journal.questionary.isAnonymous') }}
        </b-form-checkbox>
      </div>
      <div class="QuestionaryForm-Section QuestionaryForm-Actions">
        <BaseActions :actions="formActions" @save="onSubmitForm" @cancel="$emit('cancel')" />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseActions from '@/components/Base/BaseActions';
import BaseDatepicker from '@/components/Base/BaseDatepicker';

import { Questionary } from '@/store/Models';
import QuestionaryTypePosition from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryTypePosition/QuestionaryTypePosition';
import QuestionaryTypeSubdivision from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryTypeSubdivision/QuestionaryTypeSubdivision';
import QuestionaryTypeSurname from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryTypeSurname/QuestionaryTypeSurname';
import QuestionaryPeriod from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryPeriod/QuestionaryPeriod';
import QuestionaryEmployeeList from '@/views/Estimation/Questionary/_components/QuestionaryForm/Items/QuestionaryEmployeeList/QuestionaryEmployeeList';
import QuestionaryCompetenceList from '@/views/Estimation/Questionary/_components/QuestionaryForm/Items/QuestionaryCompetenceList/QuestionaryCompetenceList';
import QuestionaryParticipantList from '@/views/Estimation/Questionary/_components/QuestionaryForm/Items/QuestionaryParticipantList/QuestionaryParticipantList';

// Директивы
import { clickDisable } from '@/services/Directives/clickDisable';

// Справочники
import {
  QuestionaryParticipantsBlocks,
  QuestionaryParticipantsToShow,
} from '@/constants/QuestionaryParticipants';

// Вспомогательные функции
import { ObjectFunctions } from '@/services/Helpers';
import { isNotLessThanDateStart, isNotMoreThanDateEnd } from '@/plugins/VueValidate/Rules';

import { Notification } from '@/services/General/Notification';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'QuestionaryForm',
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseActions,
    position: QuestionaryTypePosition,
    subdivision: QuestionaryTypeSubdivision,
    surname: QuestionaryTypeSurname,
    QuestionaryPeriod,
    QuestionaryEmployeeList,
    QuestionaryCompetenceList,
    QuestionaryParticipantList,
  },
  directives: { clickDisable },
  props: {
    // Модель анкеты
    questionaryModel: {
      type: Object,
      required: true,
    },
    questionaryStatus: {
      type: String,
      required: false,
      default: () => 'new',
    },
  },
  data: () => ({
    isQuestionaryFormLoading: false,
    typeQuestionaryKey: 0,
    periodQuestionaryKey: 0,
    competenceKey: 0,
    // Форма анкеты
    form: {},
    // Копия формы, чтобы при активной анкете была возможность поменять только нужные поля
    shadowForm: {},
    // Props для компонентов position, subdivision и surname
    propertiesOfQuestionaryTypes: {
      position: {
        selectOption: '',
      },
      subdivision: {
        selectOption: '',
      },
      surname: {
        selectOption: '',
      },
    },
    // Какие типы участников принимают участие в анкетировании сотрудников в зависимости от методологии
    participantsBlocks: QuestionaryParticipantsBlocks,
    // Какие типы участников мы можем видеть в списке выбора участников в зависимости от методологии
    participantsBlocksShow: QuestionaryParticipantsToShow,
    // Промежуточный объект, для хранения id участников по типам тестировния
    employeesCollectionByTypes: {},
  }),
  computed: {
    ...mapState('Loading', ['loading']),
    ...mapState('Dictionary', ['employees', 'positions', 'competencies']),
    ...mapGetters({
      structure: 'Directories/structureForQuestionaryCreate',
    }),
    formTypeTitles() {
      return {
        position: this.$t('admin.pages.journal.questionary.type.list.position'),
        subdivision: this.$t('admin.pages.journal.questionary.type.list.subdivision'),
        surname: this.$t('admin.pages.journal.questionary.type.list.surname'),
      };
    },
    formActions() {
      return [{ action: 'cancel' }, { action: 'save', class: 'Button-Main' }];
    },
    // Выбранные сотрудники если редактируем анкету
    selectedOptionsOfEmployees() {
      const employeesIds = this.questionaryModel.employeeList.map(({ id }) => id);
      return this.employees.filter((item) => employeesIds.includes(item.id));
    },
    // Выбранные участники по типу если редактируем анкету
    selectedOptionsOfParticipants() {
      return this.participantList.reduce((result, item) => {
        const ids = this.questionaryModel.participantList.reduce((items, i) => {
          if (i.type === item.id) items.push(i.id);
          return items;
        }, []);
        result[item.id] = this.employees.filter((item) => ids.includes(item.id));
        return result;
      }, {});
    },
    // Выбранные компетенции если редактируем анкету
    selectedOptionsOfCompetencies() {
      const competenciesIds = this.questionaryModel.competenceList.map(({ id }) => id);
      const wasChangePosition =
        this.form.positionId !== this.questionaryModel.positionId || this.competenceKey !== 0;
      const competenciesIdsOfPosition =
        this.form.positionId && wasChangePosition
          ? this.positions
              .filter((item) => item.id === this.form.positionId)[0]
              .competenceList.map(({ id }) => id)
          : [];
      const ids = [
        ...(!wasChangePosition ? competenciesIds : []),
        ...(wasChangePosition ? competenciesIdsOfPosition : []),
      ];
      return this.competencies
        .filter((item) => ids.includes(item.id))
        .map((item) => {
          // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
          return { ...item, name: item?.nameText?.[this.$i18n.locale] ?? item?.name ?? '' };
        });
    },
    // Свойства компонента выбранного типа тестирования
    componentPropsByQuestionaryType() {
      return this.propertiesOfQuestionaryTypes?.[this.form.type] || { selectOption: '' };
    },
    employeeFilterKey() {
      switch (this.form.type) {
        case 'position': {
          return 'positionId';
        }
        case 'subdivision': {
          return 'subdivisionId';
        }
        case 'surname': {
          return 'id';
        }
        default: {
          return '';
        }
      }
    },
    // Список сотрудников по выбранному типу тестирования
    employeesByQuestionaryType() {
      return this.employees.reduce((ids, item) => {
        if (item?.[this.employeeFilterKey] === this.componentPropsByQuestionaryType.selectOption)
          ids.push(item);
        return ids;
      }, []);
    },
    // Подсчет кол-ва сотрудников, которых тестируют
    countModelEmployeesList() {
      return (this.form?.employeeList || '').length || 0;
    },
    // Набор компетенций
    questionaryCompetencies() {
      return this.competencies.map((item) => {
        // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
        return { ...item, name: item?.nameText?.[this.$i18n.locale] ?? item?.name ?? '' };
      });
    },
    // Набор компетенций по должности
    questionaryByPositions() {
      return this.positions.map((item) => item);
    },
    // Список типов участников анкетирования исходя из методологии
    participantList() {
      return (
        this.structure?.participantsTypes.filter(({ id }) => {
          return (this.participantsBlocksShow?.[this.form.method] || []).includes(id) || false;
        }) || []
      );
    },
    // Спискок выбранных участников анкетирования, чтобы исключать повторный выбор по типам участников
    excludeParticipants() {
      return (this.form?.participantList || []).map(({ id }) => id) || [];
    },
  },
  validations: {
    form: {
      dateStart: {
        required,
        isNotMoreThanDateEnd,
      },
      dateEnd: {
        required,
        isNotLessThanDateStart,
      },
    },
  },
  mounted() {
    this.setStateQuestionaryFormLoading();
    this.employeesCollectionByTypes = this.structure.participantsTypes.reduce((items, item) => {
      items[item.id] = [];
      return items;
    }, {});
    // TODO: Пофиксить баг при редактировании анкеты, если обернуть все промисы в all
    Promise.all([this.getCompetenciesList(), this.getPositionsList()]).then(() => {
      this.getSubdivisionsList();
      this.getEmployeesList();
      this.initForm();
      this.setStateQuestionaryFormLoading(false);
      this.$v.$touch();
    });
  },
  methods: {
    ...mapActions('Dictionary', [
      'getPositionsList',
      'getSubdivisionsList',
      'getEmployeesList',
      'getCompetenciesList',
    ]),
    setStateQuestionaryFormLoading(state = true) {
      this.isQuestionaryFormLoading = state;
    },
    // Инициализируем форму исходными данными
    initForm() {
      this.form = ObjectFunctions.deepClone(this.questionaryModel);
      if (this.questionaryStatus === 'inwork') {
        this.shadowForm = ObjectFunctions.deepClone(this.questionaryModel);
      }
      // Если редактируем анкету
      if (this.questionaryModel.positionId) {
        this.updateComponentTypeProperties(this.questionaryModel.positionId, '', '');
      } else if (this.questionaryModel.subdivisionId) {
        this.updateComponentTypeProperties('', this.questionaryModel.subdivisionId, '');
      } else if (
        this.questionaryModel.type === 'surname' &&
        this.questionaryModel.employeeList[0]
      ) {
        this.updateComponentTypeProperties('', '', this.questionaryModel.employeeList[0].id);
      }
      this.periodQuestionaryKey++;
    },
    // Обновляем список участников анкетирования
    updateParticipantList(participant = { type: 'employee', items: [] }) {
      // Получаем список участников из сотрудников
      const employees = (this.form?.employeeList || []).map(({ id }) => id) || [];

      Object.keys(this.employeesCollectionByTypes).map((key) => {
        if (!this.participantsBlocks[this.form.method].includes(key)) {
          this.employeesCollectionByTypes[key] = [];
        }
      });

      this.employeesCollectionByTypes[participant.type] = [
        ...new Set([...participant.items, ...(participant.type === 'employee' ? employees : [])]),
      ];

      this.form.participantList = Object.keys(this.employeesCollectionByTypes).reduce(
        (participants, participantsItem) => {
          const employees = this.employeesCollectionByTypes[participantsItem].reduce(
            (employeesList, employeesItem) => {
              employeesList.push({ id: employeesItem, type: participantsItem });
              return employeesList;
            },
            [],
          );
          return [...new Set([...participants, ...(employees || [])])];
        },
        [],
      );
    },
    // Обновляем props компонентов типа тестирования
    updateComponentTypeProperties(positionId = '', subdivisionId = '', selectSurnameOption = '') {
      this.propertiesOfQuestionaryTypes.position.selectOption = positionId;
      this.propertiesOfQuestionaryTypes.subdivision.selectOption = subdivisionId;
      if (this.form.employeeList && this.form.type === 'surname') {
        this.propertiesOfQuestionaryTypes.surname.selectOption = selectSurnameOption
          ? selectSurnameOption
          : this.form.employeeList[0]
          ? this.form.employeeList[0].id
          : '';
      }
      this.typeQuestionaryKey++;
    },
    // Обновление списка сотрудников, которых будут тестировать
    onUpdateEmployeesList(list) {
      if (this.form.type === 'surname' && list.length === 0) {
        this.propertiesOfQuestionaryTypes.surname.selectOption = '';
        this.typeQuestionaryKey++;
      }
      this.updateModelEmployeeList(list);
      this.updateParticipantList();
    },
    // Обновление списка компетенций
    onUpdateCompetenceList(list = false) {
      let listItems = this.competenceList;
      if (list) listItems = list;
      this.form.competenceList = listItems.map(({ id }) =>
        Questionary.questionaryCompetenceModel(id),
      );
    },
    // Выбор тип тестирования - По должности
    onSelectPosition(value) {
      this.form.positionId = value;
      this.competenceKey++;
      this.updateComponentTypeProperties(value, '', '');
      this.updateModelEmployeeList();
    },
    // Выбор тип тестирования - По подразделению
    onSelectSubdivision(value) {
      this.form.subdivisionId = value;
      this.updateComponentTypeProperties('', value, '');
      this.updateModelEmployeeList();
    },
    // Выбор тип тестирования - По фамилии
    onSelectBySurname(value) {
      this.updateComponentTypeProperties('', '', value);
      this.updateModelEmployeeList();
    },
    // Обновляем список участников после смены методологии
    onChangeQuestionaryMethod() {
      this.updateParticipantList();
    },
    // Очищаем данные после изменения типа тестирования и обновляем список участников.
    onChangeQuestionaryType() {
      this.propertiesOfQuestionaryTypes.surname.selectOption = '';
      this.propertiesOfQuestionaryTypes.position.selectOption = '';
      this.propertiesOfQuestionaryTypes.subdivision.selectOption = '';
      this.form.positionId = null;
      this.form.subdivisionId = null;
      this.updateModelEmployeeList();
      this.updateParticipantList();
    },
    // Обновляем периодичность проведения анкеты
    onSelectPeriod(period) {
      this.form.periodType = period;
    },
    // Обновление списка сотрудников, которых тестируют
    updateModelEmployeeList(list = false) {
      let listItems = this.employeesByQuestionaryType;
      if (list) listItems = list;
      this.form.employeeList = listItems.map(({ id }) => Questionary.questionaryEmployeeModel(id));
    },
    // Проверка state сущности валидатора
    validateStateForm(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    // Сохраняем форму создания заявки и исключаем типы участников, которые не входят в методологию
    onSubmitForm() {
      this.$v.$touch();

      this.form.participantList = (this.form?.participantList || []).filter((item) =>
        (this.participantsBlocks?.[this.form.method] || []).includes(item.type),
      );

      if (!this.form.periodType) {
        delete this.form.periodType;
      }

      // Если анкета "в работе", даем возможность поменять только дату окончания оценивания сотрудников
      // и добавить участников оценки
      if (this.questionaryStatus === 'inwork') {
        this.form = {
          ...ObjectFunctions.deepClone(this.shadowForm),
          dateEnd: this.form.dateEnd,
          participantList: this.form.participantList,
        };
      }
      if (!this.$v.form.$anyError) {
        this.$emit('save', this.form);
      } else {
        Notification.error(this.$t('errors.questionary.create'));
      }
    },
  },
};
</script>
