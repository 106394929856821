<template>
  <BaseSidebar
    v-model="state"
    :class="{ 'BaseSidebar--Wide': ['done', 'archived'].includes(status) }"
  >
    <template #title>{{ title }}</template>
    <QuestionarySelected :questionary="item" @close="onQuestionarySelectedClose" />
  </BaseSidebar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import BaseSidebar from '@/components/Base/BaseSidebar';
import QuestionarySelected from '@/views/Estimation/Questionary/_components/QuestionarySelected';

export default {
  name: 'SelectedQuestionary',
  components: { BaseSidebar, QuestionarySelected },
  computed: {
    ...mapState('Journal', [
      'questionarySelectedItem',
      'questionarySelectedTitle',
      'questionarySelectedState',
    ]),
    ...mapState('Settings', ['brand']),
    item() {
      return this.questionarySelectedItem;
    },
    status() {
      return this.item?.status;
    },
    title() {
      return this.questionarySelectedTitle;
    },
    state: {
      set(v) {
        this.setSelectedQuestionaryState(v);
      },
      get() {
        return this.questionarySelectedState;
      },
    },
  },
  watch: {
    brand: {
      immediate: true,
      handler() {
        this.state = false;
      },
    },
    '$route.name': {
      handler() {
        this.state = false;
      },
    },
  },
  methods: {
    ...mapMutations('Journal', ['setSelectedQuestionaryState']),
    onQuestionarySelectedClose() {
      this.setSelectedQuestionaryState(false);
    },
  },
};
</script>
