<template>
  <div class="RestorePassword">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'RestorePassword',
};
</script>