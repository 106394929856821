<template>
  <QuestionaryForm
    v-if="structure"
    :questionary-model="model"
    @save="actionCreateQuestionary"
    @cancel="onCloseForm"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QuestionaryForm from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryForm';
import { Questionary } from '@/store/Models';

export default {
  name: 'AdminQuestionaryCreate',
  components: {
    QuestionaryForm,
  },
  data: () => ({
    model: Questionary.questionaryModel(),
  }),
  computed: {
    ...mapGetters({
      structure: 'Directories/structureForQuestionaryCreate',
    }),
  },
  methods: {
    ...mapActions('Questionary', ['createQuestionary']),
    ...mapActions('Journal', ['getQuestionaryList']),
    actionCreateQuestionary(questionary) {
      this.createQuestionary(questionary).then(() => {
        this.getQuestionaryList().then(() => {
          this.onCloseForm();
        });
      });
    },
    onCloseForm() {
      this.$emit('close');
    },
  },
};
</script>
