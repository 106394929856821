<template>
  <div class="AppHeaderProfile">
    <span class="AppHeaderProfile-Img" @click="onShowEmployeeProfile">
      <img :src="photoUrl" alt="" title="" />
    </span>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

const defaultPhoto = require('@/assets/Images/profile.png');

export default {
  name: 'AppHeaderProfile',
  data: () => ({
    photoUrl: defaultPhoto,
  }),
  computed: {
    ...mapState('Admin', ['currentUserInfo']),
    ...mapState('Users', ['employeeProfileId', 'isShowingProfile']),
    employeeId() {
      return this.currentUserInfo?.employeeId;
    },
    isCurrentProfileOpened() {
      return this.employeeProfileId === this.employeeId && this.isShowingProfile;
    },
  },
  watch: {
    employeeId: {
      immediate: true,
      handler(v) {
        if (!v) return;
        this.getEmployeeProfile(v).then(({ data }) => {
          if (data.photoUrl) {
            this.photoUrl = data.photoUrl;
          }
        });
      },
    },
  },
  methods: {
    ...mapMutations('Users', ['setStateOfShowingProfile', 'setEmployeeProfileId']),
    ...mapActions('Users', ['getEmployeeProfile']),
    onShowEmployeeProfile() {
      if (this.isCurrentProfileOpened) return;
      this.setStateOfShowingProfile(false);
      setTimeout(() => {
        this.setEmployeeProfileId(this.employeeId);
        this.setStateOfShowingProfile(true);
      }, 200);
    },
  },
};
</script>