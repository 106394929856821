<template>
  <div class="AppNotifications">
    <BaseDropdown>
      <template #toggle>
        <BaseIcon :name="iconNotification" />
      </template>
      <template v-if="notifications.length" #header>
        <div class="AppNotifications-Header">
          <div class="AppNotifications-Header-Left">{{ $t('header.notification.title') }}</div>
          <div class="AppNotifications-Header-Right">
            <span
              class="Button--transparent Button--no-border Button--width-auto Button"
              @click="onShowModalForAll"
            >
              {{ $t('header.notification.button') }} <BaseIcon class="ms-1" name="check-circle" />
            </span>
          </div>
        </div>
      </template>
      <template #default>
        <template v-if="notifications.length">
          <div class="AppNotifications-List ScrollBar--hide">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-for="(notification, index) in notifications"
              :key="index"
              class="AppNotifications-Item"
              :class="{ new: statusNotifications[notification.id] }"
              @click="onShowModalForOne(notification)"
              v-html="notification.text"
            ></div>
            <!--eslint-enable-->
          </div>
        </template>
        <template v-else>
          <div class="AppNotifications-NoList">{{ $t('header.notification.empty') }}</div>
        </template>
      </template>
    </BaseDropdown>
    <AppModalNotification
      :id="notificationModalId"
      :notification="currentNotification"
      :reed-all="reedAll"
      @read="onReedOneNotification"
      @confirm="onReedAllNotifications"
      @cancel="onCancelReedAllNotifications"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseDropdown from '@/components/Base/BaseDropdown';
import AppModalNotification from '@/components/App/AppModalNotification';

export default {
  name: 'AppNotifications',
  components: {
    BaseIcon,
    BaseDropdown,
    AppModalNotification,
  },
  data: () => ({
    statusNotifications: {},
    notificationModalId: 'notification-modal',
    currentNotification: {},
    allNotifications: [],
    reedAll: false,
  }),
  computed: {
    ...mapState('Notifications', ['notifications']),
    hasNoReadNotifications() {
      return Object.values(this.statusNotifications).includes(true);
    },
    iconNotification() {
      return this.hasNoReadNotifications ? 'notification-active' : 'notification';
    },
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    ...mapActions('Notifications', ['getNotificationsList', 'updateNotificationStatus']),
    getNotifications() {
      this.getNotificationsList().then(() => {
        this.statusNotifications = this.notifications.reduce((acc, i) => {
          acc[i.id] = true;
          return acc;
        }, {});
      });
    },
    onShowModalForOne(notification) {
      if (this.statusNotifications[notification.id]) {
        this.currentNotification = notification;
        this.reedAll = false;
        this.$bvModal.show(this.notificationModalId);
      }
    },
    onShowModalForAll() {
      this.allNotifications = this.notifications.map((i) => i.id);
      this.reedAll = true;
      this.$bvModal.show(this.notificationModalId);
    },
    onCancelReedAllNotifications() {
      this.$bvModal.hide(this.notificationModalId);
    },
    onReedOneNotification() {
      this.triggerUpdateStatusNotifications([this.currentNotification.id]);
    },
    onReedAllNotifications() {
      this.triggerUpdateStatusNotifications(this.allNotifications);
    },
    triggerUpdateStatusNotifications(ids) {
      this.updateNotificationStatus(ids).then(() => {
        this.$bvModal.hide(this.notificationModalId);
        ids.map((id) => (this.statusNotifications[id] = false));
      });
    },
  },
};
</script>