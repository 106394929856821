export const EmployeeWorkDays = [
  {
    key: 'monday',
  },
  {
    key: 'tuesday',
  },
  {
    key: 'wednesday',
  },
  {
    key: 'thursday',
  },
  {
    key: 'friday',
  },
  {
    key: 'saturday',
  },
  {
    key: 'sunday',
  },
];

function buildHours() {
  const data = [];
  let hours = 0;
  let minutes = 0;

  for (let i = 0; i < 48; i++) {
    const h = hours < 10 ? `0${hours}` : hours;
    const m = minutes < 10 ? `0${minutes}` : minutes;

    data.push({
      value: `${h}:${m}`,
      unixValue: (1800000 * i) / 1000 + 1,
    });

    if (minutes === 0) {
      minutes += 30;
    } else {
      minutes -= 30;
      hours += 1;
    }
  }

  return data;
}

export const EmployeeWorkHours = buildHours();
