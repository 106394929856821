import { TranslitFunctions } from '@/services/Helpers';
import { Notification } from '@/services/General/Notification';

import { unixToDate } from '@/services/Filters';

export const exportReport = {
  methods: {
    exportReport({
      questionaryId = '',
      employeeId = '',
      subject = '',
      method = '',
      dateStart = '',
      dateEnd = '',
    } = {}) {
      if (typeof this.exportHandler === 'function') {
        this.exportHandler({
          questionaryId,
          employeeId,
        }).then((response) => {
          const fileName = `${subject} method ${method} ${unixToDate(dateStart)} ${unixToDate(
            dateEnd,
          )}.pdf`;
          const fileURL = window.URL.createObjectURL(response);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', TranslitFunctions.russianToLatin(fileName));
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        });
      }
    },
  },
};

export const getExampleFile = {
  methods: {
    getExampleUploadFile() {
      if (typeof this.getExampleHandler === 'function') {
        this.getExampleHandler().then((response) => {
          const fileName = 'example.csv';
          const fileURL = window.URL.createObjectURL(response);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        });
      }
    },
  },
};
