<template>
  <div v-if="employeeProfileId" class="Tab Tab-General">
    <BaseProfileBlock>
      <template #head>
        <div class="Title">
          {{ $t('profile.company') }}
        </div>
        <div v-if="isCanEditGeneral" class="Action">
          <span class="Button--reset Button" @click="isEditGeneral = !isEditGeneral">
            <BaseIcon v-if="!isEditGeneral" width="12" height="12" name="edit" />
            {{ $t(`base-actions.${!isEditGeneral ? 'edit' : 'cancel'}`) }}
          </span>
        </div>
      </template>
      <template v-if="employeeGeneral">
        <GeneralTabInfo v-if="!isEditGeneral" :user="employeeGeneral" />
        <GeneralTabInfoForm
          v-else
          :user="employeeGeneral"
          @close="isEditGeneral = false"
          @save="saveProfileGeneralTab"
        />
      </template>
    </BaseProfileBlock>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseProfileBlock from '@/components/Base/BaseProfileBlock';

import GeneralTabInfo from '@/components/Modules/Profile/_components/GeneralTabInfo';
import GeneralTabInfoForm from '@/components/Modules/Profile/_components/GeneralTabInfoForm';

import { ModelProfile } from '@/store/Models';

export default {
  name: 'TabGeneral',
  components: {
    BaseIcon,
    BaseProfileBlock,
    GeneralTabInfo,
    GeneralTabInfoForm,
  },
  data: () => ({
    employeeGeneral: null,
    isEditGeneral: false,
  }),
  computed: {
    ...mapState('Users', ['employeeProfileId']),
    ...mapState('Users', ['employeeProfileId']),
    ...mapState('Admin', ['currentUserInfo']),
    ...mapGetters('Users', ['userAvailableRoutes']),
    currentEmployeeId() {
      return this.currentUserInfo?.employeeId;
    },
    isCanAnyUploadGeneral() {
      return this.checkPermissions(['directory_employee_updatecompanyemployeeinfo__invoke']);
    },
    isCanCurrentUploadGeneral() {
      return (
        this.checkPermissions(['directory_employee_updatecurrentcompanyemployeeinfo__invoke']) &&
        this.employeeProfileId === this.currentEmployeeId
      );
    },
    isCanEditGeneral() {
      return this.isCanAnyUploadGeneral || this.isCanCurrentUploadGeneral;
    },
  },
  mounted() {
    this.getEmployeeGeneralData();
  },
  methods: {
    ...mapActions('Users', [
      'getEmployeeGeneralProfile',
      'updateAnyEmployeeGeneral',
      'updateEmployeeGeneral',
    ]),
    getEmployeeGeneralData() {
      this.getEmployeeGeneralProfile(this.employeeProfileId).then(({ data }) => {
        this.employeeGeneral = ModelProfile.ProfilePrepareData(data);
        this.isEditGeneral = false;
      });
    },
    saveProfileGeneralTab(form) {
      let action = 'updateAnyEmployeeGeneral';

      if (this.employeeProfileId === this.currentEmployeeId) {
        action = 'updateEmployeeGeneral';
      }

      const data = ModelProfile.GeneralUploadData(form);

      this[action](data).then(() => {
        this.getEmployeeGeneralData();
      });
    },
    checkPermissions(permissions) {
      return this.userAvailableRoutes
        ? permissions.some((i) => this.userAvailableRoutes.includes(i))
        : false;
    },
  },
};
</script>
