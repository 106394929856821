<template>
  <div class="BaseSidebar">
    <div v-if="isSideBarOpen" class="BaseSidebar-Backdrop" @click="onCloseSideBar"></div>
    <transition name="slide">
      <div v-if="isSideBarOpen" class="BaseSidebar-Container">
        <div
          class="BaseSidebar-Header"
          :class="{ 'BaseSidebar-Header--WithActions': $scopedSlots.actions }"
        >
          <div class="BaseSidebar-Close" @click="onCloseSideBar">
            <slot v-if="$scopedSlots.closeButton" name="closeButton"></slot>
            <template v-else>
              <span class="Button--reset Button">
                <BaseIcon name="x-close" />
              </span>
            </template>
          </div>
          <div v-if="$scopedSlots.title" class="BaseSidebar-Title">
            <slot name="title"></slot>
          </div>
          <div v-if="$scopedSlots.actions" class="BaseSidebar-Actions">
            <slot name="actions"></slot>
          </div>
        </div>
        <div class="BaseSidebar-Body ScrollBar--hide">
          <slot name="default"></slot>
        </div>
        <div v-if="$scopedSlots.footer" class="BaseSidebar-Footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'BaseSidebar',
  components: { BaseIcon },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isSideBarOpen: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.isSideBarOpen = v;
        v ? this.$IOSBlockScroll() : this.$IOSReturnScroll();
      },
    },
  },
  methods: {
    onCloseSideBar() {
      this.$emit('input', false);
    },
  },
};
</script>