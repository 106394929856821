<template>
  <div class="UsersListHead">
    <AppListHead>
      <template #actions>
        <span class="Button-Main Button OnlyDesktop" @click="$emit('create')">
          <BaseIcon name="plus" width="16" height="16" />
          <span class="name ps-2">{{ $t('base-actions.create') }}</span>
        </span>
      </template>
    </AppListHead>
    <span class="Button-Create Button-Main Button" @click="$emit('create')">
      <BaseIcon name="plus" height="24" width="24" />
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import AppListHead from '@/components/App/AppListHead';

export default {
  name: 'UsersListHead',
  components: {
    BaseIcon,
    AppListHead,
  },
};
</script>