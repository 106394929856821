export const clickOutside = {
  bind: function (el, binding, vNode) {
    el.__vueClickOutside__ = (event) => {
      // Todo: На подумать...
      // Костыль, чтобы не отслеживать клик вне области родителя, если есть Datepicker и мы вбрали дату в календаре
      if (event.target.parentElement !== null) {
        const isDatePicker = event.target.parentElement.getAttribute('aria-current') === 'date';
        if (!el.contains(event.target) && !isDatePicker) {
          // call method provided in v-click-outside value
          vNode.context[binding.expression](event);
          event.stopPropagation();
        }
      }
    };
    document.body.addEventListener('click', el.__vueClickOutside__);
  },
  unbind: function (el, binding, vNode) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
};
