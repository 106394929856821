import { Api } from '@/services/General/Api';
import { ENDPOINTS_TESTING } from '@/services/Endpoints';
import { ENDPOINTS_DICTIONARY } from '@/services/Endpoints';
import { Notification } from '@/services/General/Notification';
import router from '@/store/Modules/Testing';

export default {
  namespaced: true,
  state: {
    competencies: null,
    employee: {},
    questionary: null,
  },
  mutations: {
    setQuestionary(state, questionary) {
      state.questionary = questionary;
    },
    setCompetencies(state, competencies) {
      state.competencies = competencies;
    },
    setEmployee(state, employee) {
      state.employee = employee;
    },
  },
  actions: {
    async testingStart({ commit }, { token = '' } = {}) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_TESTING.TESTING_START,
          data: {
            payload: {
              params: { token },
            },
          },
        }).then(({ data } = {}) => {
          if (data.code && data.code !== 0) {
            Notification.error(data?.message || '');
            router.push({ name: 'home' });
          }

          commit('setQuestionary', data?.data || data);
        });
      } catch (error) {
        Notification.error(error);
        throw new Error(error);
      }
    },
    async testingStartNew({ commit }, { employeeId = '', questionaryId = '' } = {}) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_TESTING.TESTING_START_NEW,
          data: {
            payload: {
              params: {
                [`filterList[employeeId]`]: employeeId,
                [`filterList[questionaryId]`]: questionaryId,
              },
            },
          },
        }).then(({ data } = {}) => {
          commit('setCompetencies', data);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getEmployee({ commit }, { employeeId = '' } = {}) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_GET_LIST,
          data: {
            payload: {
              params: {
                employeeId,
              },
            },
          },
        }).then(({ data = [] } = {}) => {
          commit('setEmployee', data?.[0] || []);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async sendResult(_, result = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_TESTING.TESTING_RESULT,
          data: {
            payload: result,
          },
          notify: false,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
