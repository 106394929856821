<template>
  <div class="Legal">
    <AppDefaultLayoutHeader />
    <div class="Legal-Navigation">
      <AppLegalNavigation />
    </div>
    <div class="Legal-Content">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppLegalNavigation from '@/components/App/AppLegalNavigation';
import AppDefaultLayoutHeader from '@/components/App/AppDefaultLayoutHeader';

export default {
  name: 'Legal',
  components: {
    AppDefaultLayoutHeader,
    AppLegalNavigation,
  },
};
</script>