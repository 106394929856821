import { HelperRegEx } from '@/services/Helpers/HelperRegEx';

export const HelperStoreActions = {
  addActionToCallStack: (action, store) => {
    if (
      HelperRegEx.searchMatchOfWhiteListActions(
        action,
        store.state.Loading.callStackWhiteListPrefixOfActions,
      )
    ) {
      if (store.state.Loading.callStack.length === 0) {
        store.commit('Loading/setLoading', true, { root: true });
      }
      store.commit('Loading/addActionToCallStack', action, {
        root: true,
      });
    }
  },
  removeActionFromCallStack: (action, store) => {
    store.commit('Loading/removeActionFromCallStack', action, {
      root: true,
    });
    if (store.state.Loading.callStack.length === 0) {
      store.commit('Loading/setLoading', false, { root: true });
    }
  },
};
