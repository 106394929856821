<template>
  <div class="RestorePasswordNewPassword">
    <RestorePasswordContent
      :icon="'key'"
      :title="$t('restore-password.titles.setNewPassword')"
      :text="$t('restore-password.texts.setNewPassword')"
    />
    <b-form class="RestorePasswordForm" novalidate @submit.stop.prevent="onSubmit">
      <b-form-group
        id="form-password-group"
        label-class="Label mb-1"
        :label="`${$t('labels.password')}:`"
        label-for="form-password"
        class="mb-4"
      >
        <div class="RestorePasswordForm-InputWithIcon">
          <b-form-input
            id="form-password"
            v-model.trim.lazy="$v.form.password.$model"
            class="Input Medium"
            :class="{ 'Input--error': !validateState('password') && $v.form.password.$model }"
            :type="isShowPassword ? 'text' : 'password'"
            autocomplete="off"
            :placeholder="`${$t('placeholders.enter-password')}`"
          ></b-form-input>
          <span class="RestorePasswordForm-PasswordIcon" @click="isShowPassword = !isShowPassword">
            <BaseIcon :name="isShowPassword ? 'eye' : 'eye-close'" fill stroke />
          </span>
        </div>
        <AppRestorePasswordTooltip
          target="form-password"
          triggers="focus"
          custom-class="Tooltip"
          :items="passwordValidationInfo"
          :valid-field="$v.form.password"
        />
      </b-form-group>
      <b-form-group
        id="form-confirmPassword-group"
        label-class="Label mb-1"
        :label="`${$t('labels.confirmPassword')}:`"
        label-for="form-confirmPassword"
        class="mb-4"
      >
        <div class="RestorePasswordForm-InputWithIcon">
          <b-form-input
            id="form-confirmPassword"
            v-model.trim.lazy="$v.form.confirmPassword.$model"
            class="Input Medium"
            :class="{
              'Input--error': !validateState('confirmPassword') && $v.form.confirmPassword.$model,
            }"
            :type="isShowConfirmPassword ? 'text' : 'password'"
            autocomplete="off"
            :placeholder="`${$t('placeholders.confirmPassword')}`"
          ></b-form-input>
          <span
            class="RestorePasswordForm-PasswordIcon"
            @click="isShowConfirmPassword = !isShowConfirmPassword"
          >
            <BaseIcon :name="isShowConfirmPassword ? 'eye' : 'eye-close'" fill stroke />
          </span>
        </div>
        <AppRestorePasswordTooltip
          target="form-confirmPassword"
          triggers="focus"
          custom-class="Tooltip"
          :items="confirmValidationInfo"
          :valid-field="$v.form.confirmPassword"
        />
      </b-form-group>
      <b-button :disabled="$v.form.$anyError" type="submit" class="Button Button-Main Full">
        {{ $t('base-actions.resetPassword') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

import RestorePasswordContent from '@/views/Auth/_components/RestorePasswordContent';
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import AppRestorePasswordTooltip from '@/components/App/AppRestorePasswordTooltip';
export default {
  name: 'RestorePasswordNewPassword',
  components: { AppRestorePasswordTooltip, RestorePasswordContent, BaseIcon },
  data: () => ({
    isShowPassword: false,
    isShowConfirmPassword: false,
    form: {
      password: '',
      confirmPassword: '',
    },
  }),
  computed: {
    ...mapGetters('Settings', ['isDesktop']),
    passwordValidationInfo() {
      return [
        { errorType: 'containsLatin', text: this.$t('tooltipErrors.containsLatin') },
        { errorType: 'minLength', text: this.$t('tooltipErrors.minLength') },
        { errorType: 'containsSpecial', text: this.$t('tooltipErrors.containsSpecial') },
        { errorType: 'containsUppercase', text: this.$t('tooltipErrors.containsUppercase') },
      ];
    },
    confirmValidationInfo() {
      return [{ errorType: 'sameAsPassword', text: this.$t('tooltipErrors.sameAsPassword') }];
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: async (value) => {
          return value.length >= 8;
        },
        containsUppercase: async (value) => {
          return /^(?=.*[A-Z])/.test(value);
        },
        containsLatin: async (value) => {
          return /^[a-zA-Z0-9!"#$%&'()*+,-.\/:;><?=@\[\]\\^`{|}~\]_]+$/.test(value);
        },
        containsSpecial: async (value) => {
          return /^(?=.*[!"#$%&'()*+,-.\/:;><?=@\[\]\\^`{|}~\]_])/.test(value);
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    ...mapActions('Admin', ['restorePassword']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        const { token = '' } = this.$route.params || {};
        this.restorePassword({ ...this.form, token }).then(() => {
          this.$emit('success', true);
        });
      } else return false;
    },
  },
};
</script>