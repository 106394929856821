<template>
  <div class="ProfileInfo ProfileInfo-Data">
    <ProfileUserPhoto :src="userPhotoUrl" class="ProfileInfo-Photo" />
    <div class="ProfileInfo-User">
      <div class="ProfileInfo-Fio">{{ userName }}</div>
      <div v-if="userLocation" class="ProfileInfo-Location">
        <BaseIcon name="marker-pin" />
        {{ userLocation }}
      </div>
      <div
        v-if="false /* Включить, как статусы будут делаться отдельно задачей */"
        class="ProfileInfo-Status"
        :class="{ 'ProfileInfo-Status--Empty': !userStatus }"
      >
        <template v-if="userStatus">
          {{ userStatusEmoji }}
          {{ userStatus }}
          {{ userStatusStartEnd }}
        </template>
        <template v-else>
          {{ $t('profile.emptyStatus') }}
        </template>
      </div>
    </div>
    <div class="ProfileInfo-Additional">
      <InfoRow class="ProfileInfo-WorkTime">
        <template #label>
          {{ $t('profile.workTime') }}
        </template>
        <template #default>
          <template v-if="isUserWorkTime">
            <div v-if="userStartWorkDay && userEndWorkDay" class="Days">
              {{ userStartWorkDay }} - {{ userEndWorkDay }}
            </div>
            <div v-if="userStartWorkTime && userEndWorkTime" class="Hours">
              {{ userStartWorkTime }} - {{ userEndWorkTime }}
            </div>
          </template>
          <template v-else>
            <span class="NotIndicated">
              {{ $t('profile.not-indicated') }}
            </span>
          </template>
        </template>
      </InfoRow>
      <InfoRow class="ProfileInfo-Position">
        <template #label>
          {{ $t('profile.position') }}
        </template>
        <template v-if="userPosition" #default>
          <template>
            {{ userPosition }}
          </template>
        </template>
      </InfoRow>
      <InfoRow class="ProfileInfo-Email">
        <template #label>
          {{ $t('profile.email') }}
        </template>
        <template v-if="userEmail" #default>
          <div class="Copy" @click="onCopyValue('userEmail', userEmail)">
            <span ref="userEmail">{{ userEmail }}</span>
            <BaseIcon name="copy-2" />
          </div>
        </template>
      </InfoRow>
      <InfoRow class="ProfileInfo-Phone">
        <template #label>
          {{ $t('profile.phoneNumber') }}
        </template>
        <template v-if="userPhoneNumber" #default>
          <div class="Copy" @click="onCopyValue('userPhone', userPhoneNumber)">
            <span ref="userPhone">{{ userPhoneNumber }}</span>
            <BaseIcon name="copy-2" />
          </div>
        </template>
      </InfoRow>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import ProfileUserPhoto from '@/components/Modules/Profile/_components/ProfileUserPhoto';
import InfoRow from '@/components/Modules/Profile/_components/InfoRow';

import { unixToDate } from '@/services/Filters';
import { EmployeeWorkHours } from '@/constants/EmployeeWorkMode';

const defaultPhoto = require('@/assets/Images/profile.png');

export default {
  name: 'ProfileInfo',
  components: { BaseIcon, ProfileUserPhoto, InfoRow },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userPhotoUrl() {
      return this.user?.photoUrl || defaultPhoto;
    },
    userName() {
      return `${this.user?.lastName} ${this.user?.firstName}`;
    },
    userLocation() {
      return this.user?.location || '';
    },
    userStatusEmoji() {
      return this.user?.statusEmoji || '';
    },
    userStatus() {
      return this.user?.status || '';
    },
    userStatusStartEnd() {
      const start = unixToDate(this.user?.statusStartAt || '');
      const end = unixToDate(this.user?.statusEndAt || '');
      return (start && end && `${start} - ${end}`) || '';
    },
    userStartWorkDay() {
      return this.user?.startWorkDayAt
        ? this.$t(`profile.workDaysShort.${this.user?.startWorkDayAt}`)
        : '';
    },
    userEndWorkDay() {
      return this.user?.endWorkDayAt
        ? this.$t(`profile.workDaysShort.${this.user?.endWorkDayAt}`)
        : '';
    },
    userStartWorkTime() {
      return (
        EmployeeWorkHours.find(({ unixValue }) => unixValue === (this.user?.startWorkTimeAt || 0))
          ?.value || ''
      );
    },
    userEndWorkTime() {
      return (
        EmployeeWorkHours.find(({ unixValue }) => unixValue === (this.user?.endWorkTimeAt || 0))
          ?.value || ''
      );
    },
    isUserWorkTime() {
      return (
        (this.userStartWorkDay && this.userEndWorkDay) ||
        (this.userStartWorkTime && this.userEndWorkTime)
      );
    },
    userPosition() {
      return this.user?.position || '';
    },
    userEmail() {
      return this.user?.email || '';
    },
    userPhoneNumber() {
      return this.user?.phone || '';
    },
  },
  methods: {
    onCopyValue(nameRef, value) {
      const container = this.$refs[nameRef][0] || this.$refs[nameRef];
      this.$copyText(`${value}`, container);
    },
  },
};
</script>