<template>
  <div class="ListQuestions">
    <div v-for="(question, index) in items" :key="question.id" class="ListQuestions-Item">
      <div class="ListQuestions-Number">{{ index + 1 }}.</div>
      <div class="ListQuestions-Question">
        {{ chooseQuestionName(question) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DictionaryListQuestions',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    chooseQuestionName(question) {
      // Todo: Поле item.text планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
      return question?.questionText?.[this.$i18n.locale] ?? question?.text ?? '';
    },
  },
};
</script>
