<template>
  <div class="ListCompetencies">
    <div v-for="(competence, index) in items" :key="competence.id" class="ListCompetencies-Item">
      <div class="ListCompetencies-Number">{{ index + 1 }}.</div>
      <div class="ListCompetencies-Name">
        {{ chooseCompetenceName(competence) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DictionaryListCompetencies',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    chooseCompetenceName(competence) {
      // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
      return competence?.nameText?.[this.$i18n.locale] ?? competence?.name ?? '';
    },
  },
};
</script>
