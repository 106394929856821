<template>
  <BaseSelect
    v-model="language"
    class="AppLangSwitcher"
    :items="languages"
    option-key="code"
    option-value="title"
  >
    <template #selected="{ item }">
      <div class="AppLangSwitcher-Item">
        <div class="AppLangSwitcher-Icon">
          <BaseIcon no-fill no-stroke height="21" width="21" :name="item.icon" source="language" />
        </div>
        <div class="AppLangSwitcher-Value">
          {{ item.title }}
        </div>
      </div>
    </template>
    <template #item="{ item }">
      <div class="AppLangSwitcher-Item">
        <div class="AppLangSwitcher-Icon">
          <BaseIcon no-fill no-stroke height="21" width="21" :name="item.icon" source="language" />
        </div>
        <div class="AppLangSwitcher-Value">
          {{ item.title }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseSelect from '@/components/Base/BaseSelect';

import { languages } from '@/constants/SiteLanguages';

import { clickOutside } from '@/services/Directives/clickOutside';

export default {
  name: 'AppLangSwitcher',
  components: { BaseIcon, BaseSelect },
  directives: {
    clickOutside,
  },
  data: () => ({
    language: '',
    languages,
  }),
  computed: {
    ...mapState({
      currentUserInfo: (state) => state.Admin.currentUserInfo,
    }),
    currentLanguage() {
      return this.languages.find((language) => language?.code === this.$i18n.locale);
    },
  },
  watch: {
    language(v) {
      v !== this.$i18n.locale && this.changeLocale(v);
    },
  },
  mounted() {
    this.language = this.$i18n.locale;
  },
  methods: {
    ...mapActions({
      updateLocale: 'Users/updateUserLocale',
    }),
    changeLocale(locale) {
      if (this.currentUserInfo.id && this.currentUserInfo.email) {
        const { id } = this.currentUserInfo;
        this.updateLocale({ id, locale }).then(() => {
          this.setLocale(locale);
        });
      } else {
        this.setLocale(locale);
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
    },
  },
};
</script>