<template>
  <b-form-group :id="`form-${id}-group`" :label-class="labelClass" :label="label" class="mb-0">
    <b-input-group class="Datepicker" :class="{ 'Datepicker--Invalid': !state }">
      <b-form-input
        :id="`input-${id}`"
        v-model="input"
        v-mask="'##-##-####'"
        :disabled="disabled"
        class="Input Medium"
        :class="{ 'Input--disabled': disabled }"
        type="text"
        placeholder="DD-MM-YYYY"
        autocomplete="off"
        @change="onChange"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          v-model="model"
          :date-disabled-fn="dateDisabled"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :state="state"
          :disabled="disabled"
          :locale="locale"
          :aria-controls="`input-${id}`"
          button-only
          left
          hide-header
          label-nav=""
          label-help=""
          class="Datepicker-Calendar"
          @input="onChangeCalendar"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { vmoment } from '@/services/Helpers';

export default {
  name: 'BaseDatepicker',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: () => '',
    },
    labelClass: {
      type: String,
      required: false,
      default: () => 'mb-1',
    },
    state: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hidePastDays: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    input: '',
    model: '',
  }),
  computed: {
    locale() {
      return this.$i18n.locale || 'ru';
    },
  },
  watch: {
    value(v) {
      this.model = v ? v : '';
      this.input = v ? vmoment(v).format('DD-MM-YYYY') : '';
    },
  },
  mounted() {
    this.model = this.value || '';
    this.input = this.value ? vmoment(this.value).format('DD-MM-YYYY') : '';
  },
  methods: {
    dateDisabled(ymd) {
      if (!this.hidePastDays) return false;
      const nowDate = vmoment().startOf('day').unix();
      const calendarDate = vmoment(ymd).startOf('day').unix();
      return nowDate - calendarDate > 0;
    },
    onChange(value) {
      if (value) {
        const date = value.split('-');
        this.$emit('input', vmoment(`${date?.[2]}-${date?.[1]}-${date?.[0]}`).format('YYYY-MM-DD'));
      } else {
        this.$emit('input', '');
      }
    },
    onChangeCalendar(value) {
      this.$emit('input', value);
    },
  },
};
</script>