import Vue from 'vue';

const Themes = {
  install(Vue) {
    Vue.prototype.$changeTheme = (theme) => {
      localStorage.setItem('theme', theme);
      document.documentElement.setAttribute('data-theme', theme);
    };
    Vue.prototype.$changeBrand = (brand) => {
      localStorage.setItem('brand', brand);
      document.documentElement.setAttribute('data-brand', brand);
    };
  },
};

Vue.use(Themes);
