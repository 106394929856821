<template>
  <div class="Competencies">
    <DictionaryListHead
      type="competencies"
      @create="onCreateDictionaryItem"
      @filter="onFilterDictionaryItems"
    ></DictionaryListHead>
    <div class="ItemsList">
      <DictionaryListItem v-for="item in list" :key="item.id">
        <template #title>
          <span v-b-toggle="`Collapse-${item.id}`" class="Toggle">
            {{ chooseName(item) }}
          </span>
        </template>
        <template #short-description>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="chooseDescription(item)"></div>
          <!--eslint-enable-->
        </template>
        <template v-if="item.questionList" #full-description>
          <b-collapse :id="`Collapse-${item.id}`">
            <DictionaryListQuestions :items="item.questionList" />
          </b-collapse>
        </template>
        <template #actions>
          <DictionaryListActions
            type="competencies"
            @copy="copyDictionaryItem(item.id)"
            @edit="editDictionaryItem(item.id)"
            @delete="showConfirmAction(item.id)"
          />
        </template>
      </DictionaryListItem>
    </div>
    <DictionaryFormCreateCompetencies
      :id="nameModalOfAddDictionaryItem"
      :action-type="updateOrSave"
      :item-model="itemDictionaryModel"
      @save="saveDictionaryItem"
      @update="updateDictionaryItem"
      @cancel="closeDictionaryModal"
    />
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.delete')"
      @confirm="confirmModalAction"
      @cancel="hideConfirmAction"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import { mapState, mapActions } from 'vuex';
import { DictionaryTab } from '@/mixins/DictionaryTab';
import DictionaryListHead from '@/views/Estimation/Dictionary/_components/List/DictionaryListHead';
import DictionaryListItem from '@/views/Estimation/Dictionary/_components/List/DictionaryListItem';
import DictionaryListActions from '@/views/Estimation/Dictionary/_components/List/DictionaryListActions';
import DictionaryListQuestions from '@/views/Estimation/Dictionary/_components/List/DictionaryListQuestions';
import DictionaryFormCreateCompetencies from '@/views/Estimation/Dictionary/_components/Form/DictionaryFormCreateCompetencies';
import AppModalConfirm from '@/components/App/AppModalConfirm';

export default {
  name: 'DictionaryTabCompetencies',
  components: {
    DictionaryListHead,
    DictionaryListItem,
    DictionaryListActions,
    DictionaryListQuestions,
    DictionaryFormCreateCompetencies,
    AppModalConfirm,
  },
  mixins: [DictionaryTab],
  data: () => ({
    nameModalOfAddDictionaryItem: 'modal-add-competence',
    nameModalOfConfirmAction: 'modal-confirm-competence',
    targetDeleteDictionaryItem: null,
    itemDictionaryModel: {},
  }),
  computed: {
    ...mapState('Dictionary', ['competencies']),
    dictionaryModel() {
      return this.models.competenciesModel;
    },
    dictionaryCopyModel() {
      return this.models.competenciesCopyModel;
    },
  },
  watch: {
    competencies() {
      this.updateLocalList();
    },
  },
  created() {
    this.updateLocalList();
  },
  mounted() {
    this.getList().then(() => {
      this.itemDictionaryModel = this.dictionaryModel();
    });
  },
  methods: {
    ...mapActions({
      getList: 'Dictionary/getCompetenciesList',
      createItem: 'Dictionary/createCompetenciesItem',
      updateItem: 'Dictionary/updateCompetenciesItem',
      deleteItem: 'Dictionary/deleteCompetenciesItem',
      filterList: 'Dictionary/getFilterCompetenciesList',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.competencies);
    },
    chooseName(item) {
      // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
      return item?.nameText?.[this.$i18n.locale] ?? item?.name ?? '';
    },
    chooseDescription(item) {
      // Todo: Поле item.description планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
      return item?.descriptionText?.[this.$i18n.locale] ?? item?.description ?? '';
    },
  },
};
</script>
