export const WhiteListPrefix = [
  'testingStart',
  'sendResult',
  'loginUser',
  'logoutUser',
  'create',
  'update',
  'delete',
  'sendReport',
  'getCurrentUserInfo',
  'getAvailableRoutes',
  'getQuestionaryStructure',
];
