<template>
  <div class="QuestionaryFormEdit">
    <QuestionaryForm
      v-if="structure"
      :questionary-model="model"
      :questionary-status="questionaryStatus"
      @cancel="$emit('cancel')"
      @save="$emit('save', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QuestionaryForm from '@/views/Estimation/Questionary/_components/QuestionaryForm/QuestionaryForm';
import { Questionary } from '@/store/Models';

export default {
  name: 'QuestionaryFormEdit',
  components: {
    QuestionaryForm,
  },
  props: {
    questionaryItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      structure: 'Directories/structureForQuestionaryCreate',
    }),
    questionaryStatus() {
      return this.questionaryItem?.status || '';
    },
    model() {
      return Questionary.questionaryModel({
        id: this.questionaryItem?.id,
        number: this.questionaryItem?.number,
        method: this.questionaryItem?.method,
        dateStart: this.questionaryItem?.dateStart,
        dateEnd: this.questionaryItem?.dateEnd,
        type: this.questionaryItem?.type,
        positionId: this.questionaryItem?.position?.id,
        subdivisionId: this.questionaryItem?.subdivision?.id,
        competenceList: (this.questionaryItem?.competenceList || []).map(({ id }) => {
          return { id };
        }),
        employeeList: (this.questionaryItem?.employeeList || []).map(({ id }) => {
          return { id };
        }),
        participantList: (this.questionaryItem?.participantList || []).map(({ id, type }) => {
          return { id, type };
        }),
        isAnonymous: this.questionaryItem?.isAnonymous,
        periodType: this.questionaryItem?.periodType,
      });
    },
  },
};
</script>
