<template>
  <div class="App-Loader">
    <div class="App-Loader-Container">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
};
</script>