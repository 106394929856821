<template>
  <div class="QuestionaryCard" :class="{ 'QuestionaryCard--Archive': isArchive }">
    <div class="QuestionaryCard-Row QuestionaryCard-Numbers">
      <div class="Left">
        <QuestionaryNumber :status="card.status">№ {{ card.number }}</QuestionaryNumber>
      </div>
      <div class="Right">
        <QuestionaryMethod :method="card.method" />
      </div>
    </div>
    <div class="QuestionaryCard-Current">
      <BaseIcon class="me-1" :name="typeIcon" />
      <span class="QuestionaryCard-TypeName">
        {{ typeName }}
      </span>
    </div>
    <div class="QuestionaryCard-Row QuestionaryCard-Other">
      <div class="Left">
        <span class="QuestionaryCard-TypeInfo">
          {{ typeInfo }}
        </span>
      </div>
      <div class="Right">
        <span class="QuestionaryCard-TypeDate">
          <BaseIcon class="me-1" name="clock" />
          <span>{{ startDateTesting }}</span>
          <span>-</span>
          <span>{{ endDateTesting }}</span>
        </span>
      </div>
    </div>
    <div v-if="isShowProgressBar" class="Delimiter"></div>
    <QuestionaryProgress
      v-if="isShowProgressBar"
      :total="questionaryProgress.totalCount"
      :passed="questionaryProgress.passedCount"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import QuestionaryProgress from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryProgress';

import QuestionaryNumber from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryNumber';
import QuestionaryMethod from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryMethod';

// Словаь наименований иконок для типов тестируемых
import { QuestionaryIconTypeTesting } from '@/constants/Directories/QuestionaryIconTypeTesting';
// Словарь цветов для методов тестирования
import { QuestionaryColorMethod } from '@/constants/Directories/QuestionaryColorMethod';
import { vmoment } from '@/services/Helpers';

export default {
  name: 'QuestionaryCard',
  components: {
    BaseIcon,
    QuestionaryProgress,
    QuestionaryNumber,
    QuestionaryMethod,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    typeIcons: QuestionaryIconTypeTesting,
    methodColors: QuestionaryColorMethod,
  }),
  computed: {
    ...mapGetters('Directories', ['questionaryTypeCollection']),
    typeIcon() {
      return this.typeIcons?.[this.card?.type];
    },
    methodColor() {
      return this.methodColors?.[this.card?.method];
    },
    typeName() {
      return this.questionaryTypeCollection?.[this.card?.type] || '';
    },
    typeInfo() {
      const { type } = this.card;
      if (type === 'surname') {
        const { lastName, firstName } = this.card?.employeeList?.[0];
        return `${lastName} ${firstName}`;
      }
      return this.card?.[type]?.name || 'position';
    },
    questionaryProgress() {
      return (
        this.card?.progressList.reduce((acc, i) => {
          const { totalCount, passedCount } = i;
          acc = { totalCount, passedCount };
          return acc;
        }, {}) || {}
      );
    },
    startDateTesting() {
      return this.card ? vmoment(this.card.dateStart).format('L') : null;
    },
    endDateTesting() {
      return this.card ? vmoment(this.card.dateEnd).format('L') : null;
    },
    isShowProgressBar() {
      return this.card?.status === 'inwork';
    },
  },
};
</script>
