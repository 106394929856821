import { Api } from '@/services/General/Api';
import { ENDPOINTS_REPORT } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    reports: [],
  },
  mutations: {
    setReportsList(state, reports) {
      state.reports = reports;
    },
  },
  actions: {
    async getReportsList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_REPORT.REPORT_GET_LIST }).then(({ data } = {}) =>
          commit('setReportsList', data),
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async filterReportList(_, filter = {}) {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_REPORT.REPORT_GET_LIST,
          data: {
            payload: {
              params: filter,
            },
          },
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getReportsByQuestionaryId(_, id = '') {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_REPORT.REPORT_GET_LIST,
          data: {
            payload: {
              params: { questionaryId: id },
            },
          },
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getReportsByQuestionaryIdAndEmployeeId(_, { questionaryId = '', employeeId } = {}) {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_REPORT.REPORT_GET_LIST,
          data: {
            payload: {
              params: { questionaryId, employeeId },
            },
          },
        });
        return data?.[0] || {};
      } catch (error) {
        throw new Error(error);
      }
    },
    async exportReportByQuestionaryIdAndEmployeeID(
      _,
      { questionaryId = '', employeeId = '' } = {},
    ) {
      try {
        const { data = '' } = await Api.get({
          requestUrl: ENDPOINTS_REPORT.REPORT_EXPORT,
          data: {
            payload: {
              params: { questionaryId, employeeId },
            },
          },
          notify: false,
          requestConfig: { responseType: 'blob' },
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async sendReportByQuestionaryIdAndEmployeeID(
      _,
      { questionaryId = '', employeeId = '', text = '', emailList = [] } = {},
    ) {
      try {
        return await Api.post({
          requestUrl: ENDPOINTS_REPORT.REPORT_SEND,
          data: {
            payload: {
              questionaryId,
              employeeId,
              text,
              emailList,
            },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
