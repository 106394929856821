<template>
  <div class="Profile">
    <Information />
    <Tabs />
  </div>
</template>

<script>
import Information from '@/components/Modules/Profile/Information';
import Tabs from '@/components/Modules/Profile/Tabs';

export default {
  name: 'Profile',
  components: { Information, Tabs },
};
</script>
