import Vue from 'vue';

const IOSControlScroll = {
  install(Vue) {
    Vue.prototype.$menuScroll = 0;
    Vue.prototype.$IOSBlockScroll = () => {
      const { scrollTop } = document.documentElement;
      Vue.prototype.$menuScroll = scrollTop;
      document.body.style.position = 'fixed';
      document.body.style.top = `${-Vue.prototype.$menuScroll}px`;
      document.body.classList.add('ScrollBar--disableOverflow');
    };
    Vue.prototype.$IOSReturnScroll = () => {
      document.body.style = {};
      document.documentElement.scrollTo(0, Vue.prototype.$menuScroll);
      document.body.classList.remove('ScrollBar--disableOverflow');
    };
  },
};

Vue.use(IOSControlScroll);
