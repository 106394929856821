export const ENDPOINTS_QUESTIONARY = {
  QUESTIONARY_ITEM_CREATE: '/questionary/create',
  QUESTIONARY_ITEM_UPDATE: '/questionary/update',
  QUESTIONARY_ITEM_DELETE: '/questionary/{id}',
  QUESTIONARY_ITEM_DUBLICATE: '/questionary/duplicate',
  QUESTIONARY_ITEM_UPDATE_STATUS: '/questionary/statusUpdate',
  QUESTIONARY_GET_STRUCTURE: '/questionary/structure',
  QUESTIONARY_GET_LIST: '/questionary/list',
  QUESTIONARY_TESTING_LIST: '/questionaryEmployee',
};
