<template>
  <div class="Profile-Tabs">
    <BaseTabs>
      <template #labels>
        <span
          v-for="(tab, index) in tabs"
          :key="`Profile-Tab-${index}`"
          :class="['Label', { active: activeTab === tab.component }]"
          @click="activeTab = tab.component"
        >
          {{ tab.name }}
        </span>
      </template>
      <keep-alive>
        <component :is="currentTab.component" />
      </keep-alive>
    </BaseTabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseTabs from '@/components/Base/BaseTabs';

import TabGeneral from '@/components/Modules/Profile/Tabs/TabGeneral';
import TabReports from '@/components/Modules/Profile/Tabs/TabReports';

export default {
  name: 'ProfileTabs',
  components: { BaseTabs, TabGeneral, TabReports },
  data: () => ({
    activeTab: '',
  }),
  computed: {
    ...mapState('Users', ['employeeProfileId']),
    ...mapState('Admin', ['currentUserInfo']),
    ...mapState({
      currentUserRoles: (state) => state.Admin.currentUserInfo.roles,
    }),
    currentEmployeeId() {
      return this.currentUserInfo?.employeeId;
    },
    isCanShow360() {
      return this.employeeProfileId === this.currentEmployeeId || this.isUserAdminOrHR;
    },
    //TODO: Это костыль. Нужно будет выпилить как настроем нормальную систему доступов
    isUserAdminOrHR() {
      return this.currentUserRoles.some(({ id }) =>
        ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_HR'].includes(id),
      );
    },
    currentTab() {
      return this.tabs.find(({ component }) => component === this.activeTab) || {};
    },
    tabs() {
      return [
        {
          name: this.$t('profile.tabs.main'),
          component: 'TabGeneral',
          isShow: true,
        },
        {
          name: this.$t('profile.tabs.rating'),
          component: 'TabReports',
          isShow: this.isCanShow360,
        },
      ].filter(({ isShow }) => isShow);
    },
  },
  mounted() {
    this.activeTab = this.tabs?.[0]?.component;
  },
};
</script>
