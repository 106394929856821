<template>
  <div class="AdminTemplates">
    <AppPageHead>
      <template #title>{{ currentTab.title }}</template>
    </AppPageHead>
    <BaseTabs>
      <template #labels>
        <span
          v-for="(tab, index) in templatesTabs"
          :key="index"
          :class="['Label', { active: activeTab === tab.component }]"
          @click="activeTab = tab.component"
        >
          {{ tab.title }}
        </span>
      </template>
      <div class="AdminTemplates-Wrap">
        <div class="AdminTemplates-Container">
          <component :is="currentTab.component" :template-model="currentTab.model" />
        </div>
      </div>
    </BaseTabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BaseTabs from '@/components/Base/BaseTabs';
import AppPageHead from '@/components/App/AppPageHead';

import TemplatesTabEmails from '@/views/Estimation/Templates/Tabs/TemplatesTabEmails';
import TemplatesTabNotifications from '@/views/Estimation/Templates/Tabs/TemplatesTabNotifications';

export default {
  name: 'AdminTemplates',
  components: {
    BaseTabs,
    AppPageHead,
    Emails: TemplatesTabEmails,
    Notifications: TemplatesTabNotifications,
  },
  data: () => ({
    activeTab: 'Emails',
  }),
  computed: {
    currentTab() {
      return this.templatesTabs.find((tab) => tab.component === this.activeTab);
    },
    templatesTabs() {
      return [
        {
          title: this.$t('admin.pages.templates.tabs.email'),
          component: 'Emails',
          model: () => ({}),
        },
        {
          title: this.$t('admin.pages.templates.tabs.notification'),
          component: 'Notifications',
          model: () => ({}),
        },
      ];
    },
    templatesTabsLabels() {
      return this.templatesTabs.map((item) => item.title) || [];
    },
  },
  mounted() {
    Promise.all([this.getTemplatesEmailList(), this.getTemplatesNotificationList()]).then(() => {});
  },
  methods: {
    ...mapActions('Templates', ['getTemplatesEmailList', 'getTemplatesNotificationList']),
  },
};
</script>
