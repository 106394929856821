export const TemplateTab = {
  data: () => ({
    list: [],
  }),
  methods: {
    onEditTemplateItem(itemId) {
      this.itemTemplateModel = this.list.find((item) => item.id === itemId);
      this.$bvModal.show(this.nameModalOfEditTemplateItem);
    },
    onSaveTemplateItem(item) {
      if (typeof this.updateItem === 'function') {
        this.updateItem(item).then(() => {
          this.updateTemplateItems();
        });
      }
    },
    onCloseTemplateModal() {
      this.$bvModal.hide(this.nameModalOfEditTemplateItem);
      this.itemTemplateModel = this.templateModel();
    },
    onShowConfirmAction(itemId) {
      this.targetResetTemplate = itemId;
      this.$bvModal.show(this.nameModalOfConfirmAction);
    },
    onHideConfirmAction() {
      this.$bvModal.hide(this.nameModalOfConfirmAction);
    },
    onConfirmModalAction() {
      if (typeof this.resetItem === 'function') {
        this.resetItem(this.targetResetTemplate)
          .then(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.updateTemplateItems();
            this.targetResetTemplate = null;
          })
          .catch(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.targetResetTemplate = null;
          });
      }
    },
    updateTemplateItems() {
      this.getList?.().then(() => {
        this.$bvModal.hide(this.nameModalOfEditTemplateItem);
      });
    },
  },
};
