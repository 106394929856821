<template>
  <div class="TestingProcess">
    <div class="TestingProcess-Top">
      <div class="TestingProcess-Section p-3 pt-2">
        <div class="TestingProcess-Info mb-1">
          <div
            v-if="employee.lastName && employee.firstName"
            class="TestingProcess-EmployeeNew mt-2 mb-3"
          >
            <div class="TestingProcess-EmployeeNew-Avatar">
              <template v-if="employee.photoUrl">
                <img :src="employee.photoUrl" :alt="employee.lastName" />
              </template>
              <template v-else>
                {{ [employee.lastName, employee.firstName] | firstLetters }}
              </template>
            </div>
            <div class="TestingProcess-EmployeeNew-Name">
              {{ employee.lastName }} {{ employee.firstName }}
            </div>
            <div class="TestingProcess-EmployeeNew-Email">
              {{ employee.email }}
            </div>
          </div>
          <div class="TestingProcess-Progress">
            <BaseProgressBar
              :label="`${$t('labels.question')} ${
                questionCurrentIndex < questionsCount ? questionCurrentIndex + 1 : questionsCount
              }/${questionsCount}`"
              :current="questionCurrentIndex + 1"
              :total="questionsCount"
            />
          </div>
        </div>
      </div>
      <div class="Delimiter"></div>
      <div class="TestingProcess-Section p-3 pt-2 pb-2">
        <template v-if="isQuestions">
          <div v-if="questionCurrent" class="TestingProcess-Question mt-3 mb-3">
            {{ questionCurrent.text }}
          </div>
          <div v-if="answersVariants" class="TestingProcess-Answers">
            <span
              v-for="(answer, index) in answersVariants"
              :key="index"
              class="Button-Secondary Button TestingProcess-Answer"
              :class="{ active: answer.value === currentAnswer }"
              @click="currentAnswer = answer.value"
            >
              {{ $t(`testing.process.answers.${answer.key}`) }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="TestingProcess-Next mt-3">
            <BaseIcon color="success" name="check-circle" class="me-1 mb-1" />
            {{ $t('testing.process.testing-done') }} {{ employee.lastName }}
            {{ employee.firstName }}
            {{ employee.middleName }}!
          </div>
        </template>
      </div>
    </div>
    <div class="TestingProcess-Bottom">
      <div class="TestingProcess-Section p-3">
        <BaseActions
          :actions="actionsList"
          @answer="onQuestionAnswer"
          @back="onQuestionBack"
          @close="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseActions from '@/components/Base/BaseActions';
import BaseProgressBar from '@/components/Base/BaseProgressBar';

import { TestingModels } from '@/store/Models';

import { ArrayFunctions } from '@/services/Helpers';
import { firstLetters } from '@/services/Filters';

export default {
  name: 'QuestionaryTesting',
  components: {
    BaseIcon,
    BaseActions,
    BaseProgressBar,
  },
  filters: { firstLetters },
  props: {
    questionaryId: {
      type: String,
      required: true,
    },
    employeeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    questionCurrentIndex: 0,

    answersVariants: [
      { key: 'value0', value: 0 },
      { key: 'value1', value: 1 },
      { key: 'value2', value: 2 },
      { key: 'value3', value: 3 },
      { key: 'value4', value: 4 },
      { key: 'value5', value: 5 },
    ],

    actionsVariants: {
      hasQuestions: [
        { action: 'back' },
        {
          action: 'answer',
          class: 'Button-Main',
          icon: { name: 'chevron-right', right: true, class: 'ms-1' },
        },
      ],
      hasNoQuestions: [{ action: 'answer', class: 'Button-Main' }],
      employeeDone: [{ action: 'close' }],
    },

    answers: {},
    answersArray: [],
    currentAnswer: '',

    answersSendData: {
      answerList: [],
    },

    modelAnswerQuestion: TestingModels.questionListModel,
  }),
  computed: {
    ...mapState('Testing', ['employee', 'competencies']),
    ...mapState('Admin', ['currentUserInfo']),
    currentUserId() {
      return this.currentUserInfo?.employeeId || '';
    },
    actionsList() {
      if (this.questionCurrentIndex === this.questionsCount && this.questionsCount !== 0) {
        return this.actionsVariants.employeeDone;
      }

      if (this.questionCurrentIndex > 0) {
        return this.actionsVariants.hasQuestions;
      }

      return this.actionsVariants.hasNoQuestions || [];
    },
    questionsRandom() {
      return ArrayFunctions.random(this.questionsOfCompetencies);
    },
    questionCurrent() {
      return this.questionsRandom[this.questionCurrentIndex] || null;
    },
    questionsCount() {
      return this.questionsRandom.length || 0;
    },
    questionsOfCompetencies() {
      return (this.competencies || []).reduce((questions, competence) => {
        if (competence.questionList) {
          competence.questionList.map((question) => {
            questions.push({
              id: question.questionId,
              // Todo: Поле item.text планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
              text: question.questionText?.[this.$i18n.locale] ?? question?.text ?? '',
              competenceId: competence.competenceId,
              employeeId: this.employeeId,
            });
          });
        }
        return questions;
      }, []);
    },
    isQuestions() {
      return this.questionCurrentIndex < this.questionsCount;
    },
  },
  mounted() {
    Promise.all([
      this.getEmployee({ employeeId: this.employeeId }),
      this.testingStartNew({ employeeId: this.employeeId, questionaryId: this.questionaryId }),
    ]);
  },
  methods: {
    ...mapActions('Testing', ['getEmployee', 'testingStartNew', 'sendResult']),
    buildResultData() {
      return new Promise((resolve, reject) => {
        const result = {};
        Object.keys(this.answers).map((question) => {
          if (!result[this.answers[question].competenceId]) {
            result[this.answers[question].competenceId] = {
              competenceId: this.answers[question].competenceId,
              employeeId: this.answers[question].employeeId,
              questionList: this.answersArray.reduce((acc, item) => {
                if (item.competenceId === this.answers[question].competenceId) {
                  acc.push({
                    id: item.id,
                    answer: item.answer,
                  });
                }
                return acc;
              }, []),
            };
          }
        });

        if (result) {
          const answerList = [];

          Object.keys(result).map((item) => {
            answerList.push(result[item]);
          });

          if (answerList) {
            this.answersSendData.answerList = answerList;
            this.answersSendData.questionaryId = this.questionaryId;
            this.answersSendData.employeeId = this.currentUserId;
            resolve();
          }
        }

        reject();
      });
    },
    onQuestionAnswer() {
      if (this.currentAnswer !== '') {
        this.answers[this.questionCurrent.id] = this.modelAnswerQuestion({
          id: this.questionCurrent.id,
          answer: this.currentAnswer,
          competenceId: this.questionCurrent.competenceId,
          employeeId: this.employeeId,
        });
        this.answersArray.push({
          id: this.questionCurrent.id,
          answer: this.currentAnswer,
          competenceId: this.questionCurrent.competenceId,
          employeeId: this.employeeId,
        });
      }

      if (this.currentAnswer !== '' && this.questionCurrentIndex + 1 < this.questionsCount) {
        this.questionCurrentIndex++;
        this.currentAnswer = '';
        if (this.answers[this.questionCurrent.id]) {
          this.currentAnswer = this.answers[this.questionCurrent.id].answer;
        }
      } else if (this.currentAnswer !== '') {
        this.questionCurrentIndex++;

        this.buildResultData().then(() => {
          this.sendResult(this.answersSendData);
        });
      }
    },
    onQuestionBack() {
      if (this.questionCurrentIndex - 1 >= 0) {
        this.questionCurrentIndex--;
        this.currentAnswer = '';

        if (this.answers) {
          this.currentAnswer = this.answers[this.questionCurrent.id].answer;
        }
      }
    },
  },
};
</script>
