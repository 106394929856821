<template>
  <div v-if="!loading" class="BaseTable">
    <div class="BaseTable-Wrap">
      <div class="BaseTable-Data">
        <div class="BaseTable-Row BaseTable-Header-Row">
          <div
            v-for="(col, idxCol) in headers"
            :key="`BaseTable-Header-Col-${idxCol}`"
            class="BaseTable-Col BaseTable-Col-Header"
            :style="col.style || ''"
            :data-col="col.colName || ''"
          >
            <div
              class="BaseTable-Col-Value"
              :class="{ Sortable: col.sortable }"
              @click="onSortByCol(col.key)"
            >
              <template v-if="$scopedSlots[`header-col-${col.key}`]">
                <slot :name="`header-col-${col.key}`" :item="col"></slot>
              </template>
              <template v-else>
                {{ col.name }}
              </template>
              <BaseSortIcon
                v-if="col.sortable"
                :sort="sortable.value"
                :state="sortable.key === col.key"
              />
            </div>
          </div>
        </div>
        <div
          v-for="(row, idxRow) in items"
          :key="`BaseTable-Body-Row-${idxRow}`"
          class="BaseTable-Row BaseTable-Body-Row"
        >
          <div
            v-for="(col, idxCol) in headers"
            :key="`BaseTable-Header-Col-${idxCol}`"
            class="BaseTable-Col BaseTable-Col-Data"
            :data-col="col.colName || ''"
          >
            <div class="BaseTable-Col-Value">
              <template v-if="$scopedSlots[`body-col-${col.key}`]">
                <slot :name="`body-col-${col.key}`" :item="row"></slot>
              </template>
              <template v-else>
                {{ row[col.key] }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="count > limit" class="BaseTable-Pagination">
      <BasePagination v-model="offset" :count="count" :limit="limit" @input="getData" />
    </div>
  </div>
</template>

<script>
import BaseSortIcon from '@/components/Base/BaseSortIcon';
import BasePagination from '@/components/Base/BasePagination';

export default {
  name: 'BaseTable',
  components: { BaseSortIcon, BasePagination },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data: () => ({
    sort: '',
    offset: 1,
  }),
  computed: {
    sortable() {
      const [key, value] = this.sort.split(':');
      return {
        key,
        value,
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const { sort, offset, limit } = this;
      this.$emit('get-data', { sort, offset: (offset - 1) * limit, limit });
    },
    onSortByCol(key) {
      const [sortKey, sortValue] = this.sort.split(':');
      this.sort = `${key}:${sortKey !== key ? 'a' : sortValue === 'a' ? 'd' : 'a'}`;
      this.getData();
    },
  },
};
</script>