<template>
  <div class="EmployeesBySubdivision">
    <div class="EmployeesBySubdivision-Head">
      <div class="EmployeesBySubdivision-Name">
        {{ subdivisionName }}
      </div>
      <div class="EmployeesBySubdivision-Count">
        {{ subTitle }}
      </div>
    </div>
    <BaseTable :headers="headers" :items="items" :count="count" :limit="10" @get-data="onGetData">
      <template #body-col-name="{ item }">
        <div class="EmployeeInfo">
          <div class="EmployeeInfo-Avatar">
            <template v-if="item.photoUrl">
              <img :src="item.photoUrl" :alt="item.lastName" />
            </template>
            <template v-else>
              {{ [item.lastName, item.firstName] | firstLetters }}
            </template>
          </div>
          <div class="EmployeeInfo-Name" @click="onShowEmployeeProfile(item.employeeId)">
            {{ item.lastName }} {{ item.firstName }}
          </div>
          <div class="EmployeeInfo-Email">
            {{ item.email }}
          </div>
        </div>
      </template>
      <template #body-col-inCompany="{ item }">
        {{ item.inCompany | unixToDate }}
      </template>
      <template #body-col-slackId="{ item }">
        <BaseIcon
          :class-name="item.slackId ? 'green' : 'red'"
          :name="item.slackId ? 'check-ok' : 'x-close'"
        />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseTable from '@/components/Base/BaseTable';

import { firstLetters, unixToDate } from '@/services/Filters';
import { STRUCTURE_EMPLOYEES_HEADERS } from '@/views/Structure/Tabs/config';

import Declension from '@/services/Helpers/HelperDeclension';

export default {
  name: 'EmployeesBySubdivision',
  components: { BaseIcon, BaseTable },
  filters: { firstLetters, unixToDate },
  props: {
    subdivisionId: {
      type: String,
      required: true,
    },
    subdivisionName: {
      type: String,
      required: true,
    },
    searchValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    items: [],
    count: 0,
  }),
  computed: {
    headers() {
      return STRUCTURE_EMPLOYEES_HEADERS.map((header) => {
        const { key } = header;
        return this.$te(`tables.structure.${key}`)
          ? { ...header, name: this.$t(`tables.structure.${key}`) }
          : header;
      });
    },
    subTitle() {
      return `
        ${this.count}
        ${Declension(this.count, [
          this.$i18n.t('labels.employees[0]'),
          this.$i18n.t('labels.employees[1]'),
          this.$i18n.t('labels.employees[2]'),
        ])}
      `;
    },
  },
  watch: {
    searchValue() {
      this.getTableData();
    },
  },
  methods: {
    ...mapActions('Structure', ['getStructureEmployees']),
    ...mapMutations('Users', ['setStateOfShowingProfile', 'setEmployeeProfileId']),
    getTableData(tableParams = {}) {
      const params = {};

      if (this.searchValue) {
        params[`filterList[name]`] = this.searchValue.trim();
      }
      if (this.subdivisionId) {
        params[`filterList[subdivisionId]`] = this.subdivisionId;
      }

      this.getStructureEmployees({ ...params, ...tableParams }).then(({ data, count }) => {
        this.items = data;
        this.count = count;
      });
    },
    onGetData(params) {
      this.getTableData(params);
      this.$emit('check-scroll');
    },
    onShowEmployeeProfile(id) {
      this.setEmployeeProfileId(id);
      this.setStateOfShowingProfile(true);
    },
  },
};
</script>
