<template>
  <div class="UserListItem">
    <div class="UserListItem-Body">
      <slot name="default"></slot>
    </div>
    <div class="UserListItem-Actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserListItem',
};
</script>