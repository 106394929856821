<template>
  <div class="RestorePasswordResetSuccess">
    <RestorePasswordContent
      icon="check-circle-broken"
      classes="success"
      :title="$t('restore-password.titles.passwordReset')"
      :text="$t('restore-password.texts.passwordReset')"
    />
    <router-link
      :to="{ name: 'login' }"
      class="Button Button-Main Medium Full RestorePasswordResetSuccess-Login"
      @click.native="$emit('success', false)"
    >
      {{ $t('base-actions.logIn') }}
    </router-link>
  </div>
</template>

<script>
import RestorePasswordContent from '@/views/Auth/_components/RestorePasswordContent';
export default {
  name: 'RestorePasswordResetSuccess',
  components: { RestorePasswordContent },
};
</script>