import { API_HOST } from '@/config';

import { http } from '@/services/Plugins/Axios';
import { HelperRegEx } from '@/services/Helpers';
import { Notification } from '@/services/General/Notification';

const axiosCall = ({
  method = '',
  requestUrl = '',
  contractUrl = '',
  data = {},
  notify = false,
  requestConfig = {},
  responseConfig = {},
} = {}) => {
  return new Promise((resolve, reject) => {
    const call = method.toLowerCase();
    const files = data?.files || null;
    let payload = data?.payload || null;
    const requestUrlRes = HelperRegEx.replaceParamsFromRequestUrl(
      data?.params || {},
      contractUrl || requestUrl,
    );
    const url = contractUrl ? requestUrlRes : `${API_HOST}${requestUrlRes}`;

    if (typeof http[call] === 'function') {
      if (call === 'delete') payload = { data: payload };
      http[call](url, files || { ...payload, ...requestConfig }, {
        ...responseConfig,
      })
        .then((response) => {
          if (notify && response?.message) Notification.success(response.message);
          resolve(response);
        })
        .catch((error) => {
          if (notify) Notification.error(error);
          reject(error);
        });
    } else {
      reject(`Call undefined method: ${call}`);
    }
  });
};

export const Api = {
  get: ({ requestUrl, contractUrl, data, notify = false, requestConfig, responseConfig } = {}) =>
    axiosCall({
      method: 'get',
      requestUrl,
      contractUrl,
      data,
      notify,
      requestConfig,
      responseConfig,
    }),
  post: ({ requestUrl, contractUrl, data, notify = true, requestConfig, responseConfig } = {}) =>
    axiosCall({
      method: 'post',
      requestUrl,
      contractUrl,
      data,
      notify,
      requestConfig,
      responseConfig,
    }),
  put: ({ requestUrl, contractUrl, data, notify = true, requestConfig, responseConfig } = {}) =>
    axiosCall({
      method: 'put',
      requestUrl,
      contractUrl,
      data,
      notify,
      requestConfig,
      responseConfig,
    }),
  delete: ({ requestUrl, contractUrl, data, notify = true, requestConfig, responseConfig } = {}) =>
    axiosCall({
      method: 'delete',
      requestUrl,
      contractUrl,
      data,
      notify,
      requestConfig,
      responseConfig,
    }),
  patch: ({ requestUrl, contractUrl, data, notify = true, requestConfig, responseConfig } = {}) =>
    axiosCall({
      method: 'patch',
      requestUrl,
      contractUrl,
      data,
      notify,
      requestConfig,
      responseConfig,
    }),
};
