<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--ReportSend"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.report.title') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="ReportFormData">
        <div class="ReportFormData-Editor">
          <div class="Editor-Wrap" :class="{ 'Editor-Error': !validateStateForm('text') }">
            <VueEditor
              v-model="form.text"
              :editor-toolbar="toolbarVueEditor"
              @input="$v.$touch()"
            />
          </div>
        </div>
        <div class="ReportFormData-Form">
          <div class="ReportFormData-Field">
            <div class="ReportFormData-Label Label">{{ $t('placeholders.email-list') }}:</div>
            <b-form-input
              v-model.trim.lazy="$v.email.$model"
              :state="validateState('email')"
              class="Input Medium"
              autocomplete="off"
              :placeholder="`${$t('placeholders.email')}:`"
              @keydown.enter="onAddEmailList"
            ></b-form-input>
          </div>
          <div class="ReportFormData-Button">
            <span class="Button-InTables Button Medium" @click="onAddEmailList">
              <BaseIcon class="me-1" height="16" width="16" name="plus" />
              {{ $t('base-actions.add') }}
            </span>
          </div>
        </div>
        <div class="ReportFormData-Emails">
          <BaseItemList v-for="(email, index) in form.emailList" :key="index">
            {{ email }}
            <span class="Button--reset Button" @click="onDeleteEmail(index)">
              <BaseIcon height="14" width="14" name="x-close" />
            </span>
          </BaseItemList>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @send="onSendForm" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseItemList from '@/components/Base/BaseItemList';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

import { VueEditor } from 'vue2-editor';
import { toolbarVueEditor } from '@/plugins/VueEditor/OptionsVueEditor';

import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'ReportModalSend',
  components: {
    BaseIcon,
    BaseItemList,
    BaseModalWrap,
    BaseActions,
    VueEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    email: '',
    form: {
      text: '',
      emailList: [],
    },
    toolbarVueEditor: toolbarVueEditor,
  }),
  validations: {
    email: {
      email,
      required,
    },
    form: {
      text: {
        required,
      },
      emailList: {
        required,
      },
    },
  },
  computed: {
    modalActions() {
      return [{ action: 'send', class: 'Button-Main' }, { action: 'cancel' }];
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v?.[name] || {};
      return $dirty ? !$error : null;
    },
    validateStateForm(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onAddEmailList() {
      if (this.validateState('email')) {
        this.form.emailList.push(this.email);
        this.email = '';
      }
    },
    onDeleteEmail(index) {
      this.form.emailList.splice(index, 1);
    },
    onSendForm() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('send', {
          questionaryId: this.report?.questionaryId,
          employeeId: this.report?.employeeId,
          ...this.form,
        });
      }
    },
  },
};
</script>