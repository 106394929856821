<template>
  <div class="AppLegalNavigation">
    <template v-for="(link, index) in navigation">
      <router-link
        :key="`nav-${index}`"
        :to="{ name: link.route }"
        :exact="true"
        active-class="AppLegalNavigation--active"
      >
        {{ link.title }}
      </router-link>
    </template>
  </div>
</template>

<script>
import { FOOTER_NAVIGATION } from '@/constants/Navigation';

export default {
  name: 'AppLegalNavigation',
  computed: {
    navigation() {
      return FOOTER_NAVIGATION.map((item) => {
        return { ...item, title: this.$i18n.t(`navigation-footer.${item.page}`) };
      });
    },
  },
};
</script>