import { HelperURLSearchParams } from '@/services/Helpers';
import { DictionaryModels } from '@/store/Models';

export const DictionaryTab = {
  data: () => ({
    list: [],
    filterDictionary: '',
    models: DictionaryModels,
  }),
  computed: {
    updateOrSave() {
      return this.itemDictionaryModel.id ? 'update' : 'save';
    },
  },
  watch: {
    filterDictionary() {
      this.filterItems();
    },
  },
  methods: {
    onCreateDictionaryItem() {
      this.itemDictionaryModel = this.dictionaryModel();
      this.showDictionaryModal(this.nameModalOfAddDictionaryItem);
    },
    editDictionaryItem(itemId) {
      const dictionaryItem = this.list.find((item) => item.id === itemId);
      this.itemDictionaryModel = this.dictionaryModel(dictionaryItem);
      this.showDictionaryModal(this.nameModalOfAddDictionaryItem);
    },
    onFilterDictionaryItems(filter) {
      this.filterDictionary =
        '/?' +
        HelperURLSearchParams.createRequestParams({
          q: filter.search,
          orderBy: filter.sort,
        });
    },
    saveDictionaryItem(item) {
      if (typeof this.createItem === 'function') {
        this.createItem(item).then(() => {
          this.changeLocaleCurrentUser(item);
          this.updateDictionaryItems();
        });
      }
    },
    updateDictionaryItem(item) {
      if (typeof this.updateItem === 'function') {
        this.updateItem(item).then(() => {
          this.changeLocaleCurrentUser(item);
          this.updateDictionaryItems();
        });
      }
    },
    copyDictionaryItem(itemId) {
      const dictionaryItem = this.list.find((item) => item.id === itemId);
      this.itemDictionaryModel = this.dictionaryCopyModel(dictionaryItem);
      this.showDictionaryModal(this.nameModalOfAddDictionaryItem);
    },
    showDictionaryModal() {
      this.$bvModal.show(this.nameModalOfAddDictionaryItem);
    },
    closeDictionaryModal() {
      this.$bvModal.hide(this.nameModalOfAddDictionaryItem);
      this.itemDictionaryModel = this.dictionaryModel();
    },
    updateDictionaryItems() {
      this.getList?.(this.filterDictionary).then(() => {
        this.$bvModal.hide(this.nameModalOfAddDictionaryItem);
      });
    },
    filterItems() {
      if (typeof this.filterList === 'function') {
        this.filterList(this.filterDictionary).then((response) => {
          this.list = response;
        });
      }
    },
    showConfirmAction(itemId) {
      this.targetDeleteDictionaryItem = itemId;
      this.$bvModal.show(this.nameModalOfConfirmAction);
    },
    hideConfirmAction() {
      this.$bvModal.hide(this.nameModalOfConfirmAction);
    },
    confirmModalAction() {
      if (typeof this.deleteItem === 'function') {
        this.deleteItem(this.targetDeleteDictionaryItem)
          .then(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.updateDictionaryItems();
            this.targetDeleteDictionaryItem = null;
          })
          .catch(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.targetDeleteDictionaryItem = null;
          });
      }
    },
    changeLocaleCurrentUser(userData) {
      const { id = null, locale = null } = userData;
      if (this.currentUserId && id && id === this.currentUserId) {
        this.$i18n.locale = locale;
      }
    },
  },
};
