<template>
  <div class="InfoRow">
    <div v-if="$scopedSlots.label" class="InfoRow-Label">
      <slot name="label"></slot>
    </div>
    <div class="InfoRow-Value">
      <slot v-if="$scopedSlots.default" name="default"></slot>
      <template v-else>
        {{ $t('profile.not-indicated') }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoRow',
};
</script>