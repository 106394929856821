<template>
  <BaseSidebar v-model="state" class="BaseSidebar--Wide BaseSidebarProfile">
    <Profile />
  </BaseSidebar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BaseSidebar from '@/components/Base/BaseSidebar';

import Profile from '@/components/Modules/Profile/Profile';

export default {
  name: 'UserProfile',
  components: { BaseSidebar, Profile },
  computed: {
    ...mapState('Users', ['isShowingProfile']),
    ...mapState('Settings', ['brand']),
    state: {
      set(v) {
        this.setStateOfShowingProfile(v);
      },
      get() {
        return this.isShowingProfile;
      },
    },
  },
  watch: {
    brand: {
      immediate: true,
      handler() {
        this.state = false;
      },
    },
    '$route.name': {
      handler() {
        this.state = false;
      },
    },
  },
  methods: {
    ...mapMutations('Users', ['setStateOfShowingProfile']),
  },
};
</script>