import { CompetenceList } from '@/store/Models/ModelCompetenceList';

export const DictionaryModels = {
  competenciesModel: ({
    id = null,
    nameText = {},
    description = 'empty',
    descriptionText = {},
    questionList = [CompetenceList.questionListModel()],
  } = {}) => ({
    id,
    description,
    nameText: CompetenceList.nameTextModel(nameText),
    descriptionText: CompetenceList.descriptionTextModel(descriptionText),
    questionList: [...CompetenceList.questionListModel(questionList)],
  }),
  competenciesCopyModel: ({
    nameText = {},
    description = 'empty',
    descriptionText = {},
    questionList = [CompetenceList.questionListModel()],
  } = {}) => ({
    id: null,
    description,
    nameText: CompetenceList.nameTextModel(nameText),
    descriptionText: CompetenceList.descriptionTextModel(descriptionText),
    questionList: [...CompetenceList.questionListCopyModel(questionList)],
  }),
  positionsModel: ({ id = null, name = '', description = '', competenceList = [] } = {}) => ({
    id,
    name,
    description,
    competenceList,
  }),
  employeesModel: ({
    id = null,
    lastName = '',
    firstName = '',
    middleName = '',
    positionId = '',
    subdivisionId = '',
    email = '',
    inCompany = '',
    inPosition = '',
    workExpirience = '',
    locale = 'ru',
    slackId = '',
  } = {}) => ({
    id,
    lastName,
    firstName,
    middleName,
    positionId,
    subdivisionId,
    email,
    inCompany,
    inPosition,
    workExpirience,
    locale,
    slackId,
  }),
  subdivisionsModel: ({ id = null, name = '' } = {}) => ({ id, name }),
};
