<template>
  <div
    v-click-outside="onClickOutSide"
    class="AppSearch"
    :class="{ 'AppSearch--Open': isSearchOpen }"
  >
    <div class="AppSearch-Input">
      <div class="AppSearch-Wrap">
        <span class="AppSearch-Toggle" @click="onToggleSearch">
          <BaseIcon name="search-md" />
        </span>
        <div class="AppSearch-Slide">
          <BaseIcon name="search-md" />
          <b-form-input
            id="head-search"
            ref="head-search"
            v-model="search"
            class="Input Input--header"
            type="text"
            autocomplete="off"
            :placeholder="$t('header.search.placeholder')"
            @input="onSearch"
            @keydown.enter="onSearch"
          />
        </div>
      </div>
      <div v-if="isShowSearchResult" class="AppSearch-Result ScrollBar--hide">
        <QuestionaryCard
          v-for="card in questionaryList"
          :key="card.id"
          :card="card"
          @click.native="onShowQuestionarySelected(card)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import QuestionaryCard from '@/views/Estimation/Questionary/_components/QuestionaryCard/QuestionaryCard';

import { clickOutside } from '@/services/Directives/clickOutside';

export default {
  name: 'AppSearch',
  components: {
    BaseIcon,
    QuestionaryCard,
  },
  directives: {
    clickOutside,
  },
  data: () => ({
    search: '',
    questionary: {},
    questionaryList: [],
    isSearchOpen: false,
  }),
  computed: {
    ...mapState('Journal', ['questionaries']),
    isShowSearchResult() {
      return this.search && this.questionaryList.length;
    },
  },
  methods: {
    ...mapMutations('Journal', [
      'setSelectedQuestionary',
      'setSelectedQuestionaryState',
      'setSelectedQuestionaryTitle',
    ]),
    ...mapActions('Journal', ['filterQuestionaryList']),
    ...mapActions('Settings', ['toggleMenu']),
    onToggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    onSearch() {
      if (this.search.length > 0) {
        this.filterQuestionaryList({ q: this.search }).then((resultList) => {
          this.questionaryList = resultList;
        });
      } else {
        this.questionaryList = [];
      }
    },
    onClickOutSide() {
      this.isSearchOpen = false;
      this.questionaryList = [];
    },
    onShowQuestionarySelected(data) {
      this.setSelectedQuestionary(data);
      this.setSelectedQuestionaryState(true);
      this.setSelectedQuestionaryTitle(this.$t('admin.pages.journal.show'));
      this.toggleMenu(false);
      this.onClickOutSide();
    },
  },
};
</script>
