import Vue from 'vue';
import AppNotification from '@/components/App/AppNotification';

import i18n from '@/i18n';

const ToastificationCall = (type = '', data = {}, params = {}) => {
  const options = {
    transition: 'Vue-Toastification__bounce',
    toastClassName: 'AppNotificationBase AppNotificationBase__' + type,
    closeButtonClassName: [`AppNotificationBase__${type}-Close`, 'AppNotificationBase-Close'],
    maxToasts: 20,
    newestOnTop: true,
    icon: false,
    position: 'top-right',
    timeout: 5000,
    closeOnClick: false,
  };

  if (typeof Vue.$toast[type] === 'function') {
    Vue.$toast[type](data, { ...options, ...params });
  }
};

const IconParameters = (nameIcon, color) => {
  return {
    heightIcon: '20',
    widthIcon: '20',
    nameIcon: nameIcon || 'warning',
  };
};

export const Notification = {
  success: (message, params) =>
    ToastificationCall(
      'success',
      {
        component: AppNotification,
        props: {
          title: i18n.tc('notifications.success'),
          message: message,
          icon: IconParameters('check'),
        },
      },
      {
        ...params,
      },
    ),
  info: (message, params) =>
    ToastificationCall(
      'info',
      {
        component: AppNotification,
        props: {
          title: i18n.tc('notifications.info'),
          message: message,
          icon: IconParameters('info-circle'),
        },
      },
      {
        ...params,
      },
    ),
  warning: (message, params) =>
    ToastificationCall(
      'warning',
      {
        component: AppNotification,
        props: {
          title: i18n.tc('notifications.warning'),
          message: message,
          icon: IconParameters('bell'),
        },
      },
      {
        ...params,
      },
    ),
  error: (message, params) =>
    ToastificationCall(
      'error',
      {
        component: AppNotification,
        props: {
          title: i18n.tc('notifications.error'),
          message: message,
          icon: IconParameters('warning'),
        },
      },
      {
        ...params,
      },
    ),
};
