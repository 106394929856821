import { vmoment } from '@/services/Helpers';

export const Questionary = {
  questionaryModel: ({
    id = null,
    number = '',
    method = '90',
    dateStart = '',
    dateEnd = '',
    type = 'position',
    positionId = null,
    subdivisionId = null,
    competenceList = [],
    employeeList = [],
    participantList = [],
    isAnonymous = true,
    periodType = '',
  } = {}) => ({
    id,
    number,
    method,
    dateStart: ((dateStart && vmoment(dateStart)) || vmoment()).format('YYYY-MM-DD'),
    dateEnd: ((dateEnd && vmoment(dateEnd)) || vmoment()).format('YYYY-MM-DD'),
    type,
    positionId,
    subdivisionId,
    competenceList,
    employeeList,
    participantList,
    isAnonymous,
    periodType,
  }),
  questionaryEmployeeModel: (id = '') => ({ id }),
  questionaryCompetenceModel: (id = '') => ({ id }),
};
