<template>
  <div class="Brand">
    <div class="Brand-Left">
      <span class="Brand-Img">
        <img v-if="icon" :src="require(`@/assets/Images/Logos/${icon}.svg`)" :alt="icon" />
      </span>
    </div>
    <div class="Brand-Right">
      <div class="Brand-Name">{{ name }}</div>
      <div class="Brand-Url">{{ url }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>