import { Api } from '@/services/General/Api';
import { ENDPOINTS_DICTIONARY } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    competencies: [],
    positions: [],
    employees: [],
    subdivisions: [],
  },
  mutations: {
    getCompetenciesList(state, competencies = []) {
      state.competencies = competencies;
    },
    getPositionList(state, positions = []) {
      state.positions = positions;
    },
    getSubdivisionList(state, subdivisions = []) {
      state.subdivisions = subdivisions;
    },
    getEmployeeList(state, employees = []) {
      state.employees = employees;
    },
  },
  actions: {
    async getCompetenciesList({ commit }) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_DICTIONARY.COMPETENCIES_GET_LIST,
        }).then(({ data } = {}) => {
          commit('getCompetenciesList', data);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getFilterCompetenciesList(_, filter = '') {
      try {
        const { data = [] } = await Api.get({
          requestUrl: `${ENDPOINTS_DICTIONARY.COMPETENCIES_GET_LIST}${filter}`,
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async createCompetenciesItem({ _ }, competence = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_DICTIONARY.COMPETENCIES_ITEM_CREATE,
          data: {
            payload: competence,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateCompetenciesItem({ _ }, competence = {}) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_DICTIONARY.COMPETENCIES_ITEM_UPDATE,
          data: {
            payload: competence,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteCompetenciesItem({ _ }, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_DICTIONARY.COMPETENCIES_ITEM_DELETE,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getPositionsList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_DICTIONARY.POSITIONS_GET_LIST }).then(
          ({ data } = {}) => {
            commit('getPositionList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async getFilterPositionsList(_, filter = '') {
      try {
        const { data = [] } = await Api.get({
          requestUrl: `${ENDPOINTS_DICTIONARY.POSITIONS_GET_LIST}${filter}`,
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async createPositionsItem({ _ }, position = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_DICTIONARY.POSITIONS_ITEM_CREATE,
          data: {
            payload: position,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updatePositionsItem({ _ }, position = {}) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_DICTIONARY.POSITIONS_ITEM_UPDATE,
          data: {
            payload: position,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deletePositionsItem({ _ }, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_DICTIONARY.POSITIONS_ITEM_DELETE,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getSubdivisionsList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_DICTIONARY.SUBDIVISION_GET_LIST }).then(
          ({ data } = {}) => {
            commit('getSubdivisionList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async getFilterSubdivisionsList(_, filter = '') {
      try {
        const { data = [] } = await Api.get({
          requestUrl: `${ENDPOINTS_DICTIONARY.SUBDIVISION_GET_LIST}${filter}`,
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async createSubdivisionsItem({ _ }, subdivision = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_DICTIONARY.SUBDIVISION_ITEM_CREATE,
          data: {
            payload: subdivision,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateSubdivisionsItem({ _ }, subdivision = {}) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_DICTIONARY.SUBDIVISION_ITEM_UPDATE,
          data: {
            payload: subdivision,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteSubdivisionsItem({ _ }, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_DICTIONARY.SUBDIVISION_ITEM_DELETE,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getEmployeesList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_GET_LIST }).then(
          ({ data } = {}) => {
            commit('getEmployeeList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async getFilterEmployeesList(_, filter = '') {
      try {
        const { data = [] } = await Api.get({
          requestUrl: `${ENDPOINTS_DICTIONARY.EMPLOYEES_GET_LIST}${filter}`,
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async createEmployeesItem({ _ }, employee = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_ITEM_CREATE,
          data: {
            payload: employee,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateEmployeesItem({ _ }, employee = {}) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_ITEM_UPDATE,
          data: {
            payload: employee,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteEmployeesItem({ _ }, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_ITEM_DELETE,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getExampleEmployeesUploadFile({ _ }) {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_IMPORT_EXAMPLE,
          notify: false,
          requestConfig: {
            responseType: 'blob',
          },
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async importEmployees({ _ }, formData) {
      try {
        const { data = '' } = Api.post({
          requestUrl: ENDPOINTS_DICTIONARY.EMPLOYEES_IMPORT,
          data: { files: formData },
          notify: false,
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
