<template>
  <div class="QuestionaryInfo">
    <div v-if="isQuestionaryCreated" class="QuestionaryInfo-Created">
      <div class="Actions">
        <span class="Button-Main Button" @click="$emit('questionary-inwork')">
          {{ $t('base-actions.to-work') }}
        </span>
      </div>
      <div class="Text">
        <div>
          <BaseIcon name="info-circle" />
        </div>
        <div>
          {{ $t('admin.pages.journal.questionary.info') }}
        </div>
      </div>
    </div>
    <div v-else-if="isQuestionaryInWork" class="QuestionaryInfo-InWork">
      <div class="Text">
        <div>
          <BaseIcon name="clock" />
        </div>
        <div>
          {{ $t('main.report-in-work') }}
        </div>
      </div>
    </div>
    <template v-else-if="isQuestionaryDoneOrInArchive && !loadingReports">
      <div class="QuestionaryInfo-Done">
        <div v-if="reports.length === 0" class="QuestionaryInfo-Done--NoReport">
          <div class="Text">
            <div>
              <BaseIcon name="alert-circle" />
            </div>
            <div>
              {{ $t('main.done-no-report') }}
            </div>
          </div>
        </div>
        <div v-else class="QuestionaryInfo-Done--WithReport">
          <div class="QuestionaryInfo-Reports">
            <div class="Text">
              {{ $t('main.show-report') }}
            </div>
            <div
              v-for="report in reports"
              :key="`Report-${report.id}`"
              class="QuestionaryInfo-Report"
            >
              <div class="QuestionaryInfo-Report-Actions">
                <span class="QuestionaryInfo-Report-Actions-Name" @click="onShowReportItem(report)">
                  {{ report.subject }}
                </span>
                <span class="Button" @click="onExportReport(report)">
                  <BaseIcon class="me-1" name="arrow-circle-broken-down" />
                  <span> {{ $t('labels.download-pdf') }} </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionaryReport
        :id="nameModalOfReportItem"
        :report="selectedReport"
        @close="onHideReportItem"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import QuestionaryReport from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Modal/QuestionaryReport';

import { QuestionaryIconTypeTesting } from '@/constants/Directories/QuestionaryIconTypeTesting';

import { exportReport } from '@/mixins/DownloadFiles';

export default {
  name: 'QuestionaryInfo',
  components: {
    BaseIcon,
    QuestionaryReport,
  },
  mixins: [exportReport],
  props: {
    questionary: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    nameModalOfReportItem: 'modal-report-item',
    selectedReport: {},
    reports: [],
    icons: QuestionaryIconTypeTesting,
    loadingReports: false,
  }),
  computed: {
    questionaryId() {
      return this.questionary?.id || '';
    },
    isQuestionaryCreated() {
      return this.status === 'created';
    },
    isQuestionaryInWork() {
      return this.status === 'inwork';
    },
    isQuestionaryDoneOrInArchive() {
      return this.status === 'done' || this.status === 'archived';
    },
  },
  mounted() {
    if (this.isQuestionaryDoneOrInArchive) {
      this.loadingReports = true;
      this.getReportsByQuestionaryId(this.questionaryId).then((reports) => {
        this.reports = reports;
        this.loadingReports = false;
      });
    }
  },
  methods: {
    ...mapActions({
      getReportsByQuestionaryId: 'Reports/getReportsByQuestionaryId',
      exportHandler: 'Reports/exportReportByQuestionaryIdAndEmployeeID',
    }),
    onShowReportItem(report) {
      this.selectedReport = report;
      this.$bvModal.show(this.nameModalOfReportItem);
    },
    onHideReportItem() {
      this.$bvModal.hide(this.nameModalOfReportItem);
    },
    onExportReport(report) {
      // Метод дергает exportHandler
      this.exportReport(report);
    },
  },
};
</script>
