<template>
  <div class="TemplateListActions">
    <span class="Button--width-auto Button-InTables Button" @click="$emit('edit')">
      <BaseIcon height="16" width="16" name="edit" />
      <span class="Text">{{ $t('base-actions.edit') }}</span>
    </span>
    <span class="Button--width-auto Button-InTables Button" @click="$emit('reset')">
      <BaseIcon height="16" width="16" name="refresh-ccw" />
      <span class="Text">{{ $t('base-actions.refresh') }}</span>
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'TemplateListActions',
  components: { BaseIcon },
};
</script>