import { Api } from '@/services/General/Api';
import { ENDPOINTS_TEMPLATES } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    emails: null,
    notifications: null,
  },
  mutations: {
    updateTemplatesEmailList(state, templates) {
      state.emails = templates;
    },
    updateTemplatesNotificationList(state, templates) {
      state.notifications = templates;
    },
  },
  actions: {
    async getTemplatesEmailList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_EMAIL_GET_LIST }).then(
          ({ data } = {}) => {
            commit('updateTemplatesEmailList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateTemplatesEmailItem({ _ }, { id = null, text = '' } = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_EMAIL_UPDATE,
          data: {
            payload: {
              id,
              text,
            },
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async resetTemplatesEmailItem({ _ }, id = null) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_EMAIL_RESET,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getTemplatesNotificationList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_NOTIFICATION_GET_LIST }).then(
          ({ data } = {}) => {
            commit('updateTemplatesNotificationList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateTemplatesNotificationItem({ _ }, { id = null, text = '' } = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_NOTIFICATION_UPDATE,
          data: {
            payload: {
              id,
              text,
            },
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async resetTemplatesNotificationItem({ _ }, id = null) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_TEMPLATES.TEMPLATES_NOTIFICATION_RESET,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
