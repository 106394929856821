import Vue from 'vue';
import VueRouter from 'vue-router';

// Modules
import AuthModule from './modules/Auth';
import StructureModule from './modules/Structure';
import EstimationModule from './modules/Estimation';
import LegalModule from './modules/Legal';
import GeneralModule from './modules/General';

Vue.use(VueRouter);

const routes = [
  ...AuthModule,
  ...StructureModule,
  ...EstimationModule,
  ...LegalModule,
  ...GeneralModule,
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
