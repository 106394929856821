<template>
  <div class="QuestionaryPeriod">
    <BaseSelect
      v-model="select"
      class="Select--WidthBorder"
      option-value="name"
      option-key="id"
      :items="questionaryPeriods"
      :placeholder="$t('admin.pages.journal.questionary.no-period')"
      clearable
      @input="$emit('select-period', select || '')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';

export default {
  name: 'QuestionaryPeriod',
  components: { BaseSelect },
  props: {
    selectOption: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data: () => ({
    select: '',
  }),
  computed: {
    ...mapGetters('Directories', ['structureForQuestionaryCreate']),
    questionaryPeriods() {
      return this.structureForQuestionaryCreate?.questionaryPeriod || null;
    },
  },
  mounted() {
    this.select = this.selectOption || '';
    this.$emit('select-period', this.select);
  },
};
</script>