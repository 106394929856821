<template>
  <div class="BaseTabs">
    <div v-if="$scopedSlots.labels" class="BaseTabs-WrapLabels">
      <div class="BaseTabs-Labels">
        <slot name="labels"></slot>
      </div>
    </div>
    <div v-if="$scopedSlots.additional" class="BaseTabs-Additional">
      <slot name="additional"></slot>
    </div>
    <div class="BaseTabs-WrapContent">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
};
</script>