<template>
  <div class="BaseModalWrap">
    <div v-if="$scopedSlots.title || $scopedSlots.actions" class="BaseModalWrap-Header">
      <div v-if="$scopedSlots.title" class="BaseModalWrap-Title">
        <slot name="title"></slot>
      </div>
      <div v-if="$scopedSlots.actions" class="BaseModalWrap-Actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div v-if="$scopedSlots.default" class="BaseModalWrap-Body">
      <slot name="default"></slot>
    </div>
    <div v-if="$scopedSlots.footer" class="BaseModalWrap-Footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModalWrap',
};
</script>