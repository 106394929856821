import Vue from 'vue';
import Vuex from 'vuex';
import { HelperStoreActions } from '@/services/Helpers';

// Modules
import Loading from '@/store/Modules/Loading';
import Notifications from '@/store/Modules/Notifications';
import Directories from '@/store/Modules/Directories';
import Admin from '@/store/Modules/Admin';
import Questionary from '@/store/Modules/Questionary';
import Journal from '@/store/Modules/Journal';
import Dictionary from '@/store/Modules/Dictionary';
import Reports from '@/store/Modules/Reports';
import Settings from '@/store/Modules/Settings';
import Testing from '@/store/Modules/Testing';
import Templates from '@/store/Modules/Templates';
import Users from '@/store/Modules/Users';
import Structure from '@/store/Modules/Structure';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Loading,
    Notifications,
    Directories,
    Admin,
    Questionary,
    Journal,
    Dictionary,
    Reports,
    Testing,
    Settings,
    Templates,
    Users,
    Structure,
  },
  state: {
    routeFrom: null,
  },
  mutations: {
    setRouteFrom(state, route) {
      state.routeFrom = route;
    },
  },
  actions: {
    setRouteUserFrom({ commit }, route) {
      commit('setRouteFrom', route);
    },
  },
});

store.subscribeAction({
  before: (action) => {
    HelperStoreActions.addActionToCallStack(action.type, store);
  },
  after: (action) => {
    HelperStoreActions.removeActionFromCallStack(action.type, store);
  },
  error: (action) => {
    HelperStoreActions.removeActionFromCallStack(action.type, store);
  },
});

export default store;
