<template>
  <div :key="`App-${routerKey}`" class="Admin">
    <template>
      <Navigation class="Admin-Navigation" @logout="onLogout" />
      <Header class="Admin-Header" />
    </template>
    <div class="Admin-Content">
      <router-view v-if="!isLoadingInitData" />
    </div>
    <AppSideBarList />
    <AppModalLogout
      :id="logoutModal"
      @auth-logout-success="onConfirmLogout"
      @auth-logout-cancel="onCancelLogout"
    />
    <div
      class="Admin-Overlay"
      :class="{ 'Admin-Overlay--Show': isShowOverlay }"
      @click="toggleMenu(false)"
    ></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Header from '@/components/App/AppHeader';
import Navigation from '@/components/App/AppNavigation';
import AppModalLogout from '@/components/App/AppModalLogout';
import AppSideBarList from '@/components/App/AppSideBarList';

export default {
  name: 'LayoutAdmin',
  components: { Header, Navigation, AppModalLogout, AppSideBarList },
  data: () => ({
    logoutModal: 'logout-modal',
    routerKey: 0,
    isLoadingInitData: false,
  }),
  computed: {
    ...mapState({
      isShowOverlay: (state) => state.Settings.toggleMenu,
      currentBrand: (state) => state.Settings.brand,
    }),
    ...mapGetters('Users', ['userAvailableRoutes']),
  },
  watch: {
    '$i18n.locale'() {
      this.routerKey++;
    },
    currentBrand(value, oldValue) {
      if (oldValue) this.routerKey++;
    },
    routerKey: {
      immediate: true,
      handler() {
        this.getInitData();
      },
    },
  },
  methods: {
    ...mapActions('Admin', ['logoutUser', 'getCurrentUserInfo']),
    ...mapActions('Directories', ['getQuestionaryStructure']),
    ...mapActions('Users', ['getAvailableRoutes']),
    ...mapActions('Settings', ['toggleMenu']),
    getInitData() {
      this.isLoadingInitData = true;
      Promise.allSettled([
        this.getCurrentUserInfo(),
        this.getAvailableRoutes(),
        this.getQuestionaryStructure(),
      ]).then(() => {
        this.isLoadingInitData = false;
        if (
          !this.userAvailableRoutes.includes('questionary_questionary_list') &&
          this.$route.name === 'home'
        ) {
          this.$router.push({ name: 'reports' });
        }
      });
    },
    onLogout() {
      this.$bvModal.show(this.logoutModal);
    },
    onConfirmLogout() {
      this.logoutUser().then(() => {
        this.$bvModal.hide(this.logoutModal);
      });
    },
    onCancelLogout() {
      this.$bvModal.hide(this.logoutModal);
    },
  },
};
</script>