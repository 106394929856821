export const languages = [
  {
    code: 'ru',
    icon: 'ru',
    title: 'Русский',
  },
  {
    code: 'en',
    icon: 'en',
    title: 'English',
  },
];
