import Vue from 'vue';
import Toast from 'vue-toastification';

const options = {
  transition: 'Vue-Toastification__bounce',
  timeout: 3000,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  },
};

Vue.use(Toast, options);
