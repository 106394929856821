<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--EmployeeForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>{{ $t('modals.directory.tabs.employees') }}</template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="EmployeeFormData">
        <div class="EmployeeFormData-Fields">
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-name') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-firstName"
                v-model.trim.lazy="$v.form.firstName.$model"
                :state="validateState('firstName')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="`${$t('placeholders.enter-name')}`"
              ></b-form-input>
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-second-name') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-lastName"
                v-model.trim.lazy="$v.form.lastName.$model"
                :state="validateState('lastName')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="`${$t('placeholders.enter-lastname')}`"
              ></b-form-input>
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-patronymic') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-middleName"
                v-model.trim.lazy="$v.form.middleName.$model"
                :state="validateState('middleName')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="`${$t('placeholders.enter-second-name')}`"
              ></b-form-input>
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-position') }}:</div>
            <div class="Field">
              <BaseSelect
                v-model="$v.form.positionId.$model"
                class="Select--WidthBorder"
                option-value="name"
                option-key="id"
                :items="positions"
                :placeholder="$t('select.no-select')"
                :state="validateState('positionId')"
                clearable
                searchable
              />
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-subdivision') }}:</div>
            <div class="Field">
              <BaseSelect
                v-model="$v.form.subdivisionId.$model"
                class="Select--WidthBorder"
                option-value="name"
                option-key="id"
                :items="subdivisions"
                :placeholder="$t('select.no-select')"
                :state="validateState('subdivisionId')"
                clearable
                searchable
              />
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-email') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-email"
                v-model.trim.lazy="$v.form.email.$model"
                :state="validateState('email')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="`${$t('placeholders.email')}`"
              ></b-form-input>
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">
              <img
                class="Messenger me-1"
                :src="require(`@/assets/Images/Messenger/slack.svg`)"
                alt="slack-icon"
              />{{ $t('labels.slack-id') }}:
            </div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.slackId.$model"
                :state="validateState('slackId')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.slack-id')"
              ></b-form-input>
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.locale') }}:</div>
            <div class="Field">
              <BaseSelect
                v-model="$v.form.locale.$model"
                class="Select--WidthBorder"
                option-value="title"
                option-key="code"
                :items="languages"
                :placeholder="$t('select.no-select')"
                searchable
                clearable
              />
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-time-in-company') }}:</div>
            <div class="Field">
              <BaseDatepicker
                id="inCompany"
                v-model="$v.form.inCompany.$model"
                :state="validateState('inCompany')"
              />
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-time-in-position') }}:</div>
            <div class="Field">
              <BaseDatepicker
                id="inPosition"
                v-model="$v.form.inPosition.$model"
                :state="validateState('inPosition')"
              />
            </div>
          </div>
          <div class="EmployeeFormData-Field">
            <div class="Label-Input">{{ $t('labels.employee-work-experience') }}:</div>
            <div class="Field">
              <BaseSelect
                v-model="$v.form.workExpirience.$model"
                class="Select--WidthBorder"
                option-value="name"
                option-key="value"
                :items="listYears"
                :placeholder="$t('select.no-select')"
                searchable
                clearable
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @save="sendActionType" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseDatepicker from '@/components/Base/BaseDatepicker';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';
import BaseSelect from '@/components/Base/BaseSelect';

import { ObjectFunctions } from '@/services/Helpers';
import { required, email } from 'vuelidate/lib/validators';

import { languages } from '@/constants/SiteLanguages';

const workExpirience = (yearIterator = 4) => {
  const currentYear = new Date().getFullYear();
  const listYears = [];
  for (let year = currentYear - yearIterator; year <= currentYear; year++) {
    listYears.push({ name: year, value: `${year}` });
  }
  return listYears.sort(function (a, b) {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    return 0;
  });
};

export default {
  name: 'DictionaryFormCreateEmployees',
  components: {
    BaseIcon,
    BaseModalWrap,
    BaseActions,
    BaseDatepicker,
    BaseSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    itemModel: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: {},
    listYears: workExpirience(29),
    languages,
  }),
  validations: {
    form: {
      lastName: {
        required,
      },
      firstName: {
        required,
      },
      middleName: {},
      positionId: {
        required,
      },
      subdivisionId: {
        required,
      },
      email: {
        required,
        email,
      },
      inCompany: {
        required,
      },
      inPosition: {
        required,
      },
      workExpirience: {},
      locale: {
        required,
      },
      slackId: {},
    },
  },
  computed: {
    ...mapState('Dictionary', ['positions', 'subdivisions']),
    modalActions() {
      return [
        { action: 'save', class: 'Button-Main', disabled: this.$v.form.$anyError },
        { action: 'cancel' },
      ];
    },
  },
  watch: {
    itemModel() {
      this.form = ObjectFunctions.deepClone(this.itemModel);
      this.$v.$touch();
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    sendActionType() {
      if (!this.$v.form.$anyError) {
        this.$emit(this.actionType, this.form);
      }
    },
  },
};
</script>
