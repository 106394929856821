<template>
  <div class="UserListActions">
    <span class="Button--width-auto Button-InTables Button" @click="$emit('edit')">
      <BaseIcon height="16" width="16" name="edit" />
      <span class="Text">{{ $t('base-actions.edit') }}</span>
    </span>
    <span class="Button--width-auto Button-InTables Button" @click="$emit('delete')">
      <BaseIcon height="16" width="16" name="trash" />
      <span class="Text">{{ $t('base-actions.delete') }}</span>
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
export default {
  name: 'UserListActions',
  components: { BaseIcon },
};
</script>