export const STRUCTURE_EMPLOYEES_HEADERS = [
  {
    key: 'name',
    name: 'Name',
    style: 'width: 20%; min-width: 220px',
    sortable: true,
  },
  {
    key: 'status',
    name: 'Status',
    style: 'width: 5%; min-width: 150px',
    sortable: true,
  },
  {
    key: 'inCompany',
    name: 'InCompany',
    colName: 'InCompany',
    style: 'width: 10%; min-width: 100px',
    sortable: true,
  },
  {
    key: 'phone',
    name: 'Phone',
    colName: 'Phone',
    style: 'width: 10%; min-width: 120px',
  },
  {
    key: 'position',
    name: 'Position',
    style: 'width: 20%; min-width: 220px',
    sortable: true,
  },
  {
    key: 'subdivision',
    name: 'Subdivision',
    style: 'width: 20%; min-width: 220px',
  },
  {
    key: 'slackId',
    name: 'Slack',
    colName: 'Slack',
    style: 'width: 5%; min-width: 80px',
  },
];
