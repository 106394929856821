<template>
  <div class="ParticipantCard" :class="{ 'ParticipantCard--WidthActions': $scopedSlots.actions }">
    <span class="ParticipantCard-Avatar">
      {{ [item.lastName, item.firstName] | firstLetters }}
    </span>
    <div class="ParticipantCard-Info">
      <div class="ParticipantCard-Info--Fio">
        {{ [item.lastName, item.firstName] | joinValuesWithSpaces }}
      </div>
      <div class="ParticipantCard-Info--Email">
        {{ item.email }}
      </div>
    </div>
    <div v-if="$scopedSlots.actions" class="ParticipantCard-Actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import { firstLetters, joinValuesWithSpaces } from '@/services/Filters';

export default {
  name: 'ParticipantCard',
  filters: { firstLetters, joinValuesWithSpaces },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>