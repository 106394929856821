<template>
  <div v-if="!isEmptyQuestionaryModel" class="QuestionarySelected">
    <QuestionaryDetail
      :questionary="questionary"
      @questionary-edit="onQuestionaryEdit"
      @questionary-duplicate="onQuestionaryDuplicate"
      @questionary-archive="onQuestionaryArchive"
      @questionary-delete="onQuestionaryDelete"
      @questionary-inwork="onQuestionaryStatusInWork"
      @cancel="onCloseQuestionaryDetail"
    />
    <BaseSidebar v-model="isOpenSideBarOfQuestionaryFormEdit">
      <template #title>{{ $t('admin.pages.journal.edit') }}</template>
      <QuestionaryFormEdit
        :questionary-item="questionary"
        @save="onUpdateQuestionary"
        @cancel="onHideEditQuestionaryForm"
      />
    </BaseSidebar>
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.delete')"
      @confirm="onConfirmModalAction"
      @cancel="onHideConfirmAction"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseSidebar from '@/components/Base/BaseSidebar';

import QuestionaryDetail from '@/views/Estimation/Questionary/_components/QuestionaryDetail/QuestionaryDetail';
import QuestionaryFormEdit from '@/views/Estimation/Questionary/_components/QuestionaryFormEdit';
import AppModalConfirm from '@/components/App/AppModalConfirm';

export default {
  name: 'QuestionarySelected',
  components: {
    BaseSidebar,
    QuestionaryDetail,
    QuestionaryFormEdit,
    AppModalConfirm,
  },
  props: {
    questionary: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpenSideBarOfQuestionaryFormEdit: false,
    nameModalOfEditQuestionary: 'modal-questianary-edit',
    nameModalOfConfirmAction: 'modal-confirm-questionary-delete',
  }),
  computed: {
    isEmptyQuestionaryModel() {
      return Object.keys(this.questionary).length === 0;
    },
  },
  methods: {
    ...mapActions('Journal', ['getQuestionaryList']),
    ...mapActions('Questionary', [
      'updateStatusQuestionary',
      'dublicateQuestionary',
      'deleteQuestionary',
      'updateQuestionary',
    ]),
    updateQuestionaryItemsList() {
      this.onCloseQuestionaryDetail();
      this.getQuestionaryList();
    },
    onCloseQuestionaryDetail() {
      this.$emit('close');
    },
    onQuestionaryEdit() {
      this.isOpenSideBarOfQuestionaryFormEdit = true;
    },
    onQuestionaryDuplicate() {
      this.dublicateQuestionary({ id: this.questionary?.id }).then(() => {
        this.updateQuestionaryItemsList();
      });
    },
    onQuestionaryArchive() {
      this.updateStatusQuestionary({ id: this.questionary?.id, status: 'archived' }).then(() => {
        this.updateQuestionaryItemsList();
      });
    },
    onQuestionaryDelete() {
      this.$bvModal.show(this.nameModalOfConfirmAction);
    },
    onQuestionaryStatusInWork() {
      this.updateStatusQuestionary({ id: this.questionary?.id, status: 'inwork' }).then(() => {
        this.updateQuestionaryItemsList();
      });
    },
    onUpdateQuestionary(questionary) {
      this.updateQuestionary(questionary).then(() => {
        this.updateQuestionaryItemsList();
      });
    },
    onHideEditQuestionaryForm() {
      this.isOpenSideBarOfQuestionaryFormEdit = false;
    },
    onConfirmModalAction() {
      this.deleteQuestionary(this.questionary?.id)
        .then(() => {
          this.$bvModal.hide(this.nameModalOfConfirmAction);
          this.updateQuestionaryItemsList();
        })
        .catch(() => {
          this.$bvModal.hide(this.nameModalOfConfirmAction);
        });
    },
    onHideConfirmAction() {
      this.$bvModal.hide(this.nameModalOfConfirmAction);
    },
  },
};
</script>
