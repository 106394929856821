import { Api } from '@/services/General/Api';
import { ENDPOINTS_NOTIFICATIONS } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    setNotificationsList(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    async getNotificationsList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_NOTIFICATIONS.NOTIFICATIONS_GET_LIST }).then(
          ({ data } = {}) => {
            commit('setNotificationsList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateNotificationStatus(_, ids = []) {
      try {
        return await Api.post({
          requestUrl: ENDPOINTS_NOTIFICATIONS.NOTIFICATIONS_UPDATE,
          data: {
            payload: { idList: ids },
          },
          notify: false,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
