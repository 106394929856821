<template>
  <b-modal
    :id="id"
    size="md"
    body-class="BaseModal BaseModal--EmployeeImport"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>{{ $t('admin.pages.directory.upload') }}</template>
      <template #actions>
        <span class="Button--reset Button" @click="onClose">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div
        class="UploadStatus"
        :class="{
          'UploadStatus--Opened': file || uploadError,
          'UploadStatus--Success': !uploadError,
          'UploadStatus--Error': uploadError,
        }"
      >
        <BaseIcon :name="(!uploadError && 'check') || 'info-circle'" />
        {{ (uploadError && $t('upload.error')) || $t('upload.success') }}
      </div>
      <div class="EmployeeImportFormData">
        <div
          class="EmployeeImportFormData-DragDrop"
          @drop.prevent="onDropHandler"
          @dragover.prevent
        >
          <div class="EmployeeImportFormData-DragDrop-Info">
            <template v-if="file">
              <div class="TargetWrap">
                <div class="TargetFile">
                  .CSV
                  <span class="TargetFile-Delete" @click="onRemoveTargetFile">
                    <BaseIcon name="x-close" width="14" height="14" />
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              {{ $t('labels.information') }}
            </template>
          </div>
          <div class="EmployeeImportFormData-DragDrop-Button">
            <label for="upload-employee">
              <span class="Button-Main Button Medium">
                {{ $t('base-actions.choose-file') }}
              </span>
              <input
                id="upload-employee"
                ref="file"
                hidden
                type="file"
                accept=".csv"
                @change="onSelectFile"
              />
            </label>
          </div>
        </div>
        <div class="EmployeeImportFormData-Example" @click="onGetExample">
          <BaseIcon name="download" width="12" height="12" /> {{ $t('labels.csv-example') }}
        </div>
        <div v-if="file" class="EmployeeImportFormData-FileInfo">
          <div class="EmployeeImportFormData-FileInfo--Data">
            <div class="EmployeeImportFormData-Section EmployeeImportFormData-FileInfo--Name">
              <div class="LabelName">{{ $t('labels.file') }}:</div>
              <div class="DataValue">
                {{ fileName }}
              </div>
            </div>
            <div class="EmployeeImportFormData-Section EmployeeImportFormData-FileInfo--Size">
              <div class="LabelName">{{ $t('labels.size') }}:</div>
              <div class="DataValue">{{ fileSize }}Kb</div>
            </div>
          </div>
        </div>
        <div v-if="errorList.length" class="EmployeeImportFormData-Errors">
          <div
            v-for="({ errorList, rowIndex }, index) in errorList"
            :key="`import-error-row-${index}`"
            class="EmployeeImportFormData-ErrorRow"
          >
            <div class="EmployeeImportFormData-ErrorRow-Index">
              {{ $t('labels.line') }} {{ rowIndex }}:
            </div>
            <div class="EmployeeImportFormData-ErrorRow-Data">
              <div class="EmployeeImportFormData-ErrorRow-ErrorInfo">
                <div
                  v-for="(error, indexError) in errorList"
                  :key="`error-${indexError}`"
                  class="EmployeeImportFormData-ErrorRow-Error"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @upload="onUpload" @cancel="onClose" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

export default {
  name: 'DictionaryFormImportEmployees',
  components: { BaseIcon, BaseModalWrap, BaseActions },
  props: {
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    file: null,
    errorList: [],
    uploadError: false,
  }),
  computed: {
    modalActions() {
      return [
        {
          action: 'upload',
          class: `${!this.file && 'Button-Disabled'} Button-Main`,
          icon: {
            name: 'upload',
            left: true,
            width: '14',
            height: '14',
            class: 'me-1',
          },
        },
        { action: 'cancel' },
      ];
    },
    fileName() {
      return this.file?.name || '';
    },
    fileSize() {
      return (this.file?.size && (this.file.size / 1024).toFixed(2)) || 0;
    },
  },
  watch: {
    errors(data) {
      this.errorList = data;
      if (data.length) {
        this.uploadError = true;
      }
    },
  },
  methods: {
    onSelectFile() {
      this.file = this.$refs.file.files[0];
      this.uploadError = false;
      this.errorList = [];
    },
    onClose() {
      this.$emit('cancel');
      this.file = null;
      this.uploadError = false;
    },
    onUpload() {
      const formData = new FormData();
      formData.append('file', this.file);
      this.$emit('upload', formData);
    },
    onGetExample() {
      this.$emit('get-example');
    },
    onDropHandler($event) {
      const { files } = $event.dataTransfer;
      if (files) {
        const { type = null } = files[0];
        if (type === 'text/csv') {
          this.file = files[0];
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      }
    },
    onRemoveTargetFile() {
      this.file = null;
      this.uploadError = false;
      this.errorList = [];
    },
  },
};
</script>