export const QuestionaryParticipantsBlocks = {
  90: ['supervisor'],
  180: ['supervisor', 'employee'],
  360: ['supervisor', 'colleague', 'subordinate', 'customer', 'employee'],
};

export const QuestionaryParticipantsToShow = {
  90: ['supervisor'],
  180: ['supervisor'],
  360: ['supervisor', 'colleague', 'subordinate', 'customer'],
};
