<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--CompetenceForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>{{ $t('modals.directory.tabs.competencies') }}</template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="CompetenceFormData">
        <div class="CompetenceFormData-Names">
          <div class="CompetenceFormData-Info">
            <div class="CompetenceFormData-Title">{{ $t('labels.competencies-main') }}</div>
            <div class="CompetenceFormData-SubTitle">
              {{ $t('labels.competencies-desc') }}
            </div>
          </div>
          <div class="CompetenceFormData-List">
            <div class="CompetenceFormData-Item Ru">
              <div class="CompetenceFormData-Item-In">
                <div class="CompetenceFormData-Label">{{ $t('labels.competence-name-ru') }}:</div>
                <b-form-input
                  v-model.trim.lazy="$v.form.nameText.ru.$model"
                  :state="validateState('nameText', 'ru')"
                  class="Input Medium"
                  autocomplete="off"
                  :placeholder="`${$t('placeholders.enter-name')}`"
                ></b-form-input>
              </div>
              <div class="CompetenceFormData-Item-In">
                <div class="CompetenceFormData-Label">{{ $t('labels.description-ru') }}:</div>
                <b-form-textarea
                  v-model="$v.form.descriptionText.ru.$model"
                  :state="validateState('descriptionText', 'ru')"
                  class="Textarea"
                  :placeholder="$t('placeholders.enter-text')"
                  no-resize
                ></b-form-textarea>
              </div>
            </div>
            <div class="CompetenceFormData-Item En">
              <div class="CompetenceFormData-Item-In">
                <div class="CompetenceFormData-Label">{{ $t('labels.competence-name-en') }}:</div>
                <b-form-input
                  v-model.trim.lazy="$v.form.nameText.en.$model"
                  :state="validateState('nameText', 'en')"
                  class="Input Medium"
                  autocomplete="off"
                  :placeholder="`${$t('placeholders.enter-name')}`"
                ></b-form-input>
              </div>
              <div class="CompetenceFormData-Item-In">
                <div class="CompetenceFormData-Label">{{ $t('labels.description-en') }}:</div>
                <b-form-textarea
                  v-model="$v.form.descriptionText.en.$model"
                  :state="validateState('descriptionText', 'en')"
                  class="Textarea"
                  :placeholder="$t('placeholders.enter-text')"
                  no-resize
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="CompetenceFormData-Questions">
          <div class="CompetenceFormData-Info">
            <div class="CompetenceFormData-Title">{{ $t('labels.questions') }}</div>
            <div class="CompetenceFormData-SubTitle">
              {{ $t('labels.competencies-desc') }}
            </div>
          </div>
          <div class="CompetenceFormData-Questions-List">
            <div
              v-for="(question, index) in $v.form.questionList.$each.$iter"
              :key="`Question-${index}`"
              class="CompetenceFormData-Questions-Item"
            >
              <div class="Number-Block">
                <span> {{ parseInt(index) + 1 }}: </span>
              </div>
              <div class="Textarea-Block">
                <b-form-textarea
                  v-model="question.questionText.ru.$model"
                  :state="validateStateQuestion(index, 'ru')"
                  class="Textarea"
                  :placeholder="$t('placeholders.enter-text-ru')"
                  no-resize
                ></b-form-textarea>
                <b-form-textarea
                  v-model="question.questionText.en.$model"
                  :state="validateStateQuestion(index, 'en')"
                  class="Textarea"
                  :placeholder="$t('placeholders.enter-text-en')"
                  no-resize
                ></b-form-textarea>
              </div>
              <div class="Delete-Block">
                <span
                  v-if="isCanDeleteItem"
                  class="Button--reset Button"
                  @click="deleteQuestion(index)"
                >
                  <BaseIcon height="16" width="16" name="trash" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="CompetenceFormData-Add">
          <span class="Button" @click="addQuestion">
            <BaseIcon width="16" height="16" name="plus" class="me-1" />
            {{ $t('base-actions.add-question') }}
          </span>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @save="sendActionType" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

import { CompetenceList } from '@/store/Models';
import { ObjectFunctions } from '@/services/Helpers/HelperObject';
import { required } from 'vuelidate/lib/validators';

import { toolbarVueEditor } from '@/plugins/VueEditor/OptionsVueEditor';

export default {
  name: 'DictionaryFormCreateCompetencies',
  components: { BaseIcon, BaseModalWrap, BaseActions },
  props: {
    id: {
      type: String,
      required: true,
    },
    itemModel: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: {},
    toolbarVueEditor: toolbarVueEditor,
  }),
  validations: {
    form: {
      nameText: {
        ru: {
          required,
        },
        en: {},
      },
      descriptionText: {
        ru: {
          required,
        },
        en: {},
      },
      questionList: {
        $each: {
          questionText: {
            ru: {
              required,
            },
            en: {},
          },
        },
      },
    },
  },
  computed: {
    isCanDeleteItem() {
      return this.form?.questionList.length - 1 >= 1;
    },
    modalActions() {
      return [
        { action: 'save', class: 'Button-Main', disabled: this.$v.form.$anyError },
        { action: 'cancel' },
      ];
    },
  },
  watch: {
    itemModel(model) {
      this.form = ObjectFunctions.deepClone(model);
      this.$v.$touch();
    },
  },
  methods: {
    addQuestion() {
      this.form.questionList.push(CompetenceList.questionModel());
      this.$v.$touch();
    },
    deleteQuestion(index) {
      if (this.isCanDeleteItem) this.form.questionList.splice(index, 1);
    },
    validateState(name, locale) {
      const { $dirty, $error } = this.$v.form[name][locale];
      return $dirty ? !$error : null;
    },
    validateStateQuestion(index, locale) {
      const { $dirty, $error } =
        this.$v.form?.questionList.$each.$iter?.[index].questionText[locale] || {};
      return $dirty ? !$error : null;
    },
    sendActionType() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.prepareForm();
        this.$emit(this.actionType, this.form);
      }
    },
    // Todo: Костыльный метод удаления пустых en-полей в форме. После удалить его как на беке пофиксят логику.
    prepareForm() {
      if (this.form.nameText.en === '') {
        delete this.form.nameText.en;
      }
      if (this.form.descriptionText.en === '') {
        delete this.form.descriptionText.en;
      }

      this.form.questionList = this.form.questionList.map((q) => {
        if (q.questionText.en === '') {
          delete q.questionText.en;
        }
        return q;
      });
    },
  },
};
</script>
