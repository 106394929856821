<template>
  <div class="UiKit">
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col cols="12">
          <h1>Switchers</h1>
          <div class="mt-5 mb-5">
            <b-form-checkbox v-model="isDark" class="Switcher Switcher--Reverse m-2">
              <span class="me-2">Dark theme?</span>
            </b-form-checkbox>
            <br />
            <b-form-checkbox v-model="isDuxgroup" class="Switcher m-2">
              <span class="ms-2">Is Duxgroup?</span>
            </b-form-checkbox>
          </div>
          <hr />
          <br />
          <h1>Buttons</h1>
          <div class="mt-5 mb-5" style="display: flex; flex-wrap: wrap">
            <button class="Button m-2">Base</button>
            <button class="Button-Main Button m-2">
              <BaseIcon width="16" height="16" name="plus" class="me-2" /> Main
            </button>
            <button class="Button-Secondary Button m-2">Secondary</button>
            <button class="Button-InTables Button m-2">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> In tables
            </button>
            <button class="Button m-2">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Delete
            </button>
            <button class="Button-Disabled Button m-2">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Disabled
            </button>
          </div>
          <div class="mt-5 mb-5" style="display: flex; flex-wrap: wrap">
            <button class="Button m-2 Medium">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Base
            </button>
            <button class="Medium Button-Main Button m-2">Main</button>
            <button class="Medium Button-Secondary Button m-2">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Secondary
            </button>
            <button class="Medium Button-InTables Button m-2">In tables</button>
            <button class="Button m-2 Medium">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Delete
            </button>
            <button class="Button-Disabled Button m-2 Medium">
              <BaseIcon width="16" height="16" name="bell" class="me-2" /> Disabled
            </button>
          </div>
          <hr />
          <br />
          <h1>Inputs</h1>
          <div class="mt-5 mb-5">
            <b-form-input class="Input m-2" type="text" placeholder="Placeholder"></b-form-input>
            <b-form-input
              class="Input Input--no-border m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input Input--error m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input Input--success m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input m-2"
              type="text"
              placeholder="Placeholder"
              disabled
            ></b-form-input>
          </div>
          <div class="mt-5 mb-5">
            <b-form-input
              class="Input Medium m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input Input--no-border Medium m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input Input--error Medium m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input Input--success Medium m-2"
              type="text"
              placeholder="Placeholder"
            ></b-form-input>
            <b-form-input
              class="Input m-2 Medium"
              type="text"
              placeholder="Placeholder"
              disabled
            ></b-form-input>
          </div>
          <hr />
          <br />
          <h1>Textareas</h1>
          <div class="mt-5 mb-5">
            <b-textarea placeholder="Placeholder" class="Textarea m-2"></b-textarea>
            <b-textarea disabled placeholder="Placeholder" class="Textarea m-2"></b-textarea>
            <b-textarea placeholder="Placeholder" class="Textarea Textarea--error m-2"></b-textarea>
            <b-textarea
              placeholder="Placeholder"
              class="Textarea Textarea--success m-2"
            ></b-textarea>
          </div>
          <hr />
          <br />
          <h1>Favicon</h1>
          <div class="mt-5 mb-5">
            <img src="@/assets/Images/Favicon/favicon32x32.svg" alt="favicon" />
            <img src="@/assets/Images/Favicon/favicon24x24.svg" alt="favicon" />
            <img src="@/assets/Images/Favicon/favicon16x16.svg" alt="favicon" />
          </div>
          <hr />
          <br />
          <h1>Brands logo</h1>
          <div class="mt-5 mb-5">
            <img class="Icon-Image" src="@/assets/Images/Logos/marfa.svg" alt="marfa" />
            <img class="Icon-Image" src="@/assets/Images/Logos/wakeapp.svg" alt="wakeapp" />
            <img class="Icon-Image" src="@/assets/Images/Logos/dux.svg" alt="dux" />
          </div>
          <hr />
          <br />
          <h1>Messanger</h1>
          <div class="mt-5 mb-5">
            <img class="Icon-Image" src="@/assets/Images/Messenger/slack.svg" alt="slack" />
          </div>
          <hr />
          <br />
          <h1>Icons</h1>
          <div class="mt-5 mb-5">
            <BaseIcon width="30" height="30" class="m-2" name="eye" />
            <BaseIcon width="30" height="30" class="m-2" name="eye-close" />
            <BaseIcon width="30" height="30" class="m-2" name="filter-lines" />
            <BaseIcon width="30" height="30" class="m-2" name="check-circle" />
            <BaseIcon width="30" height="30" class="m-2" name="switch-vertical" />
            <BaseIcon width="30" height="30" class="m-2" name="alert-circle" />
            <BaseIcon width="30" height="30" class="m-2" name="archive" />
            <BaseIcon width="30" height="30" class="m-2" name="arrow-circle-broken-down" />
            <BaseIcon width="30" height="30" class="m-2" name="bell" />
            <BaseIcon width="30" height="30" class="m-2" name="book-open" />
            <BaseIcon width="30" height="30" class="m-2" name="brackets" />
            <BaseIcon width="30" height="30" class="m-2" name="calendar" />
            <BaseIcon width="30" height="30" class="m-2" name="check" />
            <BaseIcon width="30" height="30" class="m-2" name="chevron-down" />
            <BaseIcon width="30" height="30" class="m-2" name="chevron-left" />
            <BaseIcon width="30" height="30" class="m-2" name="chevron-right" />
            <BaseIcon width="30" height="30" class="m-2" name="chevron-up" />
            <BaseIcon width="30" height="30" class="m-2" name="clock" />
            <BaseIcon width="30" height="30" class="m-2" name="three-columns" />
            <BaseIcon width="30" height="30" class="m-2" name="copy" />
            <BaseIcon width="30" height="30" class="m-2" name="dots" />
            <BaseIcon width="30" height="30" class="m-2" name="edit" />
            <BaseIcon width="30" height="30" class="m-2" name="file" />
            <BaseIcon width="30" height="30" class="m-2" name="help-circle" />
            <BaseIcon width="30" height="30" class="m-2" name="info-circle" />
            <BaseIcon width="30" height="30" class="m-2" name="layers-three" />
            <BaseIcon width="30" height="30" class="m-2" name="log-out" />
            <BaseIcon width="30" height="30" class="m-2" name="menu-alt" />
            <BaseIcon width="30" height="30" class="m-2" name="plus" />
            <BaseIcon width="30" height="30" class="m-2" name="refresh-ccw" />
            <BaseIcon width="30" height="30" class="m-2" name="search-md" />
            <BaseIcon width="30" height="30" class="m-2" name="send" />
            <BaseIcon width="30" height="30" class="m-2" name="skeleton" />
            <BaseIcon width="30" height="30" class="m-2" name="trash" />
            <BaseIcon width="30" height="30" class="m-2" name="upload" />
            <BaseIcon width="30" height="30" class="m-2" name="user" />
            <BaseIcon width="30" height="30" class="m-2" name="users" />
            <BaseIcon width="30" height="30" class="m-2" name="x-close" />
          </div>
          <hr />
          <br />
          <h1>Checkboxes</h1>
          <div class="mt-5 mb-5">
            <b-form-group>
              <b-form-checkbox id="checkbox" v-model="checkbox" class="Checkbox">
                Checkbox
              </b-form-checkbox>
              <b-form-checkbox id="checkbox1" disabled checked="true" class="Checkbox">
                Checkbox
              </b-form-checkbox>
              <b-form-checkbox
                id="checkboxin"
                v-model="checkboxInvalid"
                :state="false"
                class="Checkbox Checkbox--Error"
              >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci consectetur
              </b-form-checkbox>
            </b-form-group>
            <br />
            <b-form-checkbox-group
              v-model="checkboxGroup"
              class="Checkbox Checkbox--Reverse"
              :options="[
                { id: 1, name: 'Name 1' },
                { id: 2, name: 'Name 2' },
                {
                  id: 3,
                  name: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci consectetur',
                },
                { id: 4, name: 'Name 4', disabled: true },
                { id: 5, name: 'Name 5' },
              ]"
              value-field="id"
              text-field="name"
            />
          </div>
          <hr />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'UiKit',
  components: { BaseIcon },
  data: () => ({
    isDark: false,
    isDuxgroup: false,
    checkbox: false,
    checkboxInvalid: false,
    checkboxGroup: [4],
  }),
  watch: {
    isDark(v) {
      this.$changeTheme(v ? 'Dark' : 'White');
    },
    isDuxgroup(v) {
      this.$changeBrand(v ? 'Duxgroup' : 'Marfatech');
    },
  },
};
</script>