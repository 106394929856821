<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'LayoutLegal',
  created() {
    this.$changeBrand('');
    this.setBrand('');
  },
  methods: {
    ...mapMutations('Settings', ['setBrand']),
  },
};
</script>