<template>
  <div class="QuestionaryJournal">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.journal.title') }}</template>
      <template #subtitle>{{ subTitle }}</template>
      <template #actions>
        <BaseDropdown>
          <template #toggle>
            <span class="Button-Filter Button Button--transparent Button--clean Button--width-auto">
              {{ $t('filter') }} <BaseIcon class="ms-2" name="filter-lines" />
            </span>
          </template>
          <AppFilter @filter="onFilter" />
        </BaseDropdown>
      </template>
    </AppPageHead>
    <BaseTabs v-if="questionariesGroups">
      <template #labels>
        <div
          v-for="(_, key) in questionariesGroups"
          :key="key"
          :class="['Label', { active: currentGroup === key }]"
          @click="currentGroup = key"
        >
          {{ statuses[key] }}
        </div>
      </template>
      <b-overlay
        spinner-type="grow"
        spinner-small
        rounded="sm"
        variant="white"
        :show="isQuestionariesListLoading"
        class="AppOverlay"
      >
        <div class="QuestionaryJournal-Groups">
          <div
            v-for="(column, key) in questionariesGroups"
            :key="key"
            class="QuestionaryJournal-Group"
            :class="{ active: currentGroup === key }"
          >
            <div class="QuestionaryJournal-Group-Head">
              <div class="Name">{{ statuses[key] }}</div>
              <span
                v-if="key === 'created' && $can(['questionary_questionary_create'])"
                class="Button-Main Button"
                @click="onCreateQuestionary"
              >
                <BaseIcon class="me-xl-2" height="16" width="16" name="plus" color="white" />
                <span class="name">{{ $t('base-actions.create') }}</span>
              </span>
            </div>
            <div class="QuestionaryJournal-Group-Cards">
              <QuestionaryCard
                v-for="card in column"
                :key="card.id"
                :card="card"
                @click.native="onShowQuestionarySelected(card)"
              />
            </div>
          </div>
        </div>
      </b-overlay>
    </BaseTabs>
    <span
      v-if="$can(['questionary_questionary_create'])"
      class="Button-Create Button-Main Button"
      @click="onCreateQuestionary"
    >
      <BaseIcon height="24" width="24" name="plus" />
    </span>
    <BaseSidebar v-model="isOpenSideBarOfCreateQuestionary">
      <template #title>{{ $t('admin.pages.journal.create') }}</template>
      <AdminQuestionaryCreate @close="isOpenSideBarOfCreateQuestionary = false" />
    </BaseSidebar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseTabs from '@/components/Base/BaseTabs';
import BaseDropdown from '@/components/Base/BaseDropdown';
import BaseSidebar from '@/components/Base/BaseSidebar';

import AppPageHead from '@/components/App/AppPageHead';
import AppFilter from '@/components/App/AppFilter';

import QuestionaryCard from '@/views/Estimation/Questionary/_components/QuestionaryCard/QuestionaryCard';
import AdminQuestionaryCreate from '@/views/Estimation/Questionary/Create/AdminQuestionaryCreate';

import Declension from '@/services/Helpers/HelperDeclension';

export default {
  name: 'AdminQuestionaryJournal',
  components: {
    BaseTabs,
    BaseIcon,
    BaseDropdown,
    BaseSidebar,
    AppPageHead,
    AppFilter,
    QuestionaryCard,
    AdminQuestionaryCreate,
  },
  data: () => ({
    currentGroup: 'created',
    questionariesList: [],
    isQuestionariesListLoading: false,
    filter: {},
    isOpenSideBarOfCreateQuestionary: false,
  }),
  computed: {
    ...mapState('Journal', ['questionaries']),
    ...mapGetters({
      statuses: 'Directories/questionaryStatusCollection',
    }),
    subTitle() {
      return `
        ${this.questionariesList.length}
        ${Declension(this.questionariesList.length, [
          this.$t('labels.questionaries[0]'),
          this.$t('labels.questionaries[1]'),
          this.$t('labels.questionaries[2]'),
        ])}
        ${this.$i18n.tc('labels.in-journal')}
      `;
    },
    questionariesGroups() {
      return (this.questionariesList || []).reduce(
        (acc, i) => {
          if (acc[i.status] && this.statuses[i.status]) {
            acc[i.status].push(i);
          }
          return acc;
        },
        { created: [], inwork: [], done: [] },
      );
    },
  },
  watch: {
    questionaries() {
      this.getQuestionaryLocalList();
    },
  },
  mounted() {
    this.getQuestionaryLocalList();
  },
  methods: {
    ...mapMutations('Journal', [
      'setSelectedQuestionary',
      'setSelectedQuestionaryState',
      'setSelectedQuestionaryTitle',
    ]),
    ...mapActions('Journal', ['filterQuestionaryList']),
    onShowQuestionarySelected(data) {
      this.setSelectedQuestionary(data);
      this.setSelectedQuestionaryTitle(this.$t('admin.pages.journal.show'));
      this.setSelectedQuestionaryState(true);
    },
    onCreateQuestionary() {
      this.isOpenSideBarOfCreateQuestionary = true;
    },
    setStateQuestionariesLoading(state = true) {
      this.isQuestionariesListLoading = state;
    },
    getQuestionaryLocalList() {
      this.setStateQuestionariesLoading();
      this.filterQuestionaryList(this.filter).then((response) => {
        this.questionariesList = response;
        this.setStateQuestionariesLoading(false);

        if (this.$route.params.id !== undefined) {
          const card =
            this.questionariesList.find((item) => {
              return item.id === this.$route.params.id;
            }) || null;

          if (card) {
            this.onShowQuestionarySelected(card);
          } else {
            this.$router.push({ name: 'home' });
          }
        }
      });
    },
    onFilter(filter) {
      this.filter = filter;
      this.getQuestionaryLocalList();
    },
  },
};
</script>
