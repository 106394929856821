<template>
  <div class="QuestionaryTypePosition">
    <BaseSelect
      v-model="select"
      class="Select--WidthBorder"
      option-value="name"
      option-key="id"
      :items="positions"
      :placeholder="$t('select.no-select')"
      clearable
      searchable
      @input="$emit('select-position', select)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';

export default {
  name: 'QuestionaryTypePosition',
  components: { BaseSelect },
  props: {
    selectOption: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      select: this.selectOption || '',
    };
  },
  computed: {
    ...mapState('Dictionary', ['positions']),
  },
};
</script>