<template>
  <div class="Tab Tab-Reports">
    <BaseProfileBlock>
      <template #head>
        <div class="Title">
          {{ $t('profile.reports') }}
        </div>
      </template>
      <BaseTable :count="count" :items="items" :headers="headers" :limit="10" @get-data="onGetData">
        <template #body-col-number="{ item }"> №{{ item.number }} </template>
        <template #body-col-dateStart="{ item }">
          {{ item.dateStart | unixToDate }}
        </template>
        <template #body-col-dateEnd="{ item }">
          {{ item.dateEnd | unixToDate }}
        </template>
        <template #body-col-method="{ item }">
          <QuestionaryMethod :method="item.method" />
        </template>
        <template #body-col-action="{ item }">
          <span class="Button-InTables Button" @click="onExportReport(item)">
            <BaseIcon width="16" height="16" name="arrow-circle-broken-down" class="me-2" />
            <span> {{ $t('labels.download-pdf') }} </span>
          </span>
        </template>
      </BaseTable>
    </BaseProfileBlock>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseTable from '@/components/Base/BaseTable';
import BaseProfileBlock from '@/components/Base/BaseProfileBlock';

// может перетащить этот компонент в App или Base?
import QuestionaryMethod from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryMethod';

import { exportReport } from '@/mixins/DownloadFiles';
import { unixToDate } from '@/services/Filters';

import { POFILE_REPORTS_HEADERS } from '@/components/Modules/Profile/Tabs/config';

export default {
  name: 'TabReports',
  components: {
    BaseIcon,
    BaseProfileBlock,
    BaseTable,
    QuestionaryMethod,
  },
  filters: { unixToDate },
  mixins: [exportReport],
  data: () => ({
    items: [],
    count: 0,
  }),
  computed: {
    ...mapState('Users', ['employeeProfileId']),
    headers() {
      return POFILE_REPORTS_HEADERS.map((header) => {
        const { key } = header;
        return this.$te(`tables.user-reports.${key}`)
          ? { ...header, name: this.$t(`tables.user-reports.${key}`) }
          : header;
      });
    },
  },
  methods: {
    ...mapActions('Users', ['getEmployeeReportsProfile']),
    ...mapActions({
      exportHandler: 'Reports/exportReportByQuestionaryIdAndEmployeeID',
    }),
    onExportReport(item) {
      // Метод дергает exportHandler
      // TODO: отсутствует поле - subject (имя/фамилияю сотрудника). Нужно будет запросить у бека.
      this.exportReport(item);
    },
    onGetData(params) {
      this.getEmployeeReportsProfile({ id: this.employeeProfileId, params }).then(
        ({ data, count }) => {
          this.items = data;
          this.count = count;
        },
      );
    },
  },
};
</script>
