import Legal from '@/views/Legal/Main';

import PublicOfferAgreement from '@/views/Legal/Categories/PublicOfferAgreement';
import PrivacyPolicy from '@/views/Legal/Categories/PrivacyPolicy';
import OfficialDocuments from '@/views/Legal/Categories/OfficialDocuments';
import AboutSecurity from '@/views/Legal/Categories/AboutSecurity';
import UserAgreement from '@/views/Legal/Categories/UserAgreement';

export default [
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    redirect: { name: 'legal:terms' },
    children: [
      {
        path: 'terms',
        name: 'legal:terms',
        component: PublicOfferAgreement,
      },
      {
        path: 'privacy',
        name: 'legal:privacy',
        component: PrivacyPolicy,
      },
      {
        path: 'documents',
        name: 'legal:documents',
        component: OfficialDocuments,
      },
      {
        path: 'security',
        name: 'legal:security',
        component: AboutSecurity,
      },
      {
        path: 'offer',
        name: 'legal:offer',
        component: UserAgreement,
      },
      {
        path: '*',
        redirect: { name: 'home' },
      },
    ],
  },
];
