var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Navigation-Links"},[_vm._l((_vm.navigationLinks),function(item,index){return [(_vm.hideDirectoryPage(item))?_c('div',{key:("Navigation-Row-" + index),staticClass:"Navigation-Row"},[(item.route && !item.children)?_c('router-link',{staticClass:"Navigation-Link",class:{
          'Navigation-Link--disable': item.disable,
          'Navigation-Link--active': _vm.checkActiveParentRoute(item),
        },attrs:{"to":{ name: item.route },"active-class":"Navigation-Link--active","exact":true,"append":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onClickNavLink(item)}}},[_c('span',{staticClass:"Navigation-Link-In"},[_c('span',{staticClass:"Name"},[(item.icon)?_c('BaseIcon',{attrs:{"name":item.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))])],1)])]):_c('div',{staticClass:"Navigation-Link",class:{
          'Navigation-Link--disable': item.disable,
          'Navigation-Parent': item.children,
          'Navigation-Link--active': _vm.checkActiveParentRoute(item),
        }},[_c('div',{staticClass:"Navigation-Link-In",on:{"click":function($event){$event.preventDefault();return _vm.onClickNavLink(item)}}},[_c('span',{staticClass:"Name"},[(item.icon)?_c('BaseIcon',{attrs:{"name":item.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))])],1),(item.children)?[_c('BaseIcon',{attrs:{"name":("chevron-" + (!_vm.toggleChildren ? 'down' : 'up'))}})]:_vm._e()],2),(item.children)?[_c('AppNavigationLinks',{key:("AppNavigationLinks-" + (item.route)),class:['Navigation-Children', { isOpen: _vm.toggleChildren }],attrs:{"items":item.children}})]:_vm._e()],2)],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }