<template>
  <div class="BaseProgressBar">
    <div v-if="label" class="BaseProgressBar-Label mb-1">{{ label }}</div>
    <div class="BaseProgressBar-Bar">
      <div class="BaseProgressBar-Current" :style="{ width: percentComplete + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseProgressBar',
  props: {
    label: {
      type: String,
      default: '',
    },
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    percentComplete() {
      return Math.ceil((this.current / this.total) * 100);
    },
  },
};
</script>