<template>
  <div class="AppNotification">
    <div class="AppNotification-Wrapper">
      <div class="AppNotification-Icon">
        <template v-if="icon">
          <BaseIcon :height="icon.heightIcon" :width="icon.widthIcon" :name="icon.nameIcon" />
        </template>
      </div>
      <div class="AppNotification-Title">
        {{ title }}
      </div>
      <div class="AppNotification-Message">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'AppNotification',
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>