<template>
  <div id="app">
    <AppLoader v-if="isLoading" />
    <component :is="layout" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

import LayoutLogin from '@/layouts/Admin/Login';
import LayoutAdmin from '@/layouts/Admin/Admin';
import LayoutRestorePassword from '@/layouts/Admin/RestorePassword';
import LayoutLegal from '@/layouts/Legal/Legal';
import LayoutUiKit from '@/layouts/UiKit/UiKit';

import AppLoader from '@/components/App/AppLoader';

export default {
  name: 'App',
  components: {
    LayoutLogin,
    LayoutAdmin,
    LayoutLegal,
    LayoutUiKit,
    LayoutRestorePassword,
    AppLoader,
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.Admin.token,
      isLoading: (state) => state.Loading.isLoading,
    }),
    currentRouteName() {
      return this.$route?.name;
    },
    currentParentRouteName() {
      return this.$route.matched?.[0]?.name || null;
    },
    isUiKitPage() {
      return this.currentParentRouteName === 'ui-kit';
    },
    isLegalPage() {
      return this.currentParentRouteName === 'legal';
    },
    isRestorePasswordPage() {
      return this.currentParentRouteName === 'restore-password';
    },
    isLoginPage() {
      return !this.isAdmin;
    },
    layout() {
      switch (true) {
        case this.isLegalPage:
          return 'LayoutLegal';
        case this.isRestorePasswordPage:
          return 'LayoutRestorePassword';
        case this.isLoginPage:
          return 'LayoutLogin';
        case this.isUiKitPage:
          return 'LayoutUiKit';
        default:
          return 'LayoutAdmin';
      }
    },
  },
  mounted() {
    this.checkDevice();
    document.body.classList.add('ScrollBar--hide');
  },
  methods: {
    ...mapActions('Settings', ['checkDevice']),
  },
};
</script>