<template>
  <div class="AdminSettings">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.settings.title') }}</template>
    </AppPageHead>
    <div class="AdminSettings-Wrap">
      <div class="AdminSettings-Content">
        <h5>
          {{ $t('admin.pages.settings.passwordChange') }}
        </h5>
        <AdminSettingRestorePassword />
      </div>
    </div>
  </div>
</template>

<script>
import AppPageHead from '@/components/App/AppPageHead';
import AdminSettingRestorePassword from '@/views/Estimation/Settings/_components/AdminSettingRestorePassword';

export default {
  name: 'Settings',
  components: {
    AdminSettingRestorePassword,
    AppPageHead,
  },
};
</script>
