<template>
  <div class="ProfileInfo ProfileInfo-Form">
    <ProfileUserPhoto
      :src="userPhotoUrl"
      :state="validateState('photoUrl')"
      edit
      class="ProfileInfo-Photo"
      @update-photo="$v.form.photoUrl.$model = $event"
    />
    <InfoRow class="ProfileInfo-Name">
      <template #label>
        {{ $t('labels.firstName') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.firstName.$model"
          class="Input"
          :class="{ 'Input--error': !validateState('firstName') }"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-LastName">
      <template #label>
        {{ $t('labels.lastName') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.lastName.$model"
          :class="{ 'Input--error': !validateState('lastName') }"
          class="Input"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-MiddleName">
      <template #label>
        {{ $t('labels.middleName') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.middleName.$model"
          :class="{ 'Input--error': !validateState('middleName') }"
          class="Input"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-City">
      <template #label>
        {{ $t('labels.city') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.location.$model"
          :class="{ 'Input--error': !validateState('location') }"
          class="Input"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-WorkTime">
      <template #label>
        {{ $t('labels.workTime') }}
      </template>
      <template #default>
        <div class="WorkTimeSelects">
          <div class="WorkTimeSelects-Block WorkTimeSelects-Days">
            <BaseSelect
              v-model="$v.form.startWorkDayAt.$model"
              :items="workDays"
              option-key="key"
              option-value="key"
              :prefix="$t('placeholders.from-day')"
              :state="validateState('startWorkDayAt')"
              clearable
            >
              <template #selected="{ item }">
                {{ item.shortName }}
              </template>
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </BaseSelect>
            <BaseSelect
              v-model="$v.form.endWorkDayAt.$model"
              :items="workDays"
              option-key="key"
              option-value="key"
              :prefix="$t('placeholders.to-day')"
              :state="validateState('endWorkDayAt')"
              clearable
            >
              <template #selected="{ item }">
                {{ item.shortName }}
              </template>
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </BaseSelect>
          </div>
          <div class="WorkTimeSelects-Block WorkTimeSelects-Time">
            <BaseSelect
              v-model="$v.form.startWorkTimeAt.$model"
              :items="workHours"
              option-key="unixValue"
              option-value="unixValue"
              :prefix="$t('placeholders.from-hour')"
              :state="validateState('startWorkTimeAt')"
              clearable
            >
              <template #selected="{ item }">{{ item.value }}</template>
              <template #item="{ item }">{{ item.value }}</template>
            </BaseSelect>
            <BaseSelect
              v-model="$v.form.endWorkTimeAt.$model"
              :items="workHours"
              option-key="unixValue"
              option-value="unixValue"
              :prefix="$t('placeholders.to-hour')"
              :state="validateState('endWorkTimeAt')"
              clearable
            >
              <template #selected="{ item }">{{ item.value }}</template>
              <template #item="{ item }">{{ item.value }}</template>
            </BaseSelect>
          </div>
        </div>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-Position">
      <template #label>
        {{ $t('labels.position') }}
      </template>
      <template #default>
        <BaseSelect
          v-model="$v.form.positionId.$model"
          :items="positions"
          option-key="id"
          option-value="id"
          searchable
          :search-fields="['name']"
          :state="validateState('positionId')"
          clearable
        >
          <template #selected="{ item }">{{ item.name }}</template>
          <template #item="{ item }">{{ item.name }}</template>
        </BaseSelect>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-Email">
      <template #label>
        {{ $t('labels.email') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.email.$model"
          class="Input"
          :class="{ 'Input--error': !validateState('email') }"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="ProfileInfo-Phone">
      <template #label>
        {{ $t('labels.phone') }}
      </template>
      <template #default>
        <b-form-input
          v-model="$v.form.phone.$model"
          v-mask="'+##############'"
          class="Input"
          :class="{ 'Input--error': !validateState('phone') }"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <div class="GeneralTabInfo-Actions">
      <BaseActions :actions="formActions" @cancel="$emit('close')" @save="onSaveChanges" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';
import BaseActions from '@/components/Base/BaseActions';

import InfoRow from '@/components/Modules/Profile/_components/InfoRow';
import ProfileUserPhoto from '@/components/Modules/Profile/_components/ProfileUserPhoto';

import { ObjectFunctions } from '@/services/Helpers';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';

import { EmployeeWorkDays, EmployeeWorkHours } from '@/constants/EmployeeWorkMode';

export default {
  name: 'ProfileInfoForm',
  components: { BaseSelect, BaseActions, InfoRow, ProfileUserPhoto },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      startWorkTimeAt: '',
      endWorkTimeAt: '',
      startWorkDayAt: '',
      endWorkDayAt: '',
      positionId: '',
    },
    positions: [],
    employeeWorkDays: EmployeeWorkDays,
    employeeWorkHours: EmployeeWorkHours,
  }),
  computed: {
    userPhotoUrl() {
      return this.user?.photoUrl || '';
    },
    formActions() {
      return [
        { action: 'cancel' },
        {
          action: 'save',
          class: 'Button-Blue Button',
          icon: { name: 'check-ok', class: 'me-1', left: true },
          disabled: this.$v.form.$anyError,
        },
      ];
    },
    workDays() {
      return (this.employeeWorkDays || []).map((day) => {
        return {
          ...day,
          name: this.$te(`profile.workDays.${day.key}`)
            ? this.$t(`profile.workDays.${day.key}`)
            : '',
          shortName: this.$te(`profile.workDaysShort.${day.key}`)
            ? this.$t(`profile.workDaysShort.${day.key}`)
            : '',
        };
      });
    },
    workHours() {
      return this.employeeWorkHours || [];
    },
  },
  validations: {
    form: {
      photoUrl: {
        maxLength: maxLength(255),
      },
      lastName: {
        required,
      },
      firstName: {
        required,
      },
      middleName: {},
      location: {},
      startWorkDayAt: {
        required,
      },
      endWorkDayAt: {
        required,
      },
      startWorkTimeAt: {
        required,
      },
      endWorkTimeAt: {
        required,
      },
      positionId: {
        required,
      },
      email: {
        email,
        required,
      },
      phone: { required, minLength: minLength(11), maxLength: maxLength(15) },
    },
  },
  mounted() {
    this.getStructurePositionsList().then(({ data }) => {
      this.positions = data;
    });
    this.form = ObjectFunctions.deepClone(this.user);
    this.$v.$touch();
  },
  methods: {
    ...mapActions({
      getStructurePositionsList: 'Structure/getStructurePositionsList',
    }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSaveChanges() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('save', this.form);
      }
    },
  },
};
</script>