export const DisabledFields = {
  created: [],
  inwork: [
    'isAnonymous',
    'method',
    'dateStart',
    'type',
    'employeeList',
    'competenceList',
    'periodType',
  ],
  done: [],
  archived: [],
};
