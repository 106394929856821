<template>
  <div class="Header">
    <div class="Header-Logo">
      <div class="Header-Menu" @click="toggleMenu(true)">
        <BaseIcon name="menu-alt" />
      </div>
      <router-link :to="{ name: 'home' }">
        <AppLogo name="white" />
      </router-link>
    </div>
    <div class="Header-Content">
      <div class="Header-Search">
        <AppSearch />
      </div>
      <div class="Header-Actions">
        <AppThemeSwitcher />
        <AppLangSwitcher />
        <AppNotifications v-if="$can(['notification_notification_list'])" />
        <AppHeaderProfile />
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/Base/BaseIcon';
import AppLogo from '@/components/App/AppLogo';
import AppSearch from '@/components/App/AppSearch';
import AppNotifications from '@/components/App/AppNotifications';
import AppLangSwitcher from '@/components/App/AppLangSwitcher';
import AppThemeSwitcher from '@/components/App/AppThemeSwitcher';
import AppHeaderProfile from '@/components/App/AppHeaderProfile';

import { mapActions } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    BaseIcon,
    AppLogo,
    AppSearch,
    AppNotifications,
    AppLangSwitcher,
    AppThemeSwitcher,
    AppHeaderProfile,
  },
  methods: {
    ...mapActions({
      toggleMenu: 'Settings/toggleMenu',
    }),
  },
};
</script>