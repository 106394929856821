<template>
  <div>
    <AppDefaultLayoutHeader />
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppDefaultLayoutHeader from '@/components/App/AppDefaultLayoutHeader';
export default {
  name: 'LayoutRestorePassword',
  components: {
    AppDefaultLayoutHeader,
  },
  created() {
    this.$changeBrand('');
    this.setBrand('');
  },
  methods: {
    ...mapMutations('Settings', ['setBrand']),
  },
};
</script>