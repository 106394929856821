<template>
  <div class="BasePagination">
    <div class="BasePagination-Items">
      <span
        class="BasePagination-Item BasePagination-Item-Prev"
        :class="{ 'BasePagination-Item-Disable': page === 1 }"
        @click="onSetPrevPage(page - 1)"
      >
        <BaseIcon name="chevron-left" />
      </span>
      <span
        v-for="(num, idx) in showedItems"
        :key="`p-item-${idx}`"
        class="BasePagination-Item"
        :class="{
          'BasePagination-Item-Active': num === page,
        }"
        @click="onSetPage(num)"
      >
        {{ num }}
      </span>
      <span
        class="BasePagination-Item BasePagination-Item-Next"
        :class="{ 'BasePagination-Item-Disable': page === items.length }"
        @click="onSetNextPage(page + 1)"
      >
        <BaseIcon name="chevron-right" />
      </span>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'BasePagination',
  components: { BaseIcon },
  props: {
    value: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    visiblePages: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  data: () => ({
    page: null,
  }),
  computed: {
    items() {
      return Array.from({ length: Math.ceil(this.count / this.limit) }, (_, i) => i + 1);
    },
    showedItems() {
      if (this.visiblePages >= this.items.length) return this.items;

      const centerPos = Math.ceil(this.visiblePages / 2);
      let startPos = 0;
      let endPos = this.visiblePages;

      if (this.page > centerPos) {
        startPos = this.page - centerPos;
      }

      endPos = startPos + endPos;

      if (endPos > this.items.length) {
        startPos += this.items.length - endPos;
      }

      return this.items.slice(startPos, endPos);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.page = v;
      },
    },
  },
  methods: {
    onSetPage(v) {
      if (this.page !== v) {
        this.$emit('input', v);
      }
    },
    onSetPrevPage(v) {
      if (v >= 1) {
        this.$emit('input', v);
      }
    },
    onSetNextPage(v) {
      if (v <= this.items.length) {
        this.$emit('input', v);
      }
    },
  },
};
</script>