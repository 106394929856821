import { Auth } from '@/services/General/Auth';
import { API_HOST } from '@/config';
import axios from 'axios';

import store from '@/store';
import router from '@/router';

import i18n from '@/i18n';

const axiosHandlersRequest = () => [
  (config) => {
    return config;
  },
  (error) =>
    new Promise(() => {
      throw error;
    }),
];

const axiosHandlersResponse = () => [
  (response) => {
    if (
      response.headers['content-type'] === 'application/pdf' ||
      response.headers['content-type'] === 'text/csv; charset=utf-8'
    ) {
      return response;
    }

    // Default table response
    if (response.headers['x-meta-count']) {
      return {
        data: response?.data?.data || null,
        message: response?.data?.message || null,
        count: Number(response.headers['x-meta-count']),
      };
    }

    if (response.status === 205) {
      return response;
    }

    if (response.data.code === 0) return response?.data || null;
    return Promise.reject(response?.data?.message || '');
  },
  (error) =>
    new Promise(() => {
      if (error.response === undefined) {
        router.push({
          name: 'home',
          params: { redirect: router.currentRoute.path },
        });
      }
      switch (error.response.status) {
        case 401: {
          store.dispatch('Admin/setAuthToken', '');
          break;
        }
        case 403: {
          router.push({
            name: 'home',
            params: { redirect: router.currentRoute.path },
          });
          break;
        }
        default: {
          break;
        }
      }
      throw error.response?.data?.message || i18n.tc('errors.general.response');
    }),
];

const http = axios.create({
  baseURL: API_HOST,
});

http.interceptors.request.use(...axiosHandlersRequest());
http.interceptors.response.use(...axiosHandlersResponse());

http.setAuthToken = (token = false) => {
  if (token) http.defaults.headers.common['X-Auth-Token'] = token;
  else {
    delete http.defaults.headers.common['X-Auth-Token'];
  }
};

export { http };
