<template>
  <div class="GeneralTabInfo GeneralTabInfo-Form">
    <InfoRow class="GeneralTabInfo-Subdivision">
      <template #label>
        {{ $t('profile.subdivision') }}
      </template>
      <template #default>
        <BaseSelect
          v-model="$v.form.subdivisionId.$model"
          :items="structureSubdivisions"
          option-key="id"
          option-value="name"
        />
      </template>
    </InfoRow>
    <InfoRow v-if="employeeList.length" class="GeneralTabInfo-Leaders">
      <template #label>
        {{ $t('profile.leaders') }}
      </template>
      <template #default>
        <BaseSelect
          v-model="$v.form.leaderList.$model"
          :items="employeeList"
          option-key="employeeId"
          option-value="firstName"
          multiple
          searchable
        >
          <template #selected="{ item }"> {{ `${item.lastName} ${item.firstName}` }}</template>
          <template #item="{ item }">
            <span class="CustomCheckBox" :class="{ 'CustomCheckBox-Active': item.active }">
              <span class="CustomCheckBox-Image">
                <img v-if="item.photo" :src="item.photo" alt="" />
              </span>
              <span class="CustomCheckBox-Value">
                {{ `${item.lastName} ${item.firstName}` }}
              </span>
            </span>
          </template>
        </BaseSelect>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-InCompany">
      <template #label>
        {{ $t('profile.inCompany') }}
      </template>
      <template #default>
        <BaseDatepicker id="date-picker-inCompany" v-model="$v.form.inCompany.$model" unix />
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-InPosition">
      <template #label>
        {{ $t('profile.inPosition') }}
      </template>
      <template #default>
        <BaseDatepicker id="date-picker-inCompany" v-model="$v.form.inPosition.$model" unix />
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-DateOfBirth">
      <template #label>
        {{ $t('profile.dateOfBirth') }}
      </template>
      <template #default>
        <BaseDatepicker id="date-picker-inCompany" v-model="$v.form.birthDate.$model" unix />
        <b-form-checkbox v-model="$v.form.isBirthDateHide.$model" class="Checkbox">
          {{ $t('labels.dontShowBirthDay') }}
        </b-form-checkbox>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-SlackId">
      <template #label>
        <img
          class="Messenger"
          :src="require(`@/assets/Images/Messenger/slack.svg`)"
          alt="slack-icon"
        />
        {{ $t('profile.slackId') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.slackId.$model"
          :class="{ 'Input--error': !validateState('slackId') }"
          class="Input"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-Telegram">
      <template #label>
        <img
          class="Messenger"
          :src="require(`@/assets/Images/Messenger/telegram.svg`)"
          alt="telegram-icon"
        />
        {{ $t('profile.telegram') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="$v.form.telegram.$model"
          :class="{ 'Input--error': !validateState('telegram') }"
          class="Input"
          type="text"
          autocomplete="off"
        ></b-form-input>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-Language">
      <template #label>
        {{ $t('profile.language') }}
      </template>
      <template #default>
        <BaseSelect
          v-model="$v.form.locale.$model"
          :items="languages"
          option-key="code"
          option-value="title"
        />
      </template>
    </InfoRow>
    <div class="GeneralTabInfo-Actions">
      <BaseActions :actions="formActions" @cancel="$emit('close')" @save="onSaveChanges" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';
import BaseDatepicker from '@/components/Base/BaseDatepicker';
import BaseActions from '@/components/Base/BaseActions';

import InfoRow from '@/components/Modules/Profile/_components/InfoRow';

import { required } from 'vuelidate/lib/validators';

import { ObjectFunctions, vmoment } from '@/services/Helpers';

import { languages } from '@/constants/SiteLanguages';

const defaultPhoto = require('@/assets/Images/profile.png');

export default {
  name: 'GeneralTabInfoForm',
  components: { BaseSelect, BaseDatepicker, BaseActions, InfoRow },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    employeeList: [],
    languages,
    form: {
      subdivisionId: '',
      locale: '',
    },
  }),
  computed: {
    ...mapState('Structure', ['structureSubdivisions']),
    formActions() {
      return [
        { action: 'cancel' },
        {
          action: 'save',
          class: 'Button-Blue Button',
          icon: { name: 'check-ok', class: 'me-1', left: true },
          disabled: this.$v.form.$anyError,
        },
      ];
    },
  },
  validations: {
    form: {
      subdivisionId: {},
      leaderList: {},
      inCompany: {
        required,
      },
      inPosition: {
        required,
      },
      birthDate: {},
      isBirthDateHide: {
        required,
      },
      slackId: {},
      telegram: {},
      locale: {},
    },
  },
  mounted() {
    this.$v.$touch();
    this.form = ObjectFunctions.deepClone(this.user);
    this.form.inCompany = vmoment.unix(this.form.inCompany).format('YYYY-MM-DD');
    this.form.inPosition = vmoment.unix(this.form.inPosition).format('YYYY-MM-DD');
    this.form.birthDate = vmoment.unix(this.form.birthDate).format('YYYY-MM-DD');
    this.getStructureSubdivisionsList();
    this.getStructureEmployees().then(({ data }) => {
      this.employeeList = data.map((item) => ({
        ...item,
        photo: item?.photoUrl || defaultPhoto,
      }));
    });
  },
  methods: {
    ...mapActions({
      getStructureSubdivisionsList: 'Structure/getStructureSubdivisionsList',
      getStructureEmployees: 'Structure/getStructureEmployees',
    }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSaveChanges() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('save', this.form);
      }
    },
  },
};
</script>