export const ENDPOINTS_DICTIONARY = {
  COMPETENCIES_GET_LIST: '/competence/list',
  COMPETENCIES_ITEM_CREATE: '/competence/create',
  COMPETENCIES_ITEM_DELETE: '/competence/{id}',
  COMPETENCIES_ITEM_UPDATE: '/competence/update',
  POSITIONS_GET_LIST: '/position/list',
  POSITIONS_ITEM_CREATE: '/position/create',
  POSITIONS_ITEM_DELETE: '/position/{id}',
  POSITIONS_ITEM_UPDATE: '/position/update',
  SUBDIVISION_GET_LIST: '/subdivision/list',
  SUBDIVISION_ITEM_CREATE: '/subdivision/create',
  SUBDIVISION_ITEM_DELETE: '/subdivision/{id}',
  SUBDIVISION_ITEM_UPDATE: '/subdivision/update',
  EMPLOYEES_GET_LIST: '/employee/list',
  EMPLOYEES_ITEM_CREATE: '/employee/create',
  EMPLOYEES_ITEM_DELETE: '/employee/{id}',
  EMPLOYEES_ITEM_UPDATE: '/employee/update',
  EMPLOYEES_IMPORT: '/employee/import',
  EMPLOYEES_IMPORT_EXAMPLE: '/employee/importExample',
  STRUCTURE_SUBDIVISIONS_GET_LIST: '/dictionary/subdivision',
  STRUCTURE_POSITIONS_GET_LIST: '/dictionary/position',
};
