<template>
  <div class="QuestionaryProgress">
    <div class="QuestionaryProgress-Info">
      <div class="Left">
        <div class="QuestionaryProgress-Label">
          <slot v-if="$scopedSlots.label" name="selected" />
          <template v-else> {{ passed }}/{{ total }} </template>
        </div>
      </div>
      <div class="Right">
        <div class="QuestionaryProgress-Percent">{{ percentComplete }}%</div>
      </div>
    </div>
    <div class="QuestionaryProgress-Progress">
      <div class="QuestionaryProgress-Progress-Bar" :style="{ width: percentComplete + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionaryProgress',
  props: {
    total: {
      type: [String, Number],
      required: true,
    },
    passed: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    percentComplete() {
      return Math.round((this.passed * 100) / this.total);
    },
  },
};
</script>