<template>
  <div class="LegalAboutSecurity">
    <h4>О безопасности</h4>
  </div>
</template>

<script>
export default {
  name: 'LegalAboutSecurity',
};
</script>