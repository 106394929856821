<template>
  <div v-if="items.length" class="QuestionaryParticipantList">
    <div class="Top">
      <div class="Left">
        {{ $t('admin.pages.journal.questionary.participant') }} "{{ participant.name }}":
      </div>
      <div class="Right">
        <span class="Button" @click="openSelectEmployeeModal">
          <BaseIcon name="plus" class="me-1" />
          {{ $t('base-actions.add') }}
        </span>
      </div>
    </div>
    <div class="Bottom">
      <div class="List">
        <ParticipantCard
          v-for="(item, index) in localList"
          :key="`ParticipantCard-${item.id}`"
          :item="item"
          class="ParticipantCard--widthBg"
        >
          <template v-if="isShowDeleteButton" #actions>
            <span class="Button--reset Button" @click="deleteItem(index)">
              <BaseIcon height="16" width="16" name="x-close" />
            </span>
          </template>
        </ParticipantCard>
      </div>
    </div>
    <QuestionarySelectEmployee
      :id="nameModalOfSelect"
      :key="updateKey"
      :title="`${$t('modals.participants.label')}: ${participant.name}`"
      :checked-options="checkedOptions"
      :disabled-options="disabledOptions"
      :items="localModalList"
      :questionary-status="questionaryStatus"
      @save="saveSelectEmployeeModal"
      @cancel="closeSelectEmployeeModal"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import BaseIcon from '@/components/Base/BaseIcon';
import ParticipantCard from '@/components/General/ParticipantCard';
import QuestionarySelectEmployee from '@/views/Estimation/Questionary/_components/QuestionaryForm/Modal/QuestionarySelectEmployee';

export default {
  name: 'QuestionaryParticipantList',
  components: { BaseIcon, ParticipantCard, QuestionarySelectEmployee },
  props: {
    id: {
      type: String,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    excludeItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    participant: {
      type: Object,
      required: true,
    },
    questionaryStatus: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    localList: [],
    updateKey: 0,
    checkedOptions: [],
  }),
  computed: {
    isInWork() {
      return this.questionaryStatus === 'inwork';
    },
    nameModalOfSelect() {
      return `modal-choose-employees-${this.id}`;
    },
    localModalList() {
      return ObjectFunctions.deepClone(this.items) || [];
    },
    disabledOptions() {
      let ids = this.localList.map(({ id }) => id);

      if (this.isInWork) {
        const selectedIds = this.selectedOptions.map(({ id }) => id);
        ids = ids.filter((id) => !selectedIds.includes(id));
      }

      return this.excludeItems.filter((id) => !ids.includes(id)) || [];
    },
    isShowDeleteButton() {
      return !this.isInWork;
    },
  },
  watch: {
    items() {
      this.localList = ObjectFunctions.deepClone(this.selectedOptions);
    },
    localList(values) {
      this.checkedOptions = values.map(({ id }) => id);
      this.updateKey++;
      this.$emit(
        'update-participant-list',
        {
          items: this.localList.map(({ id }) => id),
          type: this.participant.id,
        } || [],
      );
    },
  },
  methods: {
    deleteItem(index) {
      this.localList.splice(index, 1);
    },
    openSelectEmployeeModal() {
      this.$bvModal.show(this.nameModalOfSelect);
    },
    closeSelectEmployeeModal() {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.updateKey++;
    },
    saveSelectEmployeeModal(values) {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.localList = values;
    },
  },
};
</script>
