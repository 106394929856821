<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--SubdivisionForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.directory.tabs.subdivisions') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="SubdivisionFormData">
        <div class="SubdivisionFormData-Fields">
          <div class="SubdivisionFormData-Field">
            <div class="Label-Input">{{ $t('labels.subdivisions-name') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.name.$model"
                :state="validateState('name')"
                class="Input Input--font-big Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.enter-name')"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @save="sendActionType" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

import { ObjectFunctions } from '@/services/Helpers';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DictionaryFormCreateSubdivisions',
  components: {
    BaseIcon,
    BaseActions,
    BaseModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    itemModel: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: {},
  }),
  computed: {
    modalActions() {
      return [
        { action: 'save', class: 'Button-Main', disabled: this.$v.form.$anyError },
        { action: 'cancel' },
      ];
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  watch: {
    itemModel() {
      this.form = ObjectFunctions.deepClone(this.itemModel);
      this.$v.$touch();
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    sendActionType() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit(this.actionType, this.form);
      }
    },
  },
};
</script>
