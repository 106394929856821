<template>
  <div class="RestorePasswordContent">
    <div
      v-if="icon"
      class="RestorePasswordContent-Bg"
      :class="`RestorePasswordContent-Bg--${classes}`"
    >
      <BaseIcon :name="icon" />
    </div>
    <h1 v-if="title">
      {{ title }}
    </h1>
    <p v-if="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'RestorePasswordContent',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: 'base',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>