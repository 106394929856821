<template>
  <div v-if="showInfoBlock" class="BaseInfoBlock">
    <div class="BaseInfoBlock-Info">
      <div v-if="$scopedSlots.title" class="BaseInfoBlock-Title">
        <slot name="title"></slot>
      </div>
      <div class="BaseInfoBlock-Text">
        <slot name="default"></slot>
      </div>
    </div>
    <div class="BaseInfoBlock-Actions">
      <span class="Button--reset Button BaseInfoBlock-Close" @click="onCloseInfoBlock">
        <BaseIcon name="x-close" />
      </span>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import { getCookie, setCookie } from '@/services/Helpers';

export default {
  name: 'BaseInfoBlock',
  components: { BaseIcon },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showInfoBlock: true,
    prefix: '_infoBlock_',
  }),
  computed: {
    infoBlockId() {
      return `${this.prefix}${this.id}`;
    },
  },
  mounted() {
    this.showInfoBlock = !getCookie(this.infoBlockId);
  },
  methods: {
    onCloseInfoBlock() {
      setCookie(this.infoBlockId, true, { secure: true, 'max-age': 3600 });
      this.showInfoBlock = false;
    },
  },
};
</script>