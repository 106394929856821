<template>
  <div class="AppPageHead">
    <div v-if="$scopedSlots.title || $scopedSlots.subtitle" class="AppPageHead-Info">
      <div v-if="$scopedSlots.title" class="AppPageHead-Title">
        <slot name="title"></slot>
      </div>
      <div v-if="$scopedSlots.subtitle" class="AppPageHead-Subtitle">
        <slot name="subtitle"></slot>
      </div>
    </div>
    <div v-if="$scopedSlots.actions" class="AppPageHead-Actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPageHead',
};
</script>