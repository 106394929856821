<template>
  <b-tooltip
    :target="target"
    :triggers="triggers"
    :placement="isDesktop ? 'right' : 'top'"
    :custom-class="customClass"
  >
    <div
      v-for="(item, i) in items"
      :key="i"
      :class="[`${customClass}-Content`, validationClass(item.errorType, validField)]"
    >
      <BaseIcon
        :name="iconName(item.errorType, validField)"
        fill
        stroke
        :class-name="`${customClass}-Icon`"
      />
      <span :class="`${customClass}-Text`">{{ item.text }}</span>
    </div>
  </b-tooltip>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import { mapGetters } from 'vuex';

export default {
  name: 'AppRestorePasswordTooltip',
  components: {
    BaseIcon,
  },
  props: {
    target: {
      type: String,
      default: '',
    },
    triggers: {
      type: String,
      default: 'focus',
    },
    customClass: {
      type: String,
      default: 'Tooltip',
    },
    items: {
      type: Array,
      default: () => [],
    },
    validField: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('Settings', ['isDesktop']),
  },
  methods: {
    iconName(errorType, validField) {
      return validField.$model.length && !validField[errorType] ? 'x-circle' : 'check-circle';
    },
    validationClass(errorType, validField) {
      if (!validField.$model.length) return false;
      return validField[errorType] ? 'Tooltip-Content--success' : 'Tooltip-Content--error';
    },
  },
};
</script>