<template>
  <div class="Structure">
    <AppPageHead>
      <template #title> {{ $t('admin.pages.structure.title') }} </template>
    </AppPageHead>
    <BaseTabs>
      <template #labels>
        <span
          v-for="({ route, title }, index) in tabs"
          :key="index"
          :class="['Label', { active: route === currentRouteName }]"
          @click="onChangePage(route)"
        >
          {{ title }}
        </span>
      </template>
    </BaseTabs>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseTabs from '@/components/Base/BaseTabs';

import AppPageHead from '@/components/App/AppPageHead';

export default {
  name: 'Structure',
  components: { BaseTabs, AppPageHead },
  computed: {
    ...mapGetters('Users', ['userAvailableRoutes']),
    currentRouteName() {
      return this.$route.name;
    },
    tabs() {
      return [
        {
          title: this.$t('admin.pages.structure.tabs.list'),
          route: 'structure:employees',
          permissions: ['directory_employee_getemployeelist__invoke'],
        },
        {
          title: this.$t('admin.pages.structure.tabs.tree'),
          route: 'structure:tree',
          permissions: [], // Как будет готова ручка с бека - добавить сюда
        },
        {
          title: this.$t('admin.pages.structure.tabs.fired'),
          route: 'structure:firedEmployees',
          permissions: [], // Как будет готова ручка с бека - добавить сюда
        },
      ].filter(({ permissions }) => permissions.some((i) => this.userAvailableRoutes.includes(i)));
    },
  },
  methods: {
    onChangePage(route) {
      if (this.currentRouteName !== route) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>