export const QuestionaryColorMethod = {
  90: '--color-red',
  180: '--color-yellow',
  360: '--color-blue',
};

export const QuestionaryClassMethod = {
  90: 'method-90',
  180: 'method-180',
  360: 'method-360',
};
