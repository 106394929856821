<template>
  <div class="RestorePasswordEmail">
    <RestorePasswordContent
      :icon="'key'"
      :title="$t('restore-password.titles.forgotPassword')"
      :text="$t('restore-password.texts.enterEmail')"
    />
    <b-form class="RestorePasswordForm" novalidate @submit.stop.prevent="onSubmit">
      <b-form-group
        id="form-email-group"
        label-class="Label mb-1"
        :label="`${$t('labels.email')}:`"
        label-for="form-email"
        class="mb-3"
      >
        <b-form-input
          id="form-email"
          v-model.trim.lazy="$v.form.email.$model"
          class="Input Medium"
          :class="{ 'Input--error': !validateState('email') && $v.form.email.$model }"
          type="email"
          autocomplete="on"
          :placeholder="`${$t('placeholders.email')}`"
        ></b-form-input>
      </b-form-group>
      <b-button :disabled="$v.form.$anyError" type="submit" class="Button Button-Main Full">
        {{ $t('base-actions.resetPassword') }}
      </b-button>
    </b-form>
    <router-link :to="{ name: 'login' }" class="RestorePasswordEmail-Link">
      {{ $t('base-actions.backToLogin') }}
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import RestorePasswordContent from '@/views/Auth/_components/RestorePasswordContent';

export default {
  name: 'RestorePasswordEmail',
  components: {
    RestorePasswordContent,
  },
  data: () => ({
    form: {
      email: '',
    },
  }),
  computed: {
    ...mapGetters('Settings', ['isMobile']),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions('Admin', ['checkEmailForRestorePassword']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.checkEmailForRestorePassword({ ...this.form }).then(() => {
          this.$emit('send-email', { email: this.form.email, status: true });
          this.form.email = '';
        });
      } else return false;
    },
  },
};
</script>