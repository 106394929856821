export const Users = {
  modelUsers: ({
    disabled = false,
    email = '',
    roles = [],
    firstName = '',
    lastName = '',
    locale = 'ru',
    slackId = '',
    employeeIdList = [],
  } = {}) => ({
    disabled,
    email,
    roles,
    firstName,
    lastName,
    locale,
    slackId,
    employeeIdList,
  }),
};
