<template>
  <span :class="['QuestionaryNumber', `QuestionaryNumber--${status}`]">
    <slot name="default"></slot>
  </span>
</template>

<script>
export default {
  name: 'QuestionaryNumber',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
