export const POFILE_REPORTS_HEADERS = [
  {
    key: 'number',
    name: '№',
    colName: 'number',
    style: 'width: 10%; min-width: 60px',
  },
  {
    key: 'dateStart',
    name: 'Name',
    style: 'width: 30%; min-width: 200px',
    sortable: true,
    colName: 'dateStart',
  },
  {
    key: 'dateEnd',
    name: 'Position',
    style: 'width: 30%; min-width: 200px',
    sortable: true,
    colName: 'dateEnd',
  },
  {
    key: 'method',
    name: 'Method',
    style: 'width: 10%; min-width: 100px',
    colName: 'method',
  },
  {
    key: 'action',
    name: 'Actions',
    style: 'width: 20%; min-width: 200px',
  },
];
