<template>
  <div class="AppSideBarList">
    <SelectedQuestionary />
    <UserProfile />
  </div>
</template>

<script>
import SelectedQuestionary from '@/components/General/SelectedQuestionary';
import UserProfile from '@/components/General/UserProfile';

export default {
  name: 'AppSideBarList',
  components: { SelectedQuestionary, UserProfile },
};
</script>