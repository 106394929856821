<template>
  <div
    class="AppListHead"
    :class="{
      'AppListHead--onlyActions': !$scopedSlots.search,
      'AppListHead--onlySearch': !$scopedSlots.actions,
    }"
  >
    <div v-if="$scopedSlots.search" class="AppListHead-Search">
      <slot name="search"></slot>
    </div>
    <div v-if="$scopedSlots.actions" class="AppListHead-Actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppListHead',
};
</script>