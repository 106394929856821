<template>
  <div class="Navigation-Links">
    <template v-for="(item, index) in navigationLinks">
      <div v-if="hideDirectoryPage(item)" :key="`Navigation-Row-${index}`" class="Navigation-Row">
        <router-link
          v-if="item.route && !item.children"
          :to="{ name: item.route }"
          class="Navigation-Link"
          :class="{
            'Navigation-Link--disable': item.disable,
            'Navigation-Link--active': checkActiveParentRoute(item),
          }"
          active-class="Navigation-Link--active"
          :exact="true"
          append
          @click.prevent.native="onClickNavLink(item)"
        >
          <span class="Navigation-Link-In">
            <span class="Name">
              <BaseIcon v-if="item.icon" :name="item.icon" />
              <span>{{ item.title }}</span>
            </span>
          </span>
        </router-link>
        <div
          v-else
          class="Navigation-Link"
          :class="{
            'Navigation-Link--disable': item.disable,
            'Navigation-Parent': item.children,
            'Navigation-Link--active': checkActiveParentRoute(item),
          }"
        >
          <div class="Navigation-Link-In" @click.prevent="onClickNavLink(item)">
            <span class="Name">
              <BaseIcon v-if="item.icon" :name="item.icon" />
              <span>{{ item.title }}</span>
            </span>
            <template v-if="item.children">
              <BaseIcon :name="`chevron-${!toggleChildren ? 'down' : 'up'}`" />
            </template>
          </div>
          <template v-if="item.children">
            <AppNavigationLinks
              :key="`AppNavigationLinks-${item.route}`"
              :items="item.children"
              :class="['Navigation-Children', { isOpen: toggleChildren }]"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'AppNavigationLinks',
  components: {
    BaseIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    toggleChildren: false,
  }),
  computed: {
    ...mapState({
      currentUserRoles: (state) => state.Admin.currentUserInfo.roles,
    }),
    ...mapGetters('Users', ['userAvailableRoutes']),
    navigationLinks() {
      return this.items
        .filter((item) => {
          return this.checkPermission(item);
        })
        .map((item) => {
          return {
            ...item,
            title: this.$i18n.t(`navigation.${item?.page}`),
          };
        });
    },
    isUserAdminOrHR() {
      //TODO: Это костыль. Нужно будет выпилить как настроем нормальную систему доступов
      return this.currentUserRoles.some(({ id }) =>
        ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_HR'].includes(id),
      );
    },
  },
  mounted() {
    this.toggleChildren = this.navigationLinks.some(({ children }) => {
      return (
        children &&
        children.some(({ route }) => this.$route.matched.some(({ name }) => route === name))
      );
    });
  },
  methods: {
    onClickNavLink({ children, callback }) {
      if (callback) {
        this.$emit('emit', callback);
        return;
      }
      if (children) {
        this.toggleChildren = !this.toggleChildren;
      }
    },
    checkPermission({ disable = false, permissions = [], isIgnorePermissions = false }) {
      return this.userAvailableRoutes
        ? (!disable && permissions.some((i) => this.userAvailableRoutes.includes(i))) ||
            isIgnorePermissions
        : false;
    },
    hideDirectoryPage({ page }) {
      return !(page === 'directory' && !this.isUserAdminOrHR);
    },
    checkActiveParentRoute({ children, route }) {
      return (
        (children || []).some(({ route }) => route === this.$route.name) ||
        this.$route.matched.some(({ name }) => route === name)
      );
    },
  },
};
</script>
