import { vmoment } from '@/services/Helpers';

export const ModelProfile = {
  ProfilePrepareData: (data) => {
    const result = data;
    Object.keys(data).map((key) => {
      if (data[key] === null) {
        result[key] = '';
      }
    });
    return result;
  },
  ProfileUploadData: ({
    employeeId,
    photoUrl = null,
    lastName,
    firstName,
    middleName = null,
    location = null,
    status = null, // enum(vacation, sick, businessTrip, fired, remote, work, rest, holiday)
    statusEmoji = null,
    statusStartAt = null,
    statusEndAt = null,
    startWorkTimeAt = null,
    endWorkTimeAt = null,
    startWorkDayAt = null, // enum(monday, tuesday, wednesday, thursday, friday, saturday, sunday)
    endWorkDayAt = null, // enum(monday, tuesday, wednesday, thursday, friday, saturday, sunday)
    positionId,
    email,
    phone = null,
    timezone = null,
  }) => {
    const result = {
      employeeId,
      photoUrl,
      lastName,
      firstName,
      middleName,
      location,
      status,
      statusEmoji,
      statusStartAt,
      statusEndAt,
      startWorkTimeAt,
      endWorkTimeAt,
      startWorkDayAt,
      endWorkDayAt,
      positionId,
      email,
      phone,
      timezone,
    };
    Object.keys(result).map((key) => {
      if (result[key] === '') {
        result[key] = null;
      }
    });
    return result;
  },
  GeneralUploadData: ({
    employeeId,
    subdivisionId,
    leaderList = [],
    inCompany,
    inPosition,
    birthDate = null,
    isBirthDateHide,
    slackId = null,
    telegram = null,
    locale,
    firedAt = null,
  }) => {
    const result = {
      employeeId,
      subdivisionId,
      leaderList,
      inCompany: vmoment(inCompany).unix(),
      inPosition: vmoment(inPosition).unix(),
      birthDate: birthDate ? vmoment(birthDate).unix() : '',
      isBirthDateHide,
      slackId,
      telegram,
      locale,
      firedAt,
    };
    Object.keys(result).map((key) => {
      if (result[key] === '') {
        result[key] = null;
      }
    });
    return result;
  },
};
