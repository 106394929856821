import moment from 'moment';
import 'moment/locale/ru';
import i18n from '@/i18n';

moment.locale(i18n.locale);

export const differentDate = (date, locale = i18n.locale || 'ru') => {
  moment.locale(locale);
  const res = moment.duration(moment(date).diff(moment(), 'milliseconds')).humanize();
  return res === 'Invalid date' ? i18n.tc('labels.no-data') : res;
};

export const vmoment = moment;
