<template>
  <div class="BaseActions">
    <span
      v-for="(button, index) in preparedButtons"
      :key="`BaseAction-${index}`"
      :class="[button.class, 'Button', { 'Button-Disabled': button.disabled }]"
      @click="!button.disabled && $emit(button.action)"
    >
      <BaseIcon
        v-if="button.icon && button.icon.left"
        :class="button.icon.class"
        :name="button.icon.name"
        :width="button.icon.width"
        :height="button.icon.height"
      />
      {{ button.title }}
      <BaseIcon
        v-if="button.icon && button.icon.right"
        :class="button.icon.class"
        :name="button.icon.name"
        :width="button.icon.width"
        :height="button.icon.height"
      />
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'BaseActions',
  components: { BaseIcon },
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    preparedButtons() {
      return this.actions.map((i) => {
        return { title: this.$t(`base-actions.${i.action}`), ...i };
      });
    },
  },
};
</script>