<template>
  <div class="AppThemeSwitcher">
    <b-form-checkbox v-model="isDark" class="Switcher Switcher--Reverse">
      <span class="Wrap me-2">
        <span class="Value">{{ $t('dark-theme') }}</span>
      </span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AppThemeSwitcher',
  data: () => ({
    isDark: false,
  }),
  watch: {
    isDark: {
      handler(v) {
        this.$changeTheme(v ? 'Dark' : 'White');
        this.setDarkTheme(v);
      },
    },
  },
  mounted() {
    switch (localStorage.getItem('theme')) {
      case 'White': {
        this.isDark = false;
        break;
      }
      case 'Dark': {
        this.isDark = true;
        break;
      }
      default: {
        break;
      }
    }
  },
  methods: {
    ...mapMutations('Settings', ['setDarkTheme']),
  },
};
</script>