<template>
  <div class="AppFilter">
    <div class="AppFilter-Section">
      <div class="AppFilter-Section-Label mb-1">{{ $t('labels.period-from') }}:</div>
      <div class="AppFilter-Section-Field">
        <BaseDatepicker id="date-picker-startDate" v-model="filter.dateFrom" />
      </div>
    </div>
    <div class="AppFilter-Section">
      <div class="AppFilter-Section-Label mb-1">{{ $t('labels.period-to') }}:</div>
      <div class="AppFilter-Section-Field">
        <BaseDatepicker id="date-picker-startEnd" v-model="filter.dateTo" />
      </div>
    </div>
    <div class="AppFilter-Section">
      <div class="AppFilter-Section-Label mb-1">{{ $t('labels.method') }}:</div>
      <div class="AppFilter-Section-Field">
        <BaseSelect
          v-model="filter.method"
          class="Select--WidthBorder"
          option-value="name"
          option-key="id"
          :items="filterMethods"
          :placeholder="$t('select.all')"
        />
      </div>
    </div>
    <div class="AppFilter-Section">
      <div class="AppFilter-Section-Label mb-1">{{ $t('labels.type') }}:</div>
      <div class="AppFilter-Section-Field">
        <BaseSelect
          v-model="filter.type"
          class="Select--WidthBorder"
          option-value="name"
          option-key="id"
          :items="filterTypes"
          :placeholder="$t('select.all')"
        />
      </div>
    </div>
    <div class="AppFilter-Section AppFilter-Actions">
      <BaseActions :actions="filterActions" @apply="onApplyFilter" @reset="onResetFilter" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';
import BaseActions from '@/components/Base/BaseActions';
import BaseDatepicker from '@/components/Base/BaseDatepicker';
import { ObjectFunctions } from '@/services/Helpers';

const baseFilter = { dateFrom: '', dateTo: '', method: '', type: '' };

export default {
  name: 'AppFilter',
  components: {
    BaseSelect,
    BaseActions,
    BaseDatepicker,
  },
  data: () => ({
    filter: ObjectFunctions.deepClone(baseFilter),
  }),
  computed: {
    ...mapState('Directories', ['structure']),
    filterMethods() {
      return this.structure?.questionaryMethodCollection || [];
    },
    filterTypes() {
      return this.structure?.questionaryTypeCollection || [];
    },
    filterActions() {
      return [{ action: 'apply', class: 'Button-Main' }, { action: 'reset' }];
    },
  },
  methods: {
    onApplyFilter() {
      const prepareFilter = Object.keys(this.filter).reduce((acc, i) => {
        if (this.filter[i] !== '') {
          acc[i] = this.filter[i];
        }
        return acc;
      }, {});
      this.$emit('filter', prepareFilter);
    },
    onResetFilter() {
      this.filter = ObjectFunctions.deepClone(baseFilter);
      this.onApplyFilter();
    },
  },
};
</script>