export const TESTING_HEADERS = [
  {
    key: 'number',
    name: '№',
    colName: 'number',
    style: 'width: 5%; min-width: 80px',
  },
  {
    key: 'lastName',
    name: 'Name',
    style: 'width: 18%; min-width: 220px',
    sortable: true,
  },
  {
    key: 'position',
    name: 'Position',
    style: 'width: 18%; min-width: 220px',
    sortable: true,
  },
  {
    key: 'subdivision',
    name: 'Subdivision',
    style: 'width: 18%; min-width: 220px',
    sortable: true,
  },
  {
    key: 'dateStart',
    name: 'Period',
    style: 'width: 18%; min-width: 220px',
    sortable: true,
    colName: 'date',
  },
  {
    key: 'status',
    name: 'Actions',
    style: 'width: 8%; min-width: 150px',
    colName: 'actions',
    sortable: true,
  },
];
