<template>
  <div class="Dictionary">
    <AppPageHead>
      <template #title> {{ currentTitle }} </template>
    </AppPageHead>
    <BaseTabs>
      <template #labels>
        <span
          v-for="({ route, name }, index) in tabs"
          :key="index"
          :class="['Label', { active: route === currentRouteName }]"
          @click="onChangePage(route)"
        >
          {{ name }}
        </span>
      </template>
    </BaseTabs>
    <div class="Dictionary-Wrap">
      <div class="Dictionary-Container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseTabs from '@/components/Base/BaseTabs';

import AppPageHead from '@/components/App/AppPageHead';

export default {
  name: 'Dictionary',
  components: { BaseTabs, AppPageHead },
  computed: {
    ...mapGetters('Users', ['userAvailableRoutes']),
    currentTitle() {
      return this.tabs.find(({ route }) => this.currentRouteName === route).title;
    },
    currentRouteName() {
      return this.$route.name;
    },
    tabs() {
      return [
        {
          route: 'dictionary:competencies',
          title: this.$t('admin.pages.directory.list.competencies'),
          name: this.$t('admin.pages.directory.tabs.competencies'),
          permissions: ['directory_competence_competence_list'],
        },
        {
          route: 'dictionary:positions',
          title: this.$t('admin.pages.directory.list.positions'),
          name: this.$t('admin.pages.directory.tabs.positions'),
          permissions: ['directory_position_position_list'],
        },
        {
          route: 'dictionary:subdivisions',
          title: this.$t('admin.pages.directory.list.subdivisions'),
          name: this.$t('admin.pages.directory.tabs.subdivisions'),
          permissions: ['directory_subdivision_subdivision_list'],
        },
        {
          route: 'dictionary:employees',
          title: this.$t('admin.pages.directory.list.employees'),
          name: this.$t('admin.pages.directory.tabs.employees'),
          permissions: ['directory_employee_employee_list'],
        },
      ].filter(({ permissions }) => permissions.some((i) => this.userAvailableRoutes.includes(i)));
    },
  },
  methods: {
    onChangePage(route) {
      if (this.currentRouteName !== route) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>
