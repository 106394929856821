<template>
  <div class="BaseItemList">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseItemList',
};
</script>