<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--SelectPosition"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.directory.tabs.positions-select-competencies') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="SelectListData">
        <div class="SelectListData-Search">
          <b-form-input
            id="form-search"
            v-model="search"
            class="Input"
            type="text"
            autocomplete="off"
            :placeholder="$t('placeholders.search')"
            @input="checkSelectedFlags"
          ></b-form-input>
        </div>
        <div class="SelectListData-Content">
          <div class="SelectListData-Actions">
            <div class="SelectListData-Actions--SelectAll">
              <template v-if="options.length > 1">
                <b-form-checkbox
                  v-model="selectedAll"
                  class="Checkbox Checkbox--OnlyLabel"
                  @change="onSelectAllCompetence"
                >
                  {{ selectedAll ? $t('labels.unselect-all') : $t('labels.select-all') }}
                </b-form-checkbox>
              </template>
            </div>
            <div class="SelectListData-Actions--ShowSelected">
              <b-form-checkbox
                v-model="showSelected"
                class="Checkbox Checkbox--OnlyLabel"
                @change="onToggleShowSelected"
              >
                {{
                  showSelected
                    ? $t('labels.show-all')
                    : `${$t('labels.show-selected')} (${selected.length})`
                }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="SelectListData-Wrap">
            <div class="SelectListData-List">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="selected"
                  class="Checkbox Checkbox-List"
                  :options="options"
                  value-field="id"
                  text-field="name"
                  @input="checkSelectedFlags"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <BaseActions
          :actions="modalActions"
          @save="$emit('save', selectedData)"
          @cancel="$emit('cancel')"
        />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

export default {
  name: 'DictionaryFormSelectPositions',
  components: {
    BaseIcon,
    BaseModalWrap,
    BaseActions,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    checkedOptions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
    selected: [],
    selectedAll: false,
    showSelected: false,
  }),
  computed: {
    options() {
      return this.items.filter(({ id, name }) => {
        return this.showSelected
          ? name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 &&
              this.selected.includes(id)
          : name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      });
    },
    selectedData() {
      return this.items.filter(({ id }) => this.selected.includes(id)) || [];
    },
    modalActions() {
      return [{ action: 'save', class: 'Button-Main' }, { action: 'cancel' }];
    },
  },
  mounted() {
    this.selected = this.checkedOptions;
    this.checkSelectedFlags();
  },
  methods: {
    onSelectAllCompetence(checked) {
      this.selected = checked ? this.options.map(({ id }) => id) : [];
      this.checkSelectedFlags();
    },
    onToggleShowSelected() {
      this.checkSelectedFlags();
    },
    checkSelectedFlags() {
      this.selectedAll = !this.options
        .map(({ id }) => {
          return this.selected.includes(id);
        })
        .includes(false);
    },
  },
};
</script>