<template>
  <div class="ReportListItem">
    <div class="ReportListItem-Left">
      <div class="ReportListItem-Section">
        <div class="ReportListItem-Info">
          <div class="ReportListItem-Number">
            <span> № {{ number }} </span>
          </div>
          <div class="ReportListItem-Reporter" @click="$emit('report')">{{ reporter }}</div>
        </div>
        <div class="ReportListItem-Subject">
          <BaseIcon v-if="questionaryTypeIcon" :name="questionaryTypeIcon" class="me-2" />
          {{ type }}
        </div>
      </div>
      <div class="ReportListItem-Section">
        <div class="ReportListItem-Date">
          <BaseIcon name="calendar" class="me-1" />
          <span>
            <span>
              {{ questionaryDateStartTesting }}
            </span>
            <span> - </span>
            <span>
              {{ questionaryDateEndTesting }}
            </span>
          </span>
        </div>
        <div class="ReportListItem-Method">
          <QuestionaryMethod :method="report.method" />
        </div>
      </div>
    </div>
    <div class="ReportListItem-Right">
      <div class="ReportListItem-Section">
        <span class="Button-InTables Button me-2" @click="$emit('export')">
          <BaseIcon name="arrow-circle-broken-down" height="16" width="16" class="me-1" />
          <span> {{ $t('labels.download-pdf') }} </span>
        </span>
        <span class="Button-InTables Button" @click="$emit('send')">
          <BaseIcon name="send-01" height="16" width="16" class="me-1" />
          <span> {{ $t('labels.send-report') }} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import QuestionaryMethod from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryMethod';
import { vmoment } from '@/services/Helpers';

import { QuestionaryIconTypeTesting } from '@/constants/Directories/QuestionaryIconTypeTesting';

export default {
  name: 'ReportListItem',
  components: {
    BaseIcon,
    QuestionaryMethod,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dateFormat: 'DD MMM YYYY',
  }),
  computed: {
    ...mapGetters('Directories', ['questionaryTypeCollection']),
    number() {
      return '';
    },
    reporter() {
      return this.report?.subject || '';
    },
    type() {
      return this.questionaryTypeCollection?.[this.report?.type] || '';
    },
    questionaryDateStartTesting() {
      return vmoment(this.report?.dateStart || '').format(this.dateFormat) || null;
    },
    questionaryDateEndTesting() {
      return vmoment(this.report?.dateEnd || '').format(this.dateFormat) || null;
    },
    questionaryTypeIcon() {
      return QuestionaryIconTypeTesting?.[this.report?.type] || null;
    },
  },
};
</script>
