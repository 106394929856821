import { Api } from '@/services/General/Api';
import { ENDPOINTS_QUESTIONARY } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async createQuestionary(_, questionary = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_ITEM_CREATE,
          data: { payload: questionary },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateQuestionary(_, questionary = {}) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_ITEM_UPDATE,
          data: { payload: questionary },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateStatusQuestionary(_, data = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_ITEM_UPDATE_STATUS,
          data: {
            payload: data,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async dublicateQuestionary(_, id = '') {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_ITEM_DUBLICATE,
          data: {
            payload: id,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteQuestionary(_, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_ITEM_DELETE,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
