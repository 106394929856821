<template>
  <div class="StructureEmployeeList">
    <div class="StructureEmployeeList-Head">
      <AppListHead>
        <template #search>
          <b-form-input
            class="Input--transparent Input--no-border Input"
            :placeholder="$t('placeholders.search')"
            type="text"
            @change="search = $event"
          ></b-form-input>
        </template>
        <template #actions></template>
      </AppListHead>
    </div>
    <div class="StructureEmployeeList-Container">
      <!-- TODO: Включить после реализации раздела управления  -->
      <BaseInfoBlock v-if="false" id="employeeInfo">
        <template #title> <BaseIcon name="alert-circle" /> Рекомендация </template>
        Прежде, чем добавить сотрудников, перейдите в раздел Управление <br />
        Чтобы настроить список подразделений и должностей
      </BaseInfoBlock>
      <div class="StructureEmployeeList-Data ScrollBar--hide">
        <div
          ref="scrollEmployees"
          class="StructureEmployeeList-Employees ScrollBar--hide"
          @scroll="handleScroll"
        >
          <div class="StructureEmployeeList-Employees-Wrap">
            <EmployeesBySubdivision
              v-for="({ id, name }, index) in structureSubdivisions"
              :id="id"
              :ref="id"
              :key="`employeesBySubdivision-${index}`"
              :subdivision-id="id"
              :subdivision-name="name"
              :search-value="search"
              class="StructureEmployeeList-Employee"
              @check-scroll="checkScrollPosition(id)"
            />
          </div>
          <div class="EmptyDiv"></div>
        </div>
        <div class="StructureEmployeeList-Subdivisions">
          <div class="StructureEmployeeList-Subdivisions-Title">{{ subTitle }}</div>
          <div class="StructureEmployeeList-Subdivisions-List">
            <div
              v-for="(subdivision, index) in structureSubdivisions"
              :key="`subdivision-${index}`"
              class="StructureEmployeeList-Subdivisions-Item"
              :class="{
                active: activeSubdivision === subdivision.id,
              }"
              @click="onScrollToSubdivisionItem(subdivision.id)"
            >
              {{ subdivision.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseInfoBlock from '@/components/Base/BaseInfoBlock';

import AppListHead from '@/components/App/AppListHead';
import EmployeesBySubdivision from '@/views/Structure/_components/EmployeesBySubdivision';
import Declension from '@/services/Helpers/HelperDeclension';

export default {
  name: 'StructureEmployeeList',
  components: { BaseIcon, BaseInfoBlock, AppListHead, EmployeesBySubdivision },
  data: () => ({
    search: '',
    activeSubdivision: {},
  }),
  computed: {
    ...mapState('Structure', ['structureSubdivisions']),
    ...mapGetters('Structure', ['structureEmployeesCount']),
    subTitle() {
      return `
       ${this.$i18n.tc('labels.total')}
        ${this.structureEmployeesCount}
        ${Declension(this.structureEmployeesCount, [
          this.$i18n.t('labels.employees[0]'),
          this.$i18n.t('labels.employees[1]'),
          this.$i18n.t('labels.employees[2]'),
        ])}
      `;
    },
  },
  mounted() {
    this.getStructureSubdivisionsList().then(() => {
      this.activeSubdivision = this.structureSubdivisions.find(({ name }) => name).id;
    });
  },
  methods: {
    ...mapActions({
      getStructureSubdivisionsList: 'Structure/getStructureSubdivisionsList',
    }),
    onScrollToSubdivisionItem(subdivision) {
      this.activeSubdivision = subdivision;

      let scrollDiv = this.$refs.scrollEmployees;
      let el = this.$refs[subdivision][0].$el;

      if (!el) return;

      let scrollDivRect = scrollDiv.getBoundingClientRect();
      let idRect = el.getBoundingClientRect();
      let y = scrollDivRect.y;
      let y1 = idRect.y;
      let offset = y1 - y;

      scrollDiv.scrollBy({
        top: offset,
        behavior: 'smooth',
      });
    },
    handleScroll(event) {
      const children = event.target.querySelectorAll('.StructureEmployeeList-Employee');
      const { top: parentTop } = event.target.getBoundingClientRect();
      children.forEach((child) => {
        const { top: childTop, bottom: childBottom } = child.getBoundingClientRect();
        if (childTop - parentTop <= 0 && childBottom - parentTop >= 0) {
          this.activeSubdivision = child.getAttribute('id');
        }
      });
    },
    checkScrollPosition(id) {
      if (this.activeSubdivision === id) {
        this.onScrollToSubdivisionItem(id);
      }
    },
  },
};
</script>