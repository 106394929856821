<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--UserForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.users.title') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="UserFormData">
        <div class="UserFormData-Fields">
          <div class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.user-name') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.firstName.$model"
                :state="validateState('firstName')"
                class="Input Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.enter-firstname')"
              ></b-form-input>
            </div>
          </div>
          <div class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.surname') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.lastName.$model"
                :state="validateState('lastName')"
                class="Input Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.enter-lastname')"
              ></b-form-input>
            </div>
          </div>
          <div class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.email') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.email.$model"
                :state="validateState('email')"
                class="Input Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.email')"
              ></b-form-input>
            </div>
          </div>
          <div class="UserFormData-Field">
            <div class="Label-Input">
              <img
                class="Messenger me-1"
                :src="require(`@/assets/Images/Messenger/slack.svg`)"
                alt="slack-icon"
              />
              {{ $t('labels.slack-id') }}:
            </div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.slackId.$model"
                :state="validateState('slackId')"
                class="Input Medium"
                autocomplete="off"
                :placeholder="$t('placeholders.slack-id')"
              ></b-form-input>
            </div>
          </div>
          <div class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.locale') }}:</div>
            <div class="Field">
              <BaseSelect
                v-model="$v.form.locale.$model"
                class="Select--WidthBorder"
                option-value="title"
                option-key="code"
                :items="languages"
                :placeholder="$t('select.no-select')"
                clearable
                searchable
              />
            </div>
          </div>
          <div class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.roles') }}:</div>
            <div class="Field">
              <b-form-checkbox-group v-model="form.roles" class="Checkbox-Roles">
                <b-form-checkbox
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="role"
                  class="Checkbox"
                >
                  {{ role.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
          <div v-if="isManagerRole && employees" class="UserFormData-Field">
            <div class="Label-Input">{{ $t('labels.subordinates') }}:</div>
            <div class="Additional">
              <div class="Additional-Head">
                <div class="Additional-Label">{{ $t('labels.list') }}:</div>
                <div class="Additional-Action">
                  <span class="Button" @click="openSelectEmployeeModal">
                    {{ $t('base-actions.select') }}
                  </span>
                </div>
              </div>
              <div class="List">
                <ParticipantCard
                  v-for="(item, index) in selectedEmployeeList"
                  :key="`ParticipantCard-${item.id}`"
                  :item="item"
                >
                  <template #actions>
                    <span class="Button--reset Button" @click="deleteItem(index)">
                      <BaseIcon height="16" width="16" name="x-close" />
                    </span>
                  </template>
                </ParticipantCard>
              </div>
            </div>
          </div>
        </div>
        <div class="UserFormData-Checkbox">
          <b-form-checkbox v-model="form.disabled" class="Checkbox">
            {{ $t('labels.disabled') }}
          </b-form-checkbox>
        </div>
      </div>
      <UserSelectEmployees
        :id="nameModalOfSelectEmployee"
        :key="updateKeyModalSelectEmployee"
        :title="$t('labels.choose-subordinates')"
        :checked-options="checkedOptions"
        :disabled-options="disabledOptions"
        :items="employees"
        @save="saveSelectEmployeeModal"
        @cancel="closeSelectEmployeeModal"
      />
      <template #footer>
        <BaseActions :actions="modalActions" @save="onSendActionType" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';
import BaseSelect from '@/components/Base/BaseSelect';

import ParticipantCard from '@/components/General/ParticipantCard';
import UserSelectEmployees from '@/views/Estimation/Users/_components/Form/Modal/UserSelectEmployees';

import { email, required } from 'vuelidate/lib/validators';
import { ObjectFunctions } from '@/services/Helpers';

import { languages } from '@/constants/SiteLanguages';

export default {
  name: 'UserFormCreateDefault',
  components: {
    BaseIcon,
    BaseModalWrap,
    BaseSelect,
    BaseActions,
    ParticipantCard,
    UserSelectEmployees,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    itemModel: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form: {},
    languages,
    updateKeyModalSelectEmployee: 0,
    nameModalOfSelectEmployee: 'userSelectEmployee-modal',
    selectedEmployeeList: [],
  }),
  computed: {
    ...mapState('Dictionary', ['employees']),
    ...mapGetters({
      roles: 'Users/userRolesList',
    }),
    modalActions() {
      return [{ action: 'save', class: 'Button-Main' }, { action: 'cancel' }];
    },
    isManagerRole() {
      return (this.form?.roles || []).filter(({ id }) => id === 'ROLE_MANAGER').length || null;
    },
    checkedOptions() {
      return this.selectedEmployeeList.map(({ id }) => id) || [];
    },
    disabledOptions() {
      return (this.employees || [])
        .filter(({ email }) => {
          return email === this.form.email;
        })
        .map((employee) => employee.id);
    },
  },
  watch: {
    itemModel() {
      this.updateKeyModalSelectEmployee++;
      this.form = ObjectFunctions.deepClone(this.itemModel);
      this.$v.$touch();
    },
    selectedEmployeeList() {
      this.updateKeyModalSelectEmployee++;
    },
    'form.employeeList'(v) {
      const ids = (v || []).map(({ id }) => id);
      this.selectedEmployeeList = this.employees.filter(({ id }) => ids.includes(id));
    },
    isManagerRole(v) {
      if (!v) this.selectedEmployeeList = [];
    },
  },
  validations: {
    form: {
      email: {
        email,
        required,
      },
      roles: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      locale: {
        required,
      },
      slackId: {},
    },
  },
  mounted() {
    this.getEmployeesList();
  },
  methods: {
    ...mapActions('Dictionary', ['getEmployeesList']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSendActionType() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        const sendData = { ...this.form, employeeIdList: this.checkedOptions };
        delete sendData.employeeList;
        this.$emit(this.actionType, sendData);
      }
    },
    deleteItem(index) {
      this.selectedEmployeeList.splice(index, 1);
    },
    openSelectEmployeeModal() {
      this.$bvModal.show(this.nameModalOfSelectEmployee);
    },
    saveSelectEmployeeModal(values) {
      this.$bvModal.hide(this.nameModalOfSelectEmployee);
      this.selectedEmployeeList = values;
    },
    closeSelectEmployeeModal() {
      this.$bvModal.hide(this.nameModalOfSelectEmployee);
    },
  },
};
</script>
