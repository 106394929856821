<template>
  <div class="LegalOfficialDocuments">
    <h4>Сведения о правообладателе ПО «HR Galaxy Camellia, модуль 360»</h4>
    <br />
    <p>
      Общество с ограниченной ответственностью «МарфаТех» (ООО «МарфаТех»)<br />
      Адрес места нахождения: РФ, 115191, Москва, улица Большая Тульская дом 11, помещение XIII,
      комната 1<br />
      Адрес для корреспонденции: РФ, 115191, Москва, улица Большая Тульская дом 11, помещение XIII,
      комната 1<br />
      ИНН: 7726478833<br />
      КПП: 772601001<br />
      ОГРН: 1217700291820<br />
      Сайт: <a href="https://marfa-tech.com ">https://marfa-tech.com</a><br />
      Электронная почта: <a href="mailto:hello@marfa-tech.com">hello@marfa-tech.com</a><br />
    </p>
    <br />
    <ul>
      <li>
        <a download="Лист записи ЕГРЮЛ.pdf" href="/documents/document1.pdf">
          Лист записи ЕГРЮЛ.pdf
        </a>
      </li>
      <li>
        <a download="Свидетельсвои о регистрации.pdf" href="/documents/document2.pdf">
          Свидетельсвои о регистрации.pdf
        </a>
      </li>
      <li>
        <a download="Свидетельство аккредитации.pdf" href="/documents/document3.pdf">
          Свидетельство аккредитации.pdf
        </a>
      </li>
    </ul>
    <br />
    <p>
      Цена определяется индивидуально и формируется по запросу направленному на наш электронный
      адрес:
      <a href="mailto:hello@marfa-tech.com">hello@marfa-tech.com</a>.
    </p>
    <p>Наши сотрудники свяжутся с вами для уточнения деталей и подтверждения информации</p>
    <br />
    <p>
      Программное обеспечение предоставляется как сервис. Специальные действия по установке не
      требуются.
    </p>
    <br />
    <ul>
      <li>
        <a
          download="Описание функциональных характеристик программного обеспечения Модуля 360.pdf"
          href="/documents/document4.pdf"
        >
          Функциональные требования.pdf
        </a>
      </li>
      <li>
        <a download="Руководство пользователя Модуля 360.pdf" href="/documents/document5.pdf">
          Руководство пользователя.pdf
        </a>
      </li>
      <li>
        <a download="Руководство администратора Модуля 360.pdf" href="/documents/document6.pdf">
          Руководство администратора.pdf
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LegalOfficialDocuments',
};
</script>