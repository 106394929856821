<template>
  <div
    v-click-outside="onClickOutside"
    :class="{ 'Select--Opened': isSelectOpen, 'Select--Invalid': !state }"
    class="Select"
  >
    <div
      class="Select-Current"
      :class="{
        'Select-Current--Clearable': canClear,
        'Select-Current--Empty': !canShowItems,
      }"
      @click="onToggleSelect"
    >
      <div class="Select-Value" :class="{ 'Select-Value--NoSelect': !current }">
        <span class="Label" :class="{ 'Label-List': multiple }">
          <template v-if="isShowCurrentSelect">
            <template v-if="multiple">
              <span
                v-for="(item, index) in current"
                :key="`Select-Current-${index}`"
                class="Select-Current-Item"
              >
                <span class="Select-Current-Item-Value">
                  <template v-if="$scopedSlots.selected">
                    <slot name="selected" :item="{ ...item }" />
                  </template>
                  <template v-else>
                    {{ item[optionValue] }}
                  </template>
                </span>
                <span class="Select-Current-Item--Clear" @click.stop="onDeleteItem(index)">
                  <BaseIcon name="x-close" width="12" height="12" />
                </span>
              </span>
            </template>
            <template v-else>
              <template v-if="$scopedSlots.selected">
                <span v-if="prefix" class="Select-Prefix">{{ prefix }}</span>
                <slot name="selected" :item="{ ...selectedItem }" />
              </template>
              <template v-else>
                <span v-if="prefix" class="Select-Prefix">{{ prefix }}</span>
                {{ selectedLabel }}
              </template>
            </template>
          </template>
          <template v-else>
            <span v-if="prefix" class="Select-Prefix">{{ prefix }}</span>
            <template v-else> {{ placeholder || $t('base-actions.select') }} </template>
          </template>
        </span>
      </div>
      <div v-if="canClear && !multiple" class="Select-Clear" @click="onSelectItem('')">
        <BaseIcon name="x-close" />
      </div>
      <div v-if="canShowItems" class="Select-Caret">
        <slot v-if="$scopedSlots.caret" name="caret" />
        <template v-else>
          <BaseIcon :name="`chevron-${!isSelectOpen ? 'down' : 'up'}`" />
        </template>
      </div>
    </div>
    <div
      class="Select-List"
      :class="{
        'Select-List--Hide': !isSelectOpen,
      }"
    >
      <div v-if="searchable" class="Select-Search">
        <b-form-input
          v-model="search"
          class="Input--no-border Input"
          type="text"
          autocomplete="off"
          :placeholder="$t('header.search.placeholder')"
        />
      </div>
      <div
        class="Select-List-Wrap"
        :class="{ 'Select-List--Scroll ScrollBar--hide': filteredItems.length > 3 }"
      >
        <div
          v-for="(i, idx) in filteredItems"
          :key="idx"
          class="Select-List-Item"
          :class="{ 'Select-List-Item--Active': checkActiveItem(i[optionKey]) }"
          @click="onSelectItem(i[optionKey])"
        >
          <slot
            v-if="$scopedSlots.item"
            name="item"
            :item="{ ...i, active: checkActiveItem(i[optionKey]) }"
          />
          <template v-else>
            {{ i[optionValue] }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import { clickOutside } from '@/services/Directives/clickOutside';

export default {
  name: 'BaseSelect',
  components: { BaseIcon },
  directives: { clickOutside },
  props: {
    value: {
      type: [String, Number, Array],
      required: true,
    },
    state: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    items: {
      type: Array,
      required: true,
    },
    optionKey: {
      type: String,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchFields: {
      type: Array,
      required: false,
      default() {
        return [this.optionValue];
      },
    },
    hideListIfOneItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
      default: '',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    current: null,
    isSelectOpen: false,
    search: '',
  }),
  computed: {
    isShowCurrentSelect() {
      return (
        (this.current && typeof this.current !== 'object') ||
        (this.multiple && this.current.length > 0)
      );
    },
    filteredItems() {
      const regular = new RegExp(this.search, 'gi');
      return this.searchable && this.search !== ''
        ? this.items.filter((i) => {
            let isFind = false;
            this.searchFields.forEach((field) => {
              if (i[field] && i[field].toString().match(regular)) {
                isFind = true;
              }
            });
            return isFind;
          })
        : this.items;
    },
    selectedItem() {
      return this.items.find((i) => i[this.optionKey] === this.value);
    },
    selectedLabel() {
      return this.selectedItem[this.optionValue];
    },
    canClear() {
      return this.clearable && this.current;
    },
    canShowItems() {
      return this.items.length > 1 || (this.items.length === 1 && !this.hideListIfOneItem);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.current = v;
      },
    },
  },
  methods: {
    onToggleSelect() {
      if (this.canShowItems) this.isSelectOpen = !this.isSelectOpen;
    },
    changeSelectState(v) {
      this.isSelectOpen = v;
    },
    onSelectItem(v) {
      if (this.multiple) {
        this.emitMultipleSelect(v);
      } else {
        this.emitDefaultSelect(v);
      }
    },
    onClickOutside() {
      this.changeSelectState(false);
    },
    onDeleteItem(index) {
      this.current.splice(index, 1);
    },
    checkActiveItem(key) {
      if (this.multiple) {
        return !!this.current.find((item) => item[this.optionKey] === key);
      }
      return key === this.current;
    },
    emitMultipleSelect(v) {
      const findItemIdx = this.current.findIndex((item) => item[this.optionKey] === v);
      if (findItemIdx !== -1) {
        this.onDeleteItem(findItemIdx);
      } else {
        this.current.push(this.filteredItems.find((item) => item[this.optionKey] === v));
      }
      this.$emit('input', this.current);
    },
    emitDefaultSelect(v) {
      if (v === this.current) return;
      this.$emit('input', v);
      this.changeSelectState(false);
    },
  },
};
</script>
