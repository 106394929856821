import { Api } from '@/services/General/Api';
import { ENDPOINTS_QUESTIONARY } from '@/services/Endpoints';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    structure: null,
  },
  mutations: {
    updateQuestionaryStructure(state, payload) {
      state.structure = payload;
    },
  },
  actions: {
    async getQuestionaryStructure({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_GET_STRUCTURE }).then(
          ({ data } = {}) => {
            commit('updateQuestionaryStructure', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {
    // Структура анкеты справочников для создания анкеты
    structureForQuestionaryCreate(state) {
      return {
        participantsTypes: state.structure?.participantTypeCollection || [],
        questionaryMethods: state.structure?.questionaryMethodCollection || [],
        questionaryStatuses: state.structure?.questionaryStatusCollection || [],
        questionaryTypes: (state.structure?.questionaryTypeCollection || []).map((item) => {
          return {
            id: item.id,
            name: i18n.tc(`admin.pages.journal.questionary.type.tabs.${item.id}`),
          };
        }),
        questionaryPeriod: state.structure?.questionaryPeriodTypeCollection || [],
      };
    },
    // Массив статусов анкеты
    questionaryStatusCollection: (state) => {
      return (state.structure?.questionaryStatusCollection || []).reduce((acc, status) => {
        acc[status.id] = i18n.tc(`admin.pages.journal.statuses.${status.id}`);
        return acc;
      }, {});
    },

    // Массив типов анкетирования
    questionaryTypeCollection: (state) => {
      return (state.structure?.questionaryTypeCollection || []).reduce((acc, type) => {
        acc[type.id] = type.name;
        return acc;
      }, {});
    },

    // Массив типов участников
    participantTypeCollection: (state) => {
      return (state.structure?.participantTypeCollection || []).reduce((acc, type) => {
        acc[type.id] = type.name;
        return acc;
      }, {});
    },
  },
};
