<template>
  <b-form class="AdminSettingRestorePassword" novalidate @submit.stop.prevent="onSubmit">
    <b-form-group
      id="form-password-group"
      label-class="Label mb-1"
      :label="`${$t('labels.newPassword')}:`"
      label-for="form-password"
      class="AdminSettingRestorePassword-FormGroup"
    >
      <div class="AdminSettingRestorePassword-InputWithIcon">
        <b-form-input
          id="form-password"
          v-model.trim.lazy="$v.form.password.$model"
          class="Input Medium"
          :class="{ 'Input--error': !validateState('password') && $v.form.password.$model }"
          :type="isShowPassword ? 'text' : 'password'"
          autocomplete="off"
          :placeholder="`${$t('placeholders.enter-password')}`"
        ></b-form-input>
        <span
          class="AdminSettingRestorePassword-PasswordIcon"
          @click="isShowPassword = !isShowPassword"
        >
          <BaseIcon :name="isShowPassword ? 'eye' : 'eye-close'" fill stroke />
        </span>
      </div>
      <AppRestorePasswordTooltip
        target="form-password"
        triggers="focus"
        custom-class="Tooltip"
        :items="passwordValidationInfo"
        :valid-field="$v.form.password"
      />
    </b-form-group>
    <b-form-group
      id="form-confirmPassword-group"
      label-class="Label mb-1"
      :label="`${$t('labels.confirmNewPassword')}:`"
      label-for="form-confirmPassword"
      class="AdminSettingRestorePassword-FormGroup"
    >
      <div class="AdminSettingRestorePassword-InputWithIcon">
        <b-form-input
          id="form-confirmPassword"
          v-model.trim.lazy="$v.form.confirmPassword.$model"
          class="Input Medium"
          :class="{
            'Input--error': !validateState('confirmPassword') && $v.form.confirmPassword.$model,
          }"
          :type="isShowConfirmPassword ? 'text' : 'password'"
          autocomplete="off"
          :placeholder="`${$t('placeholders.confirmPassword')}`"
        ></b-form-input>
        <span
          class="AdminSettingRestorePassword-PasswordIcon"
          @click="isShowConfirmPassword = !isShowConfirmPassword"
        >
          <BaseIcon :name="isShowConfirmPassword ? 'eye' : 'eye-close'" fill stroke />
        </span>
      </div>
      <AppRestorePasswordTooltip
        target="form-confirmPassword"
        triggers="focus"
        custom-class="Tooltip"
        :items="confirmValidationInfo"
        :valid-field="$v.form.confirmPassword"
      />
    </b-form-group>
    <hr />
    <button
      type="submit"
      class="Button-Main Button"
      :class="{ Full: isMobile, 'Button-Disabled': $v.form.$anyError }"
    >
      {{ $t('base-actions.changePassword') }}
    </button>
  </b-form>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import { mapActions, mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import AppRestorePasswordTooltip from '@/components/App/AppRestorePasswordTooltip';

export default {
  name: 'SettingRestorePassword',
  components: {
    AppRestorePasswordTooltip,
    BaseIcon,
  },
  data: () => ({
    isShowPassword: false,
    isShowConfirmPassword: false,
    form: {
      password: '',
      confirmPassword: '',
    },
  }),
  computed: {
    ...mapGetters('Settings', ['isMobile']),
    passwordValidationInfo() {
      return [
        { errorType: 'containsLatin', text: this.$t('tooltipErrors.containsLatin') },
        { errorType: 'minLength', text: this.$t('tooltipErrors.minLength') },
        { errorType: 'containsSpecial', text: this.$t('tooltipErrors.containsSpecial') },
        { errorType: 'containsUppercase', text: this.$t('tooltipErrors.containsUppercase') },
      ];
    },
    confirmValidationInfo() {
      return [{ errorType: 'sameAsPassword', text: this.$t('tooltipErrors.sameAsPassword') }];
    },
  },
  mounted() {
    this.$v.$touch();
  },
  validations: {
    form: {
      password: {
        required,
        minLength: async (value) => {
          return value.length >= 8;
        },
        containsUppercase: async (value) => {
          return /^(?=.*[A-Z])/.test(value);
        },
        containsLatin: async (value) => {
          return /^[a-zA-Z0-9!"#$%&'()*+,-.\/:;><?=@\[\]\\^`{|}~\]_]+$/.test(value);
        },
        containsSpecial: async (value) => {
          return /^(?=.*[!"#$%&'()*+,-.\/:;><?=@\[\]\\^`{|}~\]_])/.test(value);
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    ...mapActions('Admin', ['resetPassword']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.resetPassword({ ...this.form });
      } else return false;
    },
  },
};
</script>