<template>
  <div class="LegalUserAgreement">
    <h4>
      ПУБЛИЧНАЯ ОФЕРТА НА ЗАКЛЮЧЕНИЕ ДОГОВОРА ОКАЗАНИЯ УСЛУГ ПО ТЕХНИЧЕСКОЙ ПОДДЕРЖКЕ ПРОГРАММНОГО
      ПРОДУКТА «HR Galaxy Camellia, модуль 360» (далее - Модуль 360)
    </h4>
    <br />
    <p>
      ООО «МарфаТех», именуемое в дальнейшем «Исполнитель», в лице Генерального директора Полякова
      Сергея Александровича, действующего на основании Устава, обязуется заключить с любым
      юридическим лицом или индивидуальным предпринимателем, именуемым в дальнейшем «Заказчик» или
      «Пользователь», Договор оказания услуг по технической поддержке программного продукта «Модуль
      360» (далее по тексту — Договор) на условиях, изложенных в настоящей публичной оферте, в
      случае принятия Заказчиком всех ее условий:
    </p>
    <br />
    <h5>1. ОБЩИЕ ПОЛОЖЕНИЯ</h5>
    <br />
    <p>
      1.1. Настоящий документ в соответствии со ст. 437 Гражданского кодекса РФ является публичной
      офертой (предложением) Исполнителя в адрес Заказчика, содержащей существенные условия Договора
      оказания услуг по технической поддержке программного продукта « Модуль 360».
    </p>
    <p>
      1.2. Полным и безоговорочным акцептом настоящей публичной оферты является подписание
      Заказчиком лицензионного договора с Исполнителем на использование программы для ЭВМ Модуль
      360. В соответствии со статьями 434, 438 Гражданского кодекса РФ с момента акцепта настоящей
      публичной оферты Договор считается заключенным в письменной форме на условиях настоящей
      публичной оферты и является равнозначным договору, подписанному двумя сторонами на бумажном
      носителе.
    </p>
    <p>
      Заказчик вправе в течение одного года с момента акцепта требовать оформления заключенного
      Договора в форме документа на бумажном носителе, подписанного сторонами. Для этого Заказчику
      необходимо распечатать Договор, находящийся на сайте Исполнителя по следующему адресу:
      <a href="https://360.galaxy-camellia.com/legal/documents"
        >https://360.galaxy-camellia.com/legal/documents</a
      >, и направить в адрес Исполнителя 2 (два) подписанных со своей стороны экземпляра Договора
      (дату и номер договора заполнять не нужно). Исполнитель в течение 5 (пяти) рабочих дней с
      момента получения от Заказчика Договора на бумажном носителе обязан подписать и вернуть 1
      (один) экземпляр подписанного сторонами Договора, 2 (второй) экземпляр остается у Исполнителя.
    </p>
    <p>1.3. Местом заключения Договора считается город Москва.</p>
    <p>
      1.4. При оформлении заявки на сайте Исполнителя Заказчик дополнительно подтверждает свое
      согласие с условиями, установленными настоящей публичной офертой, путем проставления отметки в
      графе «Я согласен с условиями публичной оферты».
    </p>
    <br />
    <h5>2. ПРЕДМЕТ ДОГОВОРА</h5>
    <br />
    <p>
      2.1. По Договору Заказчик поручает, а Исполнитель принимает на себя обязательство оказывать
      услуги по технической поддержке и техническому обслуживанию программного продукта «Модуль 360»
      (далее по тексту — ПП), на условиях, предусмотренных настоящей публичной офертой.
    </p>
    <p>2.2. Услуги по настоящему Договору оказываются Пользователю ПП на безвозмездной основе.</p>
    <p>
      2.3. Срок оказания услуг по Договору равен сроку, на который Пользователь приобрел лицензию на
      использование ПП.
    </p>
    <br />
    <h5>3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ ПО ТЕХНИЧЕСКОЙ ПОДДЕРЖКЕ</h5>
    <br />
    <p>3.1. В рамках получения услуг по технической поддержке Заказчик имеет право:</p>
    <p>3.1.1. Получать информацию по новым версиям ПП.</p>
    <p>
      3.1.2. Устанавливать и использовать новые версии ПП на условиях, изложенных в разделе 4
      настоящей публичной оферты.
    </p>
    <p>3.1.3. Задавать вопросы по функциям ПП.</p>
    <p>
      3.1.4. Выдвигать свои предложения по улучшению ПП. Если эти предложения не противоречат общему
      направлению развития ПП, то эти предложения могут быть учтены и реализованы в новой версии ПП.
    </p>
    <p>
      3.1.5. Требовать осуществления всех возможных действий по восстановлению базы данных,
      используемой в ПП, в случае ее повреждения. При предоставлении базы данных, используемой в ПП,
      Заказчик выражает согласие на обработку Исполнителем информации, содержащейся в направляемой
      базе данных, для целей исполнения Договора и предоставления услуг по восстановлению базы
      данных.
    </p>
    <p>3.2. В рамках оказания услуг по технической поддержке Исполнитель обязан:</p>
    <p>3.2.1. Предоставлять ответы на вопросы по функциям ПП, полученным от Заказчика</p>
    <p>3.2.2. По требованию Заказчика осуществлять настройку ПП, а также новых версий ПП.</p>
    <p>
      3.2.3. Осуществлять все возможные действия по восстановлению базы данных, используемой в ПП.
    </p>
    <p>
      3.2.4. Обеспечивать функционирование информационных систем, оператором которых является
      Исполнитель, и с которыми взаимодействует ПП, путем осуществления следующих действий:
    </p>
    <p>
      – обновление информации в базах данных информационных систем. Базы данных формируются на
      основании информации, полученной на возмездной основе от третьих лиц, являющихся обладателями
      данной информации, либо, если это не запрещено действующим законодательством Российской
      Федерации, полученной бесплатно. Обновление информации в базах данных информационных систем
      может быть прекращено, в случае если третьи лица, которые предоставляют информацию
      Исполнителю, существенно поменяют порядок предоставления информации, значительно повысят цены
      или усложнят получение информации настолько, что предоставлять её по зафиксированным в
      Договоре ценам станет нерентабельным;<br />
      – оплата услуг хостинга;<br />
      – мониторинг работоспособности внешних информационных систем;<br />
      – решение проблем безопасности (защита систем от взломов, DDOS атак и т.п., восстановление
      работоспособности систем);<br />
      – разрешение конфликтов при обновлении данных;<br />
      – создание и хранение резервных копий данных;<br />
      – осуществление иных действий, направленных на обеспечение функционирования информационных
      систем, оператором которых является Исполнитель, и с которыми взаимодействует ПП.
    </p>
    <p>
      3.3. Обращения Заказчика (запросы, требования, вопросы) могут направляться одним из следующих
      способов:
    </p>
    <p>
      3.3.1. Через чат-поддержку, расположенную на веб-сайте <a
        href="https://360.galaxy-camellia.com/"
        >https://360.galaxy-camellia.com/</a
      >.
    </p>
    <p>
      3.3.2. По электронной почте: hello@marfa-tech.com. Ответ на вопрос будет направлен на
      электронный адрес, указанный при оформлении заявки на техническую поддержку. Предложения по
      улучшению ПП могут направляться только по электронной почте.
    </p>
    <p>
      3.3. Техническая поддержка оказывается с понедельника по пятницу с 10:00 до 19:00 по
      московскому времени (кроме нерабочих праздничных дней, установленных Трудовым кодексом
      Российской Федерации).
    </p>
    <p>3.4. Исполнитель обязуется оказывать оперативную и своевременную поддержку Пользователей.</p>
    <br />
    <h5>4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ НОВЫХ ВЕРСИЙ ПП</h5>
    <br />
    <p>
      4.1. В рамках оказания услуг по технической поддержке Исполнитель, направив Заказчику
      уведомление о выпуске новой доступной Заказчику версии ПП, тем самым предоставляет Заказчику
      право на использование новой версии ПП, которая является самостоятельным результатом
      интеллектуальной деятельности. Использование новой версии ПП осуществляется на условиях,
      предусмотренных настоящим разделом публичной оферты.
    </p>
    <p>
      4.2. Получаемое Заказчиком право на использование новой версии ПП позволяет использовать новую
      версию ПП в соответствии с назначением.
    </p>
    <p>
      4.3. Функциональные возможности ПП новой версии могут отличаться от ПП предыдущих версии,
      доступных Заказчику. Устанавливая новую версию ПП Заказчик принимает ее как есть, в связи с
      чем Исполнитель не принимает претензии к формируемым ПП новой версии документам и иному
      функционалу ПП.
    </p>
    <p>
      4.4. ПП новой версии остается на тех же условиях и в той же комплектации, как и ПП,
      применяемый Пользователем.
    </p>
    <p>
      4.5. ПП новой версии является результатом интеллектуальной деятельности, правовая охрана
      которого устанавливается действующим законодательством РФ.
    </p>
    <p>4.6. Все интеллектуальные права на ПП новой версии принадлежат Исполнителю.</p>
    <p>
      4.7. Заказчик обязан не осуществлять действия (бездействия), которые могли бы нарушить или
      сделать недействительными права Исполнителя в отношении интеллектуальной собственности на ПП
      новой версии, его части и любых компонентов ПП новой версии. Заказчик обязан уведомлять
      Исполнителя обо всех известных ему случаях неправомерного использования ПП третьими лицами или
      работниками Заказчика.
    </p>
    <p>
      4.8. Заказчик не вправе распространять ПП новой версии, производить копирование ПП новой
      версии, а также осуществлять любые иные действия, которые могут повлечь нарушение
      интеллектуальных прав Исполнителя в отношении ПП новой версии, определенных настоящей
      публичной офертой и действующим законодательством РФ.
    </p>
    <p>
      4.9. Заказчик согласен с тем, что ПП при его использовании может автоматически собирать и
      передавать Исполнителю техническую информацию об использовании ПП. Указанная информация
      предназначена исключительно для изучения статистики использования ПП и возникающих при этом
      проблем для проведения в последующем работ по улучшению ПП и качества обслуживания Заказчика.
      В передаваемые данные не включаются данные, вводимые Заказчиком при использовании ПП и вообще
      никакие персональные или любые другие конфиденциальные данные.
    </p>
    <p>
      4.10. Услуги по технической поддержке новой версии ПП продолжают оказываться на условиях,
      предусмотренных настоящей публичной офертой.
    </p>
    <p>
      4.11. Исполнитель не гарантирует выпуск новых версий ПП в период действия технической
      поддержки.
    </p>
    <br />
    <h5>5. ВЗАИМОДЕЙСТВИЕ ПП С ВНЕШНИМИ ИНФОРМАЦИОННЫМИ СИСТЕМАМИ</h5>
    <br />
    <p>
      5.1. В целях повышения удобства использования ПП может взаимодействовать с внешними
      информационными системами посредством сети Интернет.
    </p>
    <p>
      5.2. Под информационной системой в соответствии с Федеральным законом от 27.07.2006 N 149-ФЗ
      (ред. от 19.07.2018) «Об информации, информационных технологиях и о защите информации»
      понимается совокупность информации, содержащейся в базах данных, информационных технологий и
      технических средств с помощью которых осуществляется сбор, хранение, обработка и
      предоставление указанной информации.
    </p>
    <p>
      5.3. Использование взаимодействия ПП с внешними информационными системами осуществляется по
      усмотрению пользователя ПП. Заказчик понимает, что использование ПП по назначению возможно без
      взаимодействия с внешними информационными системами и, что в рамках настоящей публичной оферты
      претензии по качеству функционирования внешних информационных систем не принимаются.
    </p>
    <p>
      5.4. Взаимодействие ПП с внешними информационными системами осуществляется в соответствии с
      порядком эксплуатации этих информационных систем, действующим на момент выпуска версии ПП, в
      которой было реализовано данное взаимодействие.
    </p>
    <br />
    <h5>6. ПОРЯДОК ОФОРМЛЕНИЯ ЗАЯВКИ НА ТЕХНИЧЕСКУЮ ПОДДЕРЖКУ</h5>
    <br />
    <p>
      6.1. Заявка на техническую поддержку может быть оформлена через чат-поддержку, расположенную
      на веб-сайте <a href="https://360.galaxy-camellia.com/">https://360.galaxy-camellia.com/</a>.
    </p>
    <p>
      6.2. Оформляя заявку, Заказчик тем самым подтверждает, что он согласен со всеми положениями
      настоящей публичной оферты и обязуется предоставить Исполнителю информацию, указанную в п.
      6.3. настоящей публичной оферты.
    </p>
    <p>6.3. Для принятия заявки Исполнителем, Заказчик обязан предоставить следующую информацию:</p>
    <p>
      — номер и дату Лицензионного договора, заключенного с Исполнителем;<br />
      — ФИО контактного лица;<br />
      — контактный телефон;<br />
      — электронный адрес.
    </p>
    <p>
      6.4. Оформляя заявку, Заказчик подтверждает согласие и разрешает Исполнителю обрабатывать свои
      персональные данные, предоставленные при оформлении заявки, а также осознает, что эти данные
      собираются и обрабатываются в целях исполнения Договора.
    </p>
    <p>
      6.5. Заказчик подтверждает свое согласие с тем, что Исполнитель или уполномоченные им лица
      вправе взаимодействовать с Заказчиком (осуществлять рекламную рассылку с информацией о
      реализуемой Исполнителем продукции, уведомлять о проводимых Исполнителем акциях) с помощью
      различных средств связи, включая, но, не ограничиваясь: электронная почта, телефон, используя
      данные, предоставленные Заказчиком при оформлении заявки.
    </p>
    <br />
    <h5>7. ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ</h5>
    <br />
    <p>7.1. Исполнитель обязан:</p>
    <p>
      7.1.1. Оказывать Заказчику услуги надлежащего качества, исходя из согласованных Сторонами
      условий соответствующего Приложения, в соответствии с действующим законодательством.
    </p>
    <p>
      7.1.2. Оказывать услуги по технической поддержке ПП с момента акцепта настоящей Публичной
      оферты.
    </p>
    <br />
    <h5>8. ПРАВА И ОБЯЗАННОСТИ ЗАКАЗЧИКА</h5>
    <br />
    <p>8.1. Заказчик обязан:</p>
    <p>8.1.1. Соблюдать условия настоящего Договора и законодательство Российской Федерации.</p>
    <p>
      8.1.2. Предоставлять полную и достоверную информацию, необходимую для осуществления сторонами
      условий настоящего Договора.
    </p>
    <br />
    <h5>9. ОТВЕТСТВЕННОСТЬ СТОРОН</h5>
    <br />
    <p>
      9.1. За неисполнение или ненадлежащее исполнение своих обязательств по Договору стороны несут
      ответственность согласно действующему законодательству РФ.
    </p>
    <p>
      9.2. Исполнитель не несет ответственность за качество оказываемых услуг в случаях, если это
      вызвано неудовлетворительной работой используемых Заказчиком аппаратных и программных средств,
      сбоями вычислительной техники, ошибками Заказчика при работе с ПП или нарушением Заказчиком
      технических требований и требований документации ПП.
    </p>
    <br />
    <h5>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h5>
    <br />
    <p>
      10.1. Права и обязанности по Договору не могут переходить к третьим лицам без письменного
      согласия Исполнителя.
    </p>
    <p>
      10.2. Все споры и разногласия, возникшие в результате исполнения Договора, стороны будут
      пытаться урегулировать путем переговоров. Срок ответа на претензию 10 (Десять) рабочих дней.
      При неурегулировании Сторонами возникших разногласий спор разрешается в судебном порядке в
      соответствии с действующим законодательством РФ.
    </p>
    <br />
    <h5>11. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</h5>
    <br />
    <p>
      Наименование: ООО «МАРФАТЕХ», <br />
      Место нахождения/почтовый адрес: 115191, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ДАНИЛОВСКИЙ,
      УЛ БОЛЬШАЯ ТУЛЬСКАЯ, Д. 11, ПОМЕЩ. XIII, КОМ. 1<br />
      ОГРН 1217700291820, ИНН 7726478833, КПП 772601001<br />
      Электронная почта: <a href="mailto:hello@marfa-tech.com">hello@marfa-tech.com</a><br />
      Счет : 40702810002860011520<br />
      в АО «АЛЬФА-БАНК»<br />
      Кор/счет : 301018102000000000593<br />
      БИК : 044525593
    </p>
  </div>
</template>

<script>
export default {
  name: 'LegalUserAgreement',
};
</script>