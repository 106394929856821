<template>
  <div class="Emails">
    <div class="ItemsList">
      <TemplateListItem v-for="(item, index) in list" :key="`Email-${index}`" :item="item">
        <template #title>
          {{ item.templateType }}
        </template>
        <template #actions>
          <TemplateListActions
            @edit="onEditTemplateItem(item.id)"
            @reset="onShowConfirmAction(item.id)"
          />
        </template>
        <template #default>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="item.text"></div>
          <!--eslint-enable-->
        </template>
      </TemplateListItem>
    </div>
    <TemplatesFormEditDefault
      :id="nameModalOfEditTemplateItem"
      :title="itemTemplateModel.templateType"
      :item-model="itemTemplateModel"
      @save="onSaveTemplateItem"
      @cancel="onCloseTemplateModal"
    />
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.reset')"
      @confirm="onConfirmModalAction"
      @cancel="onHideConfirmAction"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { TemplateTab } from '@/mixins/TemplateTab';
import AppModalConfirm from '@/components/App/AppModalConfirm';
import TemplateListItem from '@/views/Estimation/Templates/_components/List/TemplateListItem';
import TemplatesFormEditDefault from '@/views/Estimation/Templates/_components/Form/TemplatesFormEditDefault';
import TemplateListActions from '@/views/Estimation/Templates/_components/List/TemplateListActions';

import { ObjectFunctions } from '@/services/Helpers';

export default {
  name: 'TemplatesTabEmails',
  components: { AppModalConfirm, TemplateListActions, TemplateListItem, TemplatesFormEditDefault },
  mixins: [TemplateTab],
  props: {
    templateModel: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    nameModalOfEditTemplateItem: 'modal-edit-template-email',
    nameModalOfConfirmAction: 'modal-confirm-reset-template-email',
    targetResetTemplate: null,
    itemTemplateModel: {},
  }),
  computed: {
    ...mapState('Templates', ['emails']),
  },
  watch: {
    emails() {
      this.updateLocalList();
    },
  },
  created() {
    this.updateLocalList();
  },
  methods: {
    ...mapActions({
      getList: 'Templates/getTemplatesEmailList',
      updateItem: 'Templates/updateTemplatesEmailItem',
      resetItem: 'Templates/resetTemplatesEmailItem',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.emails);
    },
  },
};
</script>
