// isIgnorePermissions: true, - Добавляем, чтобы игнорировать права доступов и всегда выводить пункт меню

export const NAVIGATION_TOP = [
  {
    page: 'structure',
    route: 'structure',
    permissions: ['directory_employee_getemployeelist__invoke'],
  },
  {
    page: 'estimation',
    route: 'estimation',
    isIgnorePermissions: true,
    permissions: [],
    children: [
      {
        page: 'journal',
        route: 'home',
        icon: 'three-columns',
        permissions: ['questionary_questionary_list'],
      },
      {
        page: 'archive',
        route: 'questionary:archive',
        icon: 'trash',
        permissions: ['questionary_questionary_list'],
      },
      {
        page: 'testing',
        route: 'questionary:testing',
        icon: 'copy-2',
        permissions: ['questionary_questionaryemployeelist__invoke'],
      },
      {
        page: 'reports',
        route: 'reports',
        icon: 'file',
        permissions: ['report_report_list'],
      },
      {
        page: 'directory',
        route: 'dictionary',
        icon: 'book-open',
        permissions: [
          'directory_competence_competence_list',
          'directory_position_position_list',
          'directory_employee_employee_list',
          'directory_subdivision_subdivision_list',
        ],
      },
      {
        page: 'templates',
        route: 'templates',
        icon: 'layers-three',
        permissions: ['template_email_email_list', 'template_password_password_list'],
      },
      {
        page: 'users',
        route: 'users',
        icon: 'users',
        permissions: ['user_user_list'],
      },
      {
        page: 'settings',
        route: 'settings',
        icon: 'settings',
        permissions: ['resetpassword_resetcurrentuserpassword__invoke'],
      },
    ],
  },
];

export const NAVIGATION_BOTTOM = [
  {
    page: 'exit',
    route: false,
    icon: 'log-out',
    callback: 'logout',
    isIgnorePermissions: true,
  },
];

export const FOOTER_NAVIGATION = [
  {
    page: 'terms',
    route: 'legal:terms',
  },
  {
    page: 'privacy',
    route: 'legal:privacy',
  },
  {
    page: 'documents',
    route: 'legal:documents',
  },
  {
    page: 'security',
    route: 'legal:security',
  },
  {
    page: 'offer',
    route: 'legal:offer',
  },
];
