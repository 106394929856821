<template>
  <svg
    class="Icon"
    :class="[className, { 'Icon--no-fill': noFill, 'Icon--no-stroke': noStroke }]"
    :width="width"
    :height="height"
  >
    <use :xlink:href="require(`@/assets/Images/${source}.svg`) + '#' + name"></use>
  </svg>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    source: {
      type: String,
      required: false,
      default: () => 'sprite',
    },
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '18',
    },
    height: {
      type: String,
      required: false,
      default: '18',
    },
    noFill: {
      type: Boolean,
      required: false,
      default: false,
    },
    noStroke: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>