<template>
  <div class="Positions">
    <DictionaryListHead
      type="positions"
      @create="onCreateDictionaryItem"
      @filter="onFilterDictionaryItems"
    ></DictionaryListHead>
    <div class="ItemsList">
      <DictionaryListItem v-for="item in list" :key="item.id">
        <template #title>
          {{ item.name }}
        </template>
        <template #short-description>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="item.description"></div>
          <!--eslint-enable-->
        </template>
        <template v-if="item.competenceList" #full-description>
          <DictionaryListCompetencies :items="item.competenceList" />
        </template>
        <template #actions>
          <DictionaryListActions
            type="positions"
            @edit="editDictionaryItem(item.id)"
            @delete="showConfirmAction(item.id)"
          />
        </template>
      </DictionaryListItem>
    </div>
    <DictionaryFormCreatePositions
      :id="nameModalOfAddDictionaryItem"
      :action-type="updateOrSave"
      :item-model="itemDictionaryModel"
      :option-items="formCompetencies"
      @save="saveDictionaryItem"
      @update="updateDictionaryItem"
      @cancel="closeDictionaryModal"
    />
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.delete')"
      @confirm="confirmModalAction"
      @cancel="hideConfirmAction"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import { mapState, mapActions } from 'vuex';
import { DictionaryTab } from '@/mixins/DictionaryTab';
import DictionaryListHead from '@/views/Estimation/Dictionary/_components/List/DictionaryListHead';
import DictionaryListItem from '@/views/Estimation/Dictionary/_components/List/DictionaryListItem';
import DictionaryListCompetencies from '@/views/Estimation/Dictionary/_components/List/DictionaryListCompetencies';
import DictionaryListActions from '@/views/Estimation/Dictionary/_components/List/DictionaryListActions';
import DictionaryFormCreatePositions from '@/views/Estimation/Dictionary/_components/Form/DictionaryFormCreatePositions';
import AppModalConfirm from '@/components/App/AppModalConfirm';

export default {
  name: 'DictionaryTabPositions',
  components: {
    DictionaryListHead,
    DictionaryListItem,
    DictionaryListActions,
    DictionaryListCompetencies,
    DictionaryFormCreatePositions,
    AppModalConfirm,
  },
  mixins: [DictionaryTab],
  data: () => ({
    nameModalOfAddDictionaryItem: 'modal-add-position',
    nameModalOfConfirmAction: 'modal-confirm-position',
    targetDeleteDictionaryItem: null,
    itemDictionaryModel: {},
  }),
  computed: {
    ...mapState('Dictionary', ['positions', 'competencies']),
    dictionaryModel() {
      return this.models.positionsModel;
    },
    formCompetencies() {
      return this.competencies.map((item) => {
        // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
        return { ...item, name: item?.nameText?.[this.$i18n.locale] ?? item?.name ?? '' };
      });
    },
  },
  watch: {
    positions() {
      this.updateLocalList();
    },
  },
  created() {
    this.updateLocalList();
  },
  mounted() {
    Promise.allSettled([this.getList(), this.getListCompetencies()]).then(() => {
      this.itemDictionaryModel = this.dictionaryModel();
    });
  },
  methods: {
    ...mapActions({
      getList: 'Dictionary/getPositionsList',
      createItem: 'Dictionary/createPositionsItem',
      updateItem: 'Dictionary/updatePositionsItem',
      deleteItem: 'Dictionary/deletePositionsItem',
      filterList: 'Dictionary/getFilterPositionsList',
      getListCompetencies: 'Dictionary/getCompetenciesList',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.positions);
    },
  },
};
</script>
