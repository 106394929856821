<template>
  <div class="AdminUsers">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.users.sub-title') }}</template>
    </AppPageHead>
    <BaseTabs>
      <UsersTabList :user-model="userModel" />
    </BaseTabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BaseTabs from '@/components/Base/BaseTabs';
import UsersTabList from '@/views/Estimation/Users/Tab/UsersTabList';
import AppPageHead from '@/components/App/AppPageHead';

import { Users } from '@/store/Models';

export default {
  name: 'AdminUsers',
  components: { BaseTabs, UsersTabList, AppPageHead },
  data: () => ({
    userModel: Users.modelUsers,
  }),
  mounted() {
    Promise.all([this.getUsersList(), this.getUserRolesList()]).then(() => {});
  },
  methods: {
    ...mapActions('Users', ['getUsersList', 'getUserRolesList']),
  },
};
</script>
