<template>
  <b-modal
    :id="id"
    size="xl"
    body-class="BaseModal BaseModal--QuestionaryReport"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
    @hide="resetData"
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.report.modal') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('close')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="QuestionaryReport">
        <div class="QuestionaryReport-Info">
          <div class="QuestionaryReport-Info-Top">
            <div class="QuestionaryReport-Info-Name">
              {{ reportName }}
            </div>
            <span class="Button QuestionaryReport-Info-Button" @click="onExportReport">
              <BaseIcon class="me-1" name="arrow-circle-broken-down" />
              <span> {{ $t('labels.download-pdf') }} </span>
            </span>
          </div>
          <div class="QuestionaryReport-Info-Bottom">
            <div class="QuestionaryReport-Info-Block QuestionaryReport-Info-Method">
              <div class="LabelName">{{ $t('admin.pages.journal.report.method') }}:</div>
              <div class="DataValue">
                <QuestionaryMethod :method="reportMethod" />
              </div>
            </div>
            <div class="QuestionaryReport-Info-Block QuestionaryReport-Info-Period">
              <div class="LabelName">{{ $t('admin.pages.journal.report.period') }}:</div>
              <div class="DataValue">
                {{ reportPeriod }}
              </div>
            </div>
          </div>
        </div>
        <div class="QuestionaryReport-Graph">
          <div class="QuestionaryReport-Participants">
            <div class="QuestionaryReport-Participants-Head">
              {{ $t('admin.pages.journal.report.participants') }}
            </div>
            <div class="QuestionaryReport-Participants-Groups ScrollBar--hide">
              <div
                v-for="(participant, index) in reportParticipants"
                :key="`QuestionaryReport-Participants-Group-${index}`"
                class="QuestionaryReport-Participants-Group"
              >
                <div class="QuestionaryReport-Participant-Name">
                  {{ participant.name }} ({{ participant.passed }}/{{ participant.count }})
                  {{ participant.progress.length ? ':' : '' }}
                </div>
                <div v-if="participant.progress.length" class="QuestionaryReport-Participant-List">
                  <div
                    v-for="(item, idx) in participant.progress"
                    :key="`QuestionaryReport-Participant-List-Item-${idx}`"
                    class="QuestionaryReport-Participant-List-Item"
                  >
                    <span
                      :class="[
                        'QuestionaryReport-Participant-List-Item-Avatar',
                        `QuestionaryReport-Participant-List-Item-Avatar--${participant.key}`,
                      ]"
                    >
                      {{ [item.surname, item.firstname] | firstLetters }}
                    </span>
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="QuestionaryReport-Canvas">
            <div class="QuestionaryReport-Canvas-Head">
              <div class="Left">{{ $t('admin.pages.journal.report.title') }}</div>
              <div id="popover-target-info" class="Right">
                <BaseIcon width="20" height="20" name="help-circle" />
                {{ $t('admin.pages.journal.report.info') }}
              </div>
              <b-popover custom-class="Popover" target="popover-target-info" triggers="hover">
                <div class="Numbers">
                  <span class="Number">
                    <span class="Number-Value">1</span> {{ $t(`testing.process.answers.value1`) }}
                  </span>
                  <span class="Number">
                    <span class="Number-Value">2</span> {{ $t(`testing.process.answers.value2`) }}
                  </span>
                  <span class="Number">
                    <span class="Number-Value">3</span> {{ $t(`testing.process.answers.value3`) }}
                  </span>
                  <span class="Number">
                    <span class="Number-Value">4</span> {{ $t(`testing.process.answers.value4`) }}
                  </span>
                  <span class="Number">
                    <span class="Number-Value">5</span> {{ $t(`testing.process.answers.value5`) }}
                  </span>
                </div>
              </b-popover>
            </div>
            <div class="QuestionaryReport-Canvas-Wrap">
              <div class="QuestionaryReport-Canvas-Labels ScrollBar--hide">
                <div class="ChartLabels">
                  <span
                    :class="[
                      'ChartLabel',
                      `ChartLabel--all`,
                      { 'ChartLabel--Active': checkedParticipants['all'] },
                    ]"
                    @click="onCheckChartLabel('all')"
                  >
                    {{ $t(`admin.pages.journal.report.all`) }}
                  </span>
                  <span
                    v-for="(participant, index) in labelsParticipants"
                    :key="`participant-label-${index}`"
                    :class="[
                      'ChartLabel',
                      { 'ChartLabel--Active': checkedParticipants[participant.key] },
                    ]"
                    @click="onCheckChartLabel(participant.key)"
                  >
                    {{ participant.name }}
                    <span
                      :class="['ChartLabel-Icon', `ChartLabel-Icon--${participant.key}`]"
                    ></span>
                  </span>
                </div>
              </div>
              <QuestionaryChar
                class="QuestionaryReport-Canvas-Data"
                :chartdata="diagramData"
                :options="diagramOptions"
                :plugins="diagramPlugins"
              />
            </div>
          </div>
        </div>
        <div class="QuestionaryReport-Table">
          <div class="QuestionaryReport-Table-Wrap">
            <div class="Table">
              <div class="Table-Head">
                <div
                  class="Table-Row"
                  :class="[`Table-Row-Count${reportParticipantsWhoHasMarks.length + 1}`]"
                >
                  <div
                    v-for="(participant, index) in reportParticipantsWhoHasMarks"
                    :key="`participant-col-${index}`"
                    class="Table-Col"
                  >
                    {{ participant.name }}
                  </div>
                  <div class="Table-Col">{{ $t('admin.pages.journal.report.average') }}</div>
                </div>
              </div>
              <div class="Table-Body">
                <div
                  v-for="(row, indexRow) in reportTableData"
                  :key="`Table-Section-${indexRow}`"
                  class="Table-Section"
                >
                  <div class="Table-SectionName">
                    <span
                      :id="`popover-target-${reportId}-${indexRow}`"
                      class="Table-SectionName-Label"
                    >
                      {{ row.name }}
                    </span>
                    <b-popover
                      custom-class="Popover"
                      :target="`popover-target-${reportId}-${indexRow}`"
                      triggers="hover"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <div v-html="row.description"></div>
                      <!--eslint-enable-->
                    </b-popover>
                  </div>
                  <div
                    class="Table-Row"
                    :class="[`Table-Row-Count${reportParticipantsWhoHasMarks.length + 1}`]"
                  >
                    <div
                      v-for="(participant, index) in row.participantList"
                      :key="`participant-row-col-${index}`"
                      class="Table-Col"
                    >
                      {{ participant.value }}
                    </div>
                    <div class="Table-Col">{{ row.totalMark }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="QuestionaryReport-Results">
          <div v-if="reportCompetenceMinMarkList.length" class="QuestionaryReport-Min">
            <div class="Title">
              <img src="@/assets/Images/Img/top.png" alt="" />
              {{ $t('admin.pages.journal.report.max') }}
            </div>
            <div class="Data">
              <div
                v-for="(competence, index) in reportCompetenceMinMarkList"
                :key="`QuestionaryReport-Min-${index}`"
                class="Data-Item"
              >
                <span class="Name">{{ competence.name }}:</span>
                <div class="Data-Item-Wrap">
                  <div
                    v-for="(item, i) in competence.data"
                    :key="`it-${index}-${i}`"
                    class="MarkItem"
                  >
                    <span class="MarkItem-Value">{{ item.value }}</span>
                    <span class="MarkItem-Name">
                      {{ item.name }}{{ i + 1 !== competence.data.length ? ',' : '' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="reportZoneList.length" class="QuestionaryReport-Zone">
            <div class="Title">
              <img src="@/assets/Images/Img/zone.png" alt="" />
              {{ $t('admin.pages.journal.report.zone') }}
            </div>
            <div class="Data">
              <div
                v-for="(zone, index) in reportZoneList"
                :key="`QuestionaryReport-Zone-${index}`"
                class="MarkItem"
              >
                <span class="MarkItem-Value">{{ zone.value }}</span>
                <span class="MarkItem-Name">{{ zone.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="reportHiddenPossibilitiesList.length" class="QuestionaryReport-Hidden">
            <div class="Title">
              <img src="@/assets/Images/Img/hide.png" alt="" />
              {{ $t('admin.pages.journal.report.hide-employee') }}
            </div>
            <div class="Desc">
              {{ $t('admin.pages.journal.report.hide-desc') }}
            </div>
            <div class="TableWrap">
              <div class="TableDesc">
                <div class="TableDesc-Row Head">
                  <div class="TableDesc-Left">
                    {{ $t('admin.pages.journal.report.name-competence') }}
                  </div>
                  <div class="TableDesc-Right">
                    <div class="TableDesc-Col">
                      {{ $t('admin.pages.journal.report.self') }}
                    </div>
                    <div class="TableDesc-Col">
                      {{ $t('admin.pages.journal.report.other') }}
                    </div>
                  </div>
                </div>
                <div
                  v-for="(possibilities, index) in reportHiddenPossibilitiesList"
                  :key="`TableDesc-Row-Hidden-${index}`"
                  class="TableDesc-Row"
                >
                  <div class="TableDesc-Left">
                    {{ possibilities.name }}
                  </div>
                  <div class="TableDesc-Right">
                    <div class="TableDesc-Col">
                      <span class="Value">{{ possibilities.selfMark }}</span>
                    </div>
                    <div class="TableDesc-Col">
                      <span class="Value">{{ possibilities.otherParticipantMark }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="reportBlindZoneList.length" class="QuestionaryReport-Blind">
            <div class="Title">
              <img src="@/assets/Images/Img/blind.png" alt="" />
              {{ $t('admin.pages.journal.report.blind-employee') }}
            </div>
            <div class="Desc">
              {{ $t('admin.pages.journal.report.blind-desc') }}
            </div>
            <div class="TableWrap">
              <div class="TableDesc">
                <div class="TableDesc-Row Head">
                  <div class="TableDesc-Left">
                    {{ $t('admin.pages.journal.report.name-competence') }}
                  </div>
                  <div class="TableDesc-Right">
                    <div class="TableDesc-Col">
                      {{ $t('admin.pages.journal.report.self') }}
                    </div>
                    <div class="TableDesc-Col">
                      {{ $t('admin.pages.journal.report.other') }}
                    </div>
                  </div>
                </div>
                <div
                  v-for="(blindZone, index) in reportBlindZoneList"
                  :key="`TableDesc-Row-Blind-${index}`"
                  class="TableDesc-Row"
                >
                  <div class="TableDesc-Left">
                    {{ blindZone.name }}
                  </div>
                  <div class="TableDesc-Right">
                    <div class="TableDesc-Col">
                      <span class="Value">{{ blindZone.selfMark }}</span>
                    </div>
                    <div class="TableDesc-Col">
                      <span class="Value">{{ blindZone.otherParticipantMark }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';

import QuestionaryMethod from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryMethod';
import QuestionaryChar from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryChar';

import { exportReport } from '@/mixins/DownloadFiles';

import { vmoment, ObjectFunctions } from '@/services/Helpers';
import { firstLetters } from '@/services/Filters';

const participants = {
  all: true,
  colleague: true,
  customer: true,
  employee: true,
  subordinate: true,
  supervisor: true,
};

export default {
  name: 'QuestionaryReport',
  components: {
    BaseIcon,
    BaseModalWrap,
    QuestionaryMethod,
    QuestionaryChar,
  },
  filters: { firstLetters },
  mixins: [exportReport],
  props: {
    id: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    diagramData: null,
    diagramOptions: null,
    diagramPlugins: null,
    participants: [],
    checkedParticipants: ObjectFunctions.deepClone(participants),
  }),
  computed: {
    ...mapState('Dictionary', ['employees']),
    ...mapGetters('Settings', ['isMobile']),
    ...mapGetters('Directories', ['participantTypeCollection']),
    reportId() {
      return this.report?.id || '';
    },
    reportName() {
      return this.report?.subject || '-';
    },
    reportMethod() {
      return this.report?.method || '-';
    },
    reportPeriod() {
      return `${vmoment(this.report.dateStart).format('D MMM YYYY')} - ${vmoment(
        this.report.dateEnd,
      ).format('D MMM YYYY')}`;
    },
    reportParticipants() {
      return typeof this.report?.participantTypeCount === 'object'
        ? Object.keys(this.report.participantTypeCount)
            .sort()
            .reduce((acc, i) => {
              if (i !== 'totalCount') {
                acc.push({
                  key: i,
                  count: this.report.participantTypeCount?.[i],
                  passed: this.report?.reportProgressList?.[i]?.passedCount,
                  name:
                    i === 'employee'
                      ? this.$t('participants.self')
                      : this.participantTypeCollection?.[i],
                  progress:
                    this.report?.participantProgressList
                      .filter((participant) => participant.type === i)
                      .map((item) => {
                        const [surname, firstname] = item.name.split(' ');
                        item = { ...item, firstname, surname };
                        return item;
                      }) || [],
                });
              }
              return acc;
            }, [])
        : [];
    },
    reportParticipantsWhoHasMarks() {
      return this.reportParticipants
        ? this.reportParticipants.filter((participant) =>
            this.participants.includes(participant.key),
          )
        : [];
    },
    reportParticipantsCount() {
      return this.report?.participantTypeCount?.totalCount || '-';
    },
    reportTableData() {
      return typeof this.report?.competenceMarkList === 'object'
        ? Object.keys(this.report.competenceMarkList).reduce((data, item) => {
            data.push({
              description: this.report.competenceMarkList?.[item]?.description,
              name: this.report.competenceMarkList?.[item]?.name,
              totalMark: this.report.competenceMarkList?.[item]?.totalMark,
              participantList:
                typeof this.report.competenceMarkList?.[item]?.participantList === 'object'
                  ? Object.keys(this.report.competenceMarkList?.[item].participantList)
                      .sort()
                      .reduce((acc, i) => {
                        if (i !== 'totalCount') {
                          if (!this.participants.includes(i)) {
                            this.participants.push(i);
                          }
                          acc.push({
                            key: i,
                            value: this.report.competenceMarkList[item].participantList?.[i],
                            name: this.participantTypeCollection?.[i],
                          });
                        }
                        return acc;
                      }, [])
                  : [],
            });
            return data;
          }, [])
        : [];
    },
    reportCompetenceMinMarkList() {
      return typeof this.report?.competenceMinMarkList === 'object'
        ? Object.keys(this.report.competenceMinMarkList)
            .sort()
            .reduce((acc, i) => {
              acc.push({
                key: i,
                data: this.report.competenceMinMarkList?.[i],
                name:
                  i === 'employee'
                    ? this.$t('participants.self')
                    : this.participantTypeCollection?.[i],
              });
              return acc;
            }, [])
        : [];
    },
    reportZoneList() {
      return this.report?.developmentZoneList || [];
    },
    reportHiddenPossibilitiesList() {
      return typeof this.report?.hiddenPossibilitiesList === 'object'
        ? Object.keys(this.report.hiddenPossibilitiesList)
            .sort()
            .reduce((acc, i) => {
              acc.push({ ...(this.report.hiddenPossibilitiesList?.[i] || {}) });
              return acc;
            }, [])
        : [];
    },
    reportBlindZoneList() {
      return typeof this.report?.blindZoneList === 'object'
        ? Object.keys(this.report.blindZoneList)
            .sort()
            .reduce((acc, i) => {
              acc.push({ ...(this.report.blindZoneList?.[i] || {}) });
              return acc;
            }, [])
        : [];
    },
    participantsTypeDataSet() {
      const data = {};
      const chartData = {
        colleague: {
          label: this.$t('participants.colleague'),
          data: [],
          fill: true,
          backgroundColor: 'rgba(7, 97, 232, 0.15)',
          borderColor: 'rgba(7, 97, 232)',
          pointBackgroundColor: 'rgba(7, 97, 232)',
          pointBorderColor: 'rgba(255,255,255,0.2)',
          pointHoverBackgroundColor: 'rgba(255,255,255,0.2)',
          pointHoverBorderColor: 'rgba(7, 97, 232)',
        },
        customer: {
          label: this.$t('participants.customer'),
          data: [],
          fill: true,
          backgroundColor: 'rgba(102, 4, 200, 0.15)',
          borderColor: 'rgba(102, 4, 200)',
          pointBackgroundColor: 'rgba(102, 4, 200)',
          pointBorderColor: 'rgba(255,255,255,0.2)',
          pointHoverBackgroundColor: 'rgba(255,255,255,0.2)',
          pointHoverBorderColor: 'rgba(102, 4, 200)',
        },
        employee: {
          label: this.$t('participants.employee'),
          data: [],
          fill: true,
          backgroundColor: 'rgba(255, 168, 0, 0.15)',
          borderColor: 'rgba(255, 168, 0)',
          pointBackgroundColor: 'rgba(255, 168, 0)',
          pointBorderColor: 'rgba(255,255,255,0.2)',
          pointHoverBackgroundColor: 'rgba(255,255,255,0.2)',
          pointHoverBorderColor: 'rgba(255, 168, 0)',
        },
        subordinate: {
          label: this.$t('participants.subordinate'),
          data: [],
          fill: true,
          backgroundColor: 'rgba(0, 152, 106, 0.15)',
          borderColor: 'rgba(0, 152, 106)',
          pointBackgroundColor: 'rgba(0, 152, 106)',
          pointBorderColor: 'rgba(255,255,255,0.2)',
          pointHoverBackgroundColor: 'rgba(255,255,255,0.2)',
          pointHoverBorderColor: 'rgba(0, 152, 106)',
        },
        supervisor: {
          label: this.$t('participants.supervisor'),
          data: [],
          fill: true,
          backgroundColor: 'rgba(255, 75, 85, 0.15)',
          borderColor: 'rgb(255,75,85)',
          pointBackgroundColor: 'rgb(255,75,85)',
          pointBorderColor: 'rgba(255,255,255,0.2)',
          pointHoverBackgroundColor: 'rgba(255,255,255,0.2)',
          pointHoverBorderColor: 'rgb(255,75,85)',
        },
      };

      Object.keys(this.checkedParticipants).map((key) => {
        if (this.checkedParticipants[key]) {
          data[key] = chartData[key];
        }
      });

      return data;
    },
    labelsParticipants() {
      return this.reportParticipants.filter((i) => i.passed > 0);
    },
  },
  watch: {
    report: {
      immediate: true,
      handler() {
        this.initRadarChart();
      },
    },
    checkedParticipants: {
      deep: true,
      handler() {
        this.initRadarChart();
      },
    },
  },
  methods: {
    ...mapActions({
      exportHandler: 'Reports/exportReportByQuestionaryIdAndEmployeeID',
    }),
    initRadarChart() {
      // TODO: Сделано было на гипер скорую руку. Нужно оформить прилично
      const participantsTypeDataSet = this.participantsTypeDataSet;
      const competenciesLabels = this.reportTableData.map((item) => item.name);
      const participants = Object.keys(this.report?.participantTypeCount || {})
        .sort()
        .reduce((acc, i) => {
          if (i !== 'totalCount' && this.participants.includes(i)) {
            if (!acc[i]) {
              acc[i] = {};
              acc[i]['name'] = i;
              acc[i]['values'] = [];
            }
          }
          return acc;
        }, {});
      Object.values(this.report?.competenceMarkList || {}).map((competence) => {
        for (const [key, value] of Object.entries(competence.participantList)) {
          participants[key].values.push(value);
        }
      });
      const DATASETS = [];
      for (const [key, participant] of Object.entries(participants)) {
        if (participantsTypeDataSet[key]) {
          const dataset = participantsTypeDataSet[key];
          dataset.data = participant?.values || [];
          DATASETS.push(dataset);
        }
      }
      this.diagramData = {
        labels: competenciesLabels || [],
        datasets: DATASETS || [],
      };
      this.diagramOptions = {
        animation: {
          duration: 0,
        },
        scale: {
          ticks: {
            display: false,
            min: 0,
          },
          gridLines: {
            display: false,
          },
          pointLabels: {
            fontSize: !this.isMobile ? 12 : 10,
            fontStyle: 'bold',
          },
        },
        elements: {
          line: {
            borderWidth: 1,
          },
          point: {
            radius: 4,
          },
        },
        legend: {
          display: false,
        },
        responsive: !this.isMobile,
        maintainAspectRatio: this.isMobile,
      };
      this.diagramPlugins = [
        {
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) {
              if (/ /.test(e)) {
                a[i] = e.split(/ /);
              }
            });
          },
        },
      ];
    },
    onExportReport() {
      // Метод дергает exportHandler
      this.exportReport(this.report);
    },
    onCheckChartLabel(key) {
      if (this.checkedParticipants[key] !== undefined) {
        if (key === 'all') {
          this.checkedParticipants[key] = !this.checkedParticipants[key];
          Object.keys(this.checkedParticipants).map((keyParticipant) => {
            if (keyParticipant !== key) {
              this.checkedParticipants[keyParticipant] = !!this.checkedParticipants[key];
            }
          });
        } else {
          this.checkedParticipants[key] = !this.checkedParticipants[key];
          if (!this.checkedParticipants[key]) {
            this.checkedParticipants['all'] = false;
          } else {
            const isAll = !Object.keys(this.checkedParticipants).some((keyParticipant) => {
              if (keyParticipant != 'all') {
                return !this.checkedParticipants[keyParticipant];
              }
            });
            if (isAll) {
              this.checkedParticipants['all'] = true;
            }
          }
        }
      }
    },
    resetData() {
      this.checkedParticipants = ObjectFunctions.deepClone(participants);
    },
  },
};
</script>
