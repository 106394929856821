export const ENDPOINTS_USERS = {
  USERS_GET_INFO: '/user/me',
  USERS_GET_LIST: '/user/list',
  USERS_GET_ROLES: '/user/roles',
  USERS_GET_ROUTES: '/user/routes',
  USERS_CREATE_ITEM: '/user/create',
  USERS_UPDATE_ITEM: '/user/{id}',
  USERS_DELETE_ITEM: '/user/{id}',
  USERS_SWITCH_BRAND: '/user/switchBrand',
  USERS_SWITCH_LOCALE: '/user/switchLocale/{id}',
  USERS_GET_PROFILE: '/employee/profile/{id}',
  USERS_GET_GENERAL_INFO: '/employee/company/{id}',
  USERS_GET_REPORTS: '/report/employee/{id}',
  USERS_UPDATE_ANY_PROFILE: '/employee/profile/{id}',
  USERS_UPDATE_PROFILE: '/employee/updateProfile',
  USERS_UPDATE_ANY_GENERAL: '/employee/company/{id}',
  USERS_UPDATE_GENERAL: '/employee/updateCompany',
};
