<template>
  <div v-if="!count" class="QuestionaryTypeSurname">
    <BaseSelect
      v-model="select"
      class="Select--WidthBorder SelectSurname"
      option-value="lastName"
      option-key="id"
      :items="employeesSelectList"
      :placeholder="$t('select.no-select')"
      clearable
      searchable
      :search-fields="['lastName', 'firstName']"
      @input="$emit('select-surname', select)"
    >
      <template #selected="{ item }"> {{ item.lastName }} {{ item.firstName }} </template>
      <template #item="{ item }">
        <ParticipantCard :item="item" />
      </template>
    </BaseSelect>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSelect from '@/components/Base/BaseSelect';
import ParticipantCard from '@/components/General/ParticipantCard';

export default {
  name: 'QuestionaryTypeSurname',
  components: { BaseSelect, ParticipantCard },
  props: {
    selectOption: {
      type: String,
      required: true,
    },
    count: {
      type: [String, Number],
      required: true,
    },
    excludeItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      select: this.selectOption,
    };
  },
  computed: {
    ...mapState('Dictionary', ['employees']),
    employeesSelectList() {
      return this.employees.filter((item) => !this.excludeItems.includes(item.id));
    },
  },
};
</script>