<template>
  <div class="DictionaryListItem">
    <div class="DictionaryListItem-Section">
      <div class="DictionaryListItem-Title">
        <slot name="title"></slot>
      </div>
      <div
        v-if="$scopedSlots['short-description'] || $scopedSlots['full-description']"
        class="DictionaryListItem-Description"
      >
        <div class="DictionaryListItem-Description--Short">
          <slot name="short-description"></slot>
        </div>
        <div class="DictionaryListItem-Description--Full">
          <slot name="full-description"></slot>
        </div>
      </div>
    </div>
    <div class="DictionaryListItem-Section">
      <div class="DictionaryListItem-Actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DictionaryListItem',
};
</script>