<script>
import { Radar } from 'vue-chartjs';

export default {
  name: 'QuestionaryChar',
  extends: Radar,
  props: {
    chartdata: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    chartdata: {
      deep: true,
      handler(v) {
        this.renderChart(v, this.options);
      },
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>