<template>
  <div class="ProfileBlock">
    <div v-if="$scopedSlots.head" class="ProfileBlock-Head">
      <div class="Head">
        <slot name="head" />
      </div>
    </div>
    <div class="Delimiter"></div>
    <div class="ProfileBlock-Content">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseProfileBlock',
};
</script>