<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--SelectCompetence"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.competence.title') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="SelectListData">
        <div class="SelectListData-Tabs">
          <BaseTabs>
            <template #labels>
              <span
                v-for="(tab, index) in tabs"
                :key="index"
                :class="['Label', { active: activeTab === tab.tab }]"
                @click="activeTab = tab.tab"
              >
                {{ tab.name }}
              </span>
            </template>
            <template #additional>
              <div class="SelectListData-Search">
                <b-form-input
                  id="form-search"
                  v-model="search"
                  class="Input Input--radius"
                  type="text"
                  autocomplete="off"
                  :placeholder="$t('placeholders.search')"
                  @input="checkSelectedFlags"
                ></b-form-input>
              </div>
              <div v-if="activeTab === 'all'" class="SelectListData-Actions">
                <div class="SelectListData-Actions--SelectAll">
                  <template v-if="options.length > 1">
                    <b-form-checkbox
                      v-model="selectedAll"
                      class="Checkbox Checkbox--OnlyLabel"
                      @change="onSelectAllCompetence"
                    >
                      {{ selectedAll ? $t('labels.unselect-all') : $t('labels.select-all') }}
                    </b-form-checkbox>
                  </template>
                </div>
                <div class="SelectListData-Actions--ShowSelected">
                  <b-form-checkbox
                    v-model="showSelected"
                    class="Checkbox Checkbox--OnlyLabel"
                    @change="onToggleShowSelected"
                  >
                    {{
                      showSelected
                        ? $t('labels.show-all')
                        : `${$t('labels.show-selected')} (${selected.length})`
                    }}
                  </b-form-checkbox>
                </div>
              </div>
            </template>
            <div class="SelectListData-Wrap">
              <div v-if="activeTab === 'all'" class="SelectListData-List">
                <b-form-checkbox-group
                  v-model="selected"
                  class="Checkbox Checkbox-List"
                  :options="options"
                  value-field="id"
                  text-field="name"
                  @input="checkSelectedFlags"
                ></b-form-checkbox-group>
              </div>
              <div v-if="activeTab === 'position'" class="SelectListData-List">
                <div
                  v-for="optionPosition in optionsByPositions"
                  :key="`position-${optionPosition.id}`"
                >
                  <div class="SelectListData-List-Tab">
                    <b-form-checkbox
                      v-model="selectedFlagsByPositions[optionPosition.id]"
                      class="Checkbox"
                      @change="
                        onSelectAllCompetenceByPosition($event, optionPosition.competenceList)
                      "
                    >
                    </b-form-checkbox>
                    <span v-b-toggle="`collapse-position-${optionPosition.id}`">
                      {{ optionPosition.name }}
                    </span>
                  </div>
                  <b-collapse
                    :id="`collapse-position-${optionPosition.id}`"
                    class="SelectListData-List-Data"
                  >
                    <b-form-group>
                      <template v-if="isShowSelectAll(optionPosition.competenceList)">
                        <div class="SelectListData-Actions--SelectAll"></div>
                      </template>
                      <b-form-checkbox-group
                        v-model="selected"
                        class="Checkbox Checkbox-List"
                        :options="optionPosition.competenceList"
                        value-field="id"
                        text-field="name"
                        @input="checkSelectedFlags"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-collapse>
                </div>
              </div>
            </div>
          </BaseTabs>
        </div>
      </div>
      <template #footer>
        <BaseActions
          :actions="modalActions"
          @save="$emit('save', selectedData)"
          @cancel="$emit('cancel')"
        />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';
import BaseTabs from '@/components/Base/BaseTabs';

// TODO: Адская верстка и чуток кода. Позже нужно почистить и переделать логику компонента выбора компетенций по должностям.

export default {
  name: 'QuestionarySelectEmployee',
  components: {
    BaseIcon,
    BaseModalWrap,
    BaseActions,
    BaseTabs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsByPositions: {
      type: Array,
      required: true,
    },
    checkedOptions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
    selected: [],
    selectedAll: false,
    showSelected: false,
    selectedFlagsByPositions: {},
    activeTab: 'all',
  }),
  computed: {
    options() {
      return this.items.filter(({ id, name }) => {
        return this.showSelected
          ? name.toLowerCase().includes(this.search.trim().toLowerCase()) &&
              this.selected.includes(id)
          : name.toLowerCase().includes(this.search.trim().toLowerCase());
      });
    },
    optionsByPositions() {
      return this.itemsByPositions.reduce((positions, position) => {
        const competenceList = (
          position?.competenceList.reduce((competencies, competence) => {
            competencies.push({
              id: competence.id,
              name: competence.name,
            });
            return competencies;
          }, []) || []
        ).filter(({ id, name }) => {
          return this.showSelected
            ? name.toLowerCase().includes(this.search.trim().toLowerCase()) &&
                this.selected.includes(id)
            : name.toLowerCase().includes(this.search.trim().toLowerCase());
        });
        if (competenceList.length) {
          positions.push({
            id: position?.id || '',
            name: position?.name || '',
            toggle: false,
            competenceList: competenceList,
          });
        }
        return positions;
      }, []);
    },
    selectedData() {
      return this.items.filter(({ id }) => this.selected.includes(id)) || [];
    },
    modalActions() {
      return [{ action: 'save', class: 'Button-Main' }, { action: 'cancel' }];
    },
    tabs() {
      return [
        {
          name: this.$t('modals.questionary.choose-competence-all-list'),
          tab: 'all',
        },
        {
          name: this.$t('modals.questionary.choose-competence-by-positions'),
          tab: 'position',
        },
      ];
    },
  },
  watch: {
    activeTab(v) {
      if (v === 'position' && this.showSelected) {
        this.showSelected = false;
        this.checkSelectedFlags();
      }
    },
  },
  mounted() {
    this.selected = this.checkedOptions;
    this.checkSelectedFlags();
  },
  methods: {
    onSelectAllCompetence(checked) {
      this.selected = checked ? this.options.map(({ id }) => id) : [];
      this.checkSelectedFlags();
    },
    onToggleShowSelected() {
      this.checkSelectedFlags();
    },
    onSelectAllCompetenceByPosition(checked, list) {
      const ids = list.map(({ id }) => id);
      if (checked) {
        this.selected = [...this.selected, ...ids];
      } else {
        this.selected = this.selected.filter((id) => {
          return !ids.includes(id);
        });
      }
    },
    checkSelectedFlags() {
      this.selectedAll = !this.options
        .map(({ id }) => {
          return this.selected.includes(id);
        })
        .includes(false);
      this.selectedFlagsByPositions = this.optionsByPositions.reduce(
        (flags, { id, competenceList }) => {
          flags[id] = !competenceList
            .map(({ id }) => {
              return this.selected.includes(id);
            })
            .includes(false);
          return flags;
        },
        {},
      );
    },
    isShowSelectAll(items) {
      const ids = this.options.map(({ id }) => id);
      return items.filter(({ id }) => ids.includes(id)).length > 1;
    },
  },
};
</script>