<template>
  <div class="RestorePasswordEmail">
    <RestorePasswordContent
      icon="mail"
      :title="$t('restore-password.titles.checkYourEmail')"
      :text="$t('restore-password.texts.checkYourEmail', { email })"
    />
    <button class="RestorePasswordEmail-Link" @click="onResendEmail">
      {{ $t('base-actions.resendEmail') }}
    </button>
  </div>
</template>

<script>
import RestorePasswordContent from '@/views/Auth/_components/RestorePasswordContent';

export default {
  name: 'RestorePasswordEmailCheck',
  components: {
    RestorePasswordContent,
  },
  props: {
    email: {
      type: String,
      default: 'user@marfa-tech.com',
    },
  },
  methods: {
    onResendEmail() {
      this.$emit('resend');
    },
  },
};
</script>