<template>
  <div class="ProfileUserPhoto" :class="{ 'ProfileUserPhoto-Edit': edit }">
    <div class="ProfileUserPhoto-Wrap">
      <img :src="resource || defaultPhoto" alt="" />
      <div v-if="edit" class="EditButtons">
        <span class="Button-Blue Button UploadPhoto" @click="onUploadPhoto">
          <BaseIcon name="upload-photo" />
          {{ $t('base-actions.uploadPhoto') }}
        </span>
        <span class="Button--reset Button DeletePhoto" @click="onResetPhoto">
          {{ $t('base-actions.deletePhoto') }}
        </span>
      </div>
    </div>
    <InfoRow v-if="edit && isShowSrcInput">
      <template #label>
        {{ $t('labels.srcPhoto') }}
      </template>
      <template #default>
        <b-form-input
          v-model.trim.lazy="resource"
          class="Input"
          :class="{ 'Input--error': !state }"
          type="text"
          autocomplete="off"
          placeholder="Url"
          @input="onChangeUrl"
        ></b-form-input>
      </template>
    </InfoRow>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

import InfoRow from '@/components/Modules/Profile/_components/InfoRow';

import defaultPhoto from '@/assets/Images/profile.png';

export default {
  name: 'ProfileUserPhoto',
  components: { BaseIcon, InfoRow },
  props: {
    state: {
      type: Boolean,
      required: false,
      default: true,
    },
    src: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    defaultPhoto,
    isShowSrcInput: false,
    resource: '',
  }),
  watch: {
    src: {
      immediate: true,
      handler(v) {
        this.resource = v;
      },
    },
  },
  methods: {
    onUploadPhoto() {
      this.isShowSrcInput = true;
    },
    onResetPhoto() {
      this.resource = null;
      this.onChangeUrl(null);
    },
    onChangeUrl(v) {
      this.$emit('update-photo', v);
    },
  },
};
</script>