<template>
  <div class="GeneralTabInfo GeneralTabInfo-Data">
    <InfoRow class="GeneralTabInfo-Subdivision">
      <template #label>
        {{ $t('profile.subdivision') }}
      </template>
      <template #default>
        {{ userSubdivision }}
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-Leaders">
      <template #label>
        {{ $t('profile.leaders') }}
      </template>
      <template #default>
        <template v-if="userLeaderList.length">
          <div class="Leaders-List">
            <div
              v-for="({ photoUrl, fio, position }, index) in userLeaderList"
              :key="`Leader-${index}`"
              class="Leader"
            >
              <div class="Leader-Photo">
                <img :src="photoUrl" :alt="fio" />
              </div>
              <div class="Leader-Data">
                <div class="Leader-Name">{{ fio }}</div>
                <div v-if="false" class="Leader-Position">
                  {{ $t('profile.position') }}: {{ position }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <span class="NotIndicated">
            {{ $t('profile.not-indicated') }}
          </span>
        </template>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-InCompany">
      <template #label>
        {{ $t('profile.inCompany') }}
      </template>
      <template v-if="userInCompany" #default>{{ userInCompany }}</template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-InPosition">
      <template #label>
        {{ $t('profile.inPosition') }}
      </template>
      <template v-if="userInPosition" #default>{{ userInPosition }}</template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-WorkExperience">
      <template #label>
        {{ $t('profile.experience') }}
      </template>
      <template v-if="userWorkExperience" #default>{{ userWorkExperience }}</template>
    </InfoRow>
    <InfoRow v-if="isShowUserDateOfBirth" class="GeneralTabInfo-DateOfBirth">
      <template #label>
        {{ $t('profile.dateOfBirth') }}
      </template>
      <template v-if="userDateOfBirth" #default>{{ userDateOfBirth }}</template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-SlackId">
      <template #label>
        {{ $t('profile.slackId') }}
      </template>
      <template v-if="userSlackId" #default>
        <div class="Copy" @click="onCopyValue('slackId', userSlackId)">
          <img
            class="Messenger"
            :src="require(`@/assets/Images/Messenger/slack.svg`)"
            alt="slack-icon"
          />
          <span ref="slackId">{{ userSlackId }}</span>
          <BaseIcon name="copy-2" />
        </div>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-Telegram">
      <template #label>
        {{ $t('profile.telegram') }}
      </template>
      <template v-if="userTelegram" #default>
        <div class="Copy" @click="onCopyValue('telegram', userTelegram)">
          <img
            class="Messenger"
            :src="require(`@/assets/Images/Messenger/telegram.svg`)"
            alt="telegram-icon"
          />
          <span ref="telegram">{{ userTelegram }}</span>
          <BaseIcon name="copy-2" />
        </div>
      </template>
    </InfoRow>
    <InfoRow class="GeneralTabInfo-Language">
      <template #label>
        {{ $t('profile.language') }}
      </template>
      <template v-if="userLanguageCode" #default>
        <div class="Language">
          <BaseIcon
            no-fill
            no-stroke
            height="21"
            width="21"
            :name="userLanguageCode"
            source="language"
          />
          {{ userLanguage.title }}
        </div>
      </template>
    </InfoRow>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import InfoRow from '@/components/Modules/Profile/_components/InfoRow';

import { languages } from '@/constants/SiteLanguages';
import { unixToDate } from '@/services/Filters';
import { differentDate } from '@/services/Helpers';

const defaultPhoto = require('@/assets/Images/profile.png');

export default {
  name: 'GeneralTabInfo',
  components: {
    BaseIcon,
    InfoRow,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    languages,
  }),
  computed: {
    userSubdivision() {
      return this.user?.subdivision || '';
    },
    userLeaderList() {
      return (this.user?.leaderList || []).map(
        ({ lastName, firstName, photoUrl, position = null }) => {
          return {
            fio: `${lastName} ${firstName}`,
            photoUrl: photoUrl || defaultPhoto,
            position: position || this.$t('profile.not-indicated'),
          };
        },
      );
    },
    userInCompany() {
      return unixToDate(this.user?.inCompany || '');
    },
    userInPosition() {
      return unixToDate(this.user?.inPosition || '');
    },
    userWorkExperience() {
      return differentDate(unixToDate(this.user?.inCompany || '', 'YYYY-MM-DD'));
    },
    userDateOfBirth() {
      return unixToDate(this.user?.birthDate || '');
    },
    isShowUserDateOfBirth() {
      return !this.user?.isBirthDateHide;
    },
    userSlackId() {
      return this.user?.slackId || '';
    },
    userTelegram() {
      return this.user?.telegram || '';
    },
    userLanguageCode() {
      return this.user?.locale || '';
    },
    userLanguage() {
      return this.languages.find((language) => language?.code === this.userLanguageCode);
    },
  },
  methods: {
    onCopyValue(nameRef, value) {
      const container = this.$refs[nameRef][0] || this.$refs[nameRef];
      this.$copyText(`${value}`, container);
    },
  },
};
</script>