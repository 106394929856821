<template>
  <div v-if="items.length" class="QuestionaryCompetenceList">
    <div class="Top">
      <div class="Left">{{ $t('admin.pages.journal.questionary.competence') }}:</div>
      <div class="Right">
        <span class="Button" @click="openSelectCompetenceModal">
          <BaseIcon name="plus" class="me-1" />
          {{ $t('base-actions.add') }}
        </span>
      </div>
    </div>
    <div class="Bottom">
      <div class="List">
        <BaseItemList v-for="(item, index) in localList" :key="item.id">
          <span>
            {{ index + 1 }}: <span class="ms-1">{{ item.name }}</span>
          </span>
          <span class="Button--reset Button" @click="deleteItem(index)">
            <BaseIcon height="16" width="16" name="x-close" />
          </span>
        </BaseItemList>
      </div>
    </div>
    <QuestionarySelectCompetence
      :id="nameModalOfSelect"
      :key="updateKey"
      :checked-options="checkedOptions"
      :items="localModalList"
      :items-by-positions="localModalListByPositions"
      @save="saveSelectCompetenceModal"
      @cancel="closeSelectCompetenceModal"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseItemList from '@/components/Base/BaseItemList';
import QuestionarySelectCompetence from '@/views/Estimation/Questionary/_components/QuestionaryForm/Modal/QuestionarySelectCompetence';

export default {
  name: 'QuestionaryCompetenceList',
  components: { BaseIcon, BaseItemList, QuestionarySelectCompetence },
  props: {
    id: {
      type: String,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    itemsByPositions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    localList: [],
    localModalList: [],
    localModalListByPositions: [],
    updateKey: 0,
    nameModalOfSelect: 'modal-choose-competence',
    checkedOptions: [],
  }),
  watch: {
    localList(values) {
      this.checkedOptions = values.map(({ id }) => id);
      this.updateKey++;
      this.$emit('update-competence', this.localList || []);
    },
    selectedOptions(values) {
      this.localList = ObjectFunctions.deepClone(values);
    },
  },
  mounted() {
    this.localModalList = ObjectFunctions.deepClone(this.items);
    this.localList = ObjectFunctions.deepClone(this.selectedOptions);
    this.localModalListByPositions = ObjectFunctions.deepClone(this.itemsByPositions);
  },
  methods: {
    deleteItem(index) {
      this.localList.splice(index, 1);
    },
    openSelectCompetenceModal() {
      this.$bvModal.show(this.nameModalOfSelect);
    },
    closeSelectCompetenceModal() {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.updateKey++;
    },
    saveSelectCompetenceModal(values) {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.localList = values;
    },
  },
};
</script>
