<template>
  <div class="TemplateListItem">
    <div class="TemplateListItem-Header">
      <div class="TemplateListItem-Title">
        <slot name="title"></slot>
      </div>
      <div class="TemplateListItem-Actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="TemplateListItem-Body">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateListItem',
};
</script>