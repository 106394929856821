<template>
  <div class="QuestionaryArchive">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.archive.title') }}</template>
      <template #subtitle>{{ subTitle }}</template>
    </AppPageHead>
    <div class="QuestionaryArchive-Head">
      <AppListHead>
        <template #search>
          <b-form-input
            v-model.lazy="search"
            class="Input--transparent Input--no-border Input"
            :placeholder="$t('placeholders.archive-search')"
            type="text"
            @change="onSearch"
          ></b-form-input>
        </template>
        <template #actions>
          <BaseSelect
            v-model="sort"
            :items="sortOptions"
            option-key="code"
            option-value="name"
            @input="onSorting"
          >
            <template #caret>
              <BaseIcon name="switch-vertical" />
            </template>
          </BaseSelect>
          <BaseDropdown>
            <template #toggle>
              <span
                class="Button-Filter Button Button--transparent Button--clean Button--width-auto"
              >
                {{ $t('filter') }} <BaseIcon class="ms-2" name="filter-lines" />
              </span>
            </template>
            <AppFilter @filter="onFilter" />
          </BaseDropdown>
        </template>
      </AppListHead>
    </div>
    <div class="QuestionaryArchive-Container">
      <b-overlay
        spinner-type="grow"
        spinner-small
        rounded="sm"
        variant="white"
        :show="isQuestionariesArchiveListLoading"
        class="AppOverlay"
      >
        <div class="QuestionaryArchive-List">
          <QuestionaryCard
            v-for="(card, key) in questionariesGroups"
            :key="key"
            :card="card"
            :is-archive="true"
            @click.native="showQuestionaryModal(card)"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseDropdown from '@/components/Base/BaseDropdown';
import BaseSelect from '@/components/Base/BaseSelect';

import AppPageHead from '@/components/App/AppPageHead';
import AppFilter from '@/components/App/AppFilter';
import AppListHead from '@/components/App/AppListHead';

import QuestionaryCard from '@/views/Estimation/Questionary/_components/QuestionaryCard/QuestionaryCard';

import Declension from '@/services/Helpers/HelperDeclension';

export default {
  name: 'AdminArchiveJournal',
  components: {
    BaseIcon,
    BaseDropdown,
    BaseSelect,
    AppFilter,
    AppPageHead,
    AppListHead,
    QuestionaryCard,
  },
  data: () => ({
    questionariesArchiveList: [],
    isQuestionariesArchiveListLoading: false,
    search: '',
    filter: '',
    sort: 'ASC',
  }),
  computed: {
    subTitle() {
      return `
        ${this.questionariesGroups.length}
        ${Declension(this.questionariesGroups.length, [
          this.$i18n.t('labels.questionaries[0]'),
          this.$i18n.t('labels.questionaries[1]'),
          this.$i18n.t('labels.questionaries[2]'),
        ])}
        ${this.$i18n.tc('labels.in-archive')}
      `;
    },
    questionariesGroups() {
      return this.questionariesArchiveList || null;
    },
    sortOptions() {
      return [
        { name: this.$t('admin.pages.directory.sort-date.asc'), code: 'ASC' },
        { name: this.$t('admin.pages.directory.sort-date.desc'), code: 'DESC' },
      ];
    },
  },
  mounted() {
    this.getQuestionaryArchiveLocalList();
  },
  methods: {
    ...mapMutations('Journal', [
      'setSelectedQuestionary',
      'setSelectedQuestionaryState',
      'setSelectedQuestionaryTitle',
    ]),
    ...mapActions('Journal', ['filterQuestionaryList']),
    showQuestionaryModal(data) {
      this.setSelectedQuestionary(data);
      this.setSelectedQuestionaryTitle(this.$t('admin.pages.journal.show'));
      this.setSelectedQuestionaryState(true);
    },
    setStateArchiveQuestionariesLoading(state = true) {
      this.isQuestionariesArchiveListLoading = state;
    },
    getQuestionaryArchiveLocalList() {
      this.setStateArchiveQuestionariesLoading();

      const prepareFilter = {
        ...this.filter,
        ...(this.search ? { q: this.search } : {}),
        ...(this.sort ? { orderBy: this.sort } : {}),
      };

      this.filterQuestionaryList({
        ...{ active: 0 },
        ...(prepareFilter || {}),
      }).then((response) => {
        this.questionariesArchiveList = response;
        this.setStateArchiveQuestionariesLoading(false);
      });
    },
    onSorting() {
      this.getQuestionaryArchiveLocalList();
    },
    onFilter(filter) {
      this.filter = filter;
      this.getQuestionaryArchiveLocalList();
    },
    onSearch() {
      this.getQuestionaryArchiveLocalList();
    },
  },
};
</script>
