export const Auth = {
  getAuthToken: () => {
    return localStorage.getItem('admin-token');
  },
  setAuthToken: (value) => {
    return localStorage.setItem('admin-token', value);
  },
  removeAuthToken: () => {
    return localStorage.removeItem('admin-token');
  },
};
