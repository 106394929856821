<template>
  <div class="AppDefaultLayoutHeader">
    <div class="AppDefaultLayoutHeader-Left">
      <span class="Button--reset Button me-3 ms-2" @click="$router.back()">
        <BaseIcon name="chevron-left" />
      </span>
      <router-link class="AppDefaultLayoutHeader-Logo" :to="{ name: 'home' }">
        <AppLogo name="dark" />
      </router-link>
    </div>
    <div class="AppDefaultLayoutHeader-Right">
      <AppThemeSwitcher />
      <AppLangSwitcher />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

import AppLogo from '@/components/App/AppLogo';
import AppLangSwitcher from '@/components/App/AppLangSwitcher';
import AppThemeSwitcher from '@/components/App/AppThemeSwitcher';

export default {
  name: 'AppDefaultLayoutHeader',
  components: {
    BaseIcon,
    AppLogo,
    AppLangSwitcher,
    AppThemeSwitcher,
  },
};
</script>