<template>
  <div :class="['QuestionaryMethod', `QuestionaryMethod--${methodClass}`]">
    {{ method }}
  </div>
</template>

<script>
// Словарь классов для методов тестирования
import { QuestionaryClassMethod } from '@/constants/Directories/QuestionaryColorMethod';

export default {
  name: 'QuestionaryMethod',
  props: {
    method: {
      type: [String || Number],
      required: true,
    },
  },
  computed: {
    methodClass() {
      return QuestionaryClassMethod?.[this.method] || '';
    },
  },
};
</script>