<template>
  <div
    v-click-outside="onClickOutside"
    class="Dropdown"
    :class="{ 'Dropdown--Opened': isDropdownOpen }"
  >
    <div class="Dropdown-Toggle" @click="isDropdownOpen = !isDropdownOpen">
      <slot v-if="$scopedSlots.toggle" name="toggle"></slot>
      <template v-else> {{ $t('toggle') }} </template>
    </div>
    <div
      class="Dropdown-Container ScrollBar--hide"
      :class="{ 'Dropdown-Container--Hide': !isDropdownOpen }"
    >
      <div v-if="$scopedSlots.header" class="Dropdown-Header">
        <slot name="header"></slot>
      </div>
      <div class="Dropdown-Body">
        <slot name="default"></slot>
      </div>
      <div v-if="$scopedSlots.footer" class="Dropdown-Footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '@/services/Directives/clickOutside';

export default {
  name: 'BaseDropdown',
  directives: { clickOutside },
  data: () => ({
    isDropdownOpen: false,
  }),
  methods: {
    toggleSelect(v) {
      this.isDropdownOpen = v;
    },
    onClickOutside() {
      this.toggleSelect(false);
    },
  },
};
</script>