<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--Testing"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>{{ $t('testing.title') }}</template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('close')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <QuestionaryTesting
        v-if="questionaryId && employeeId"
        :questionary-id="questionaryId"
        :employee-id="employeeId"
        @close="$emit('close')"
      />
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import QuestionaryTesting from '@/views/Estimation/Questionary/_components/QuestionaryTesting/QuestionaryTesting';

export default {
  name: 'QuestionaryTestingModal',
  components: {
    BaseIcon,
    BaseModalWrap,
    QuestionaryTesting,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    questionaryId: {
      type: String,
      required: true,
    },
    employeeId: {
      type: String,
      required: true,
    },
  },
  computed: {},
};
</script>
