import { DisabledFields } from '@/constants/QuestionaryDisabledFieldsByStatus';

export const clickDisable = {
  bind: function (el, binding) {
    const { status, field } = binding.value;
    if ((DisabledFields?.[status] || []).includes(field)) {
      el.style.opacity = 0.3;
      el.style.pointerEvents = 'none';
    }
  },
};
