<template>
  <div class="UsersTab">
    <div class="UsersTab-Wrap">
      <div class="UsersTab-Container">
        <div class="UsersTab-Head">
          <UsersListHead v-if="isHasPermissions" @create="onCreateUserItem" />
        </div>
        <div class="UsersTab-List">
          <UserListItem v-for="(item, index) in list" :key="`UserTab-Item-${index}`">
            <template v-if="isHasPermissions" #actions>
              <UserListActions
                @edit="onEditUserItem(item.id)"
                @delete="onShowConfirmAction(item.id)"
              />
            </template>
            <div class="UserTab-Item-Info">
              <div class="UserTab-Item-Person">
                <div class="UserTab-Item-Image">
                  {{ [item.lastName, item.firstName] | firstLetters }}
                </div>
                <div class="UserTab-Item-Body">
                  <div class="UserTab-Item-Name">
                    {{ [item.lastName, item.firstName] | joinValuesWithSpaces }}
                  </div>
                  <div class="UserTab-Item-Email">{{ item.email }}</div>
                </div>
              </div>
              <div class="UserTab-Item-Roles">
                <span
                  v-for="(role, indexRole) in item.roles"
                  :key="`UserTab-Item-Role-${index}-${indexRole}`"
                  :class="['UserTab-Item-Role', `UserTab-Item-Role--${role.id}`]"
                >
                  {{ role.name }}
                </span>
              </div>
            </div>
          </UserListItem>
          <UserFormCreateDefault
            :id="nameModalOfAddUserItem"
            :item-model="itemUserModel"
            :action-type="updateOrSave"
            @save="onSaveUserItem"
            @update="onUpdateUserItem"
            @cancel="onCloseUserModal"
          />
          <AppModalConfirm
            :id="nameModalOfConfirmAction"
            :title="$t('modals.directory.delete')"
            @confirm="onConfirmModalAction"
            @cancel="onHideConfirmAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UserListItem from '@/views/Estimation/Users/_components/List/UserListItem';
import UserListActions from '@/views/Estimation/Users/_components/List/UserListActions';
import UsersListHead from '@/views/Estimation/Users/_components/List/UsersListHead';

import UserFormCreateDefault from '@/views/Estimation/Users/_components/Form/UserFormCreateDefault';
import AppModalConfirm from '@/components/App/AppModalConfirm';

import { firstLetters, joinValuesWithSpaces, joinValuesWithComma } from '@/services/Filters';
import { ObjectFunctions } from '@/services/Helpers';

export default {
  name: 'UsersTab',
  components: {
    UserFormCreateDefault,
    AppModalConfirm,
    UserListActions,
    UserListItem,
    UsersListHead,
  },
  filters: { firstLetters, joinValuesWithSpaces, joinValuesWithComma },
  props: {
    userModel: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    nameModalOfAddUserItem: 'modal-add-user',
    nameModalOfConfirmAction: 'modal-confirm-user',
    targetDeleteUserItem: null,
    itemUserModel: {},
    list: [],
  }),
  computed: {
    ...mapState('Users', ['users']),
    ...mapState({
      currentUserInfo: (state) => state.Admin.currentUserInfo,
    }),
    currentUserId() {
      return this.currentUserInfo.id || null;
    },
    updateOrSave() {
      return this.itemUserModel.id ? 'update' : 'save';
    },
    isHasPermissions() {
      return this.currentUserInfo.roles.find((role) => role.id === 'ROLE_SUPER_ADMIN');
    },
  },
  watch: {
    users() {
      this.updateLocalList();
    },
  },
  mounted() {
    this.updateLocalList();
    this.itemUserModel = this.userModel();
  },
  methods: {
    ...mapActions({
      getList: 'Users/getUsersList',
      createItem: 'Users/createUser',
      updateItem: 'Users/updateUser',
      deleteItem: 'Users/deleteUser',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.users);
    },
    updateUserItems() {
      this.getList?.().then(() => {
        this.$bvModal.hide(this.nameModalOfAddUserItem);
      });
    },
    showUserModal() {
      this.$bvModal.show(this.nameModalOfAddUserItem);
    },
    onCreateUserItem() {
      this.itemUserModel = this.userModel();
      this.showUserModal(this.nameModalOfAddUserItem);
    },
    onEditUserItem(itemId) {
      this.itemUserModel = this.list.find((item) => item.id === itemId);
      this.showUserModal(this.nameModalOfAddUserItem);
    },
    onCloseUserModal() {
      this.$bvModal.hide(this.nameModalOfAddUserItem);
      this.itemUserModel = this.userModel();
    },
    onShowConfirmAction(itemId) {
      this.targetDeleteUserItem = itemId;
      this.$bvModal.show(this.nameModalOfConfirmAction);
    },
    onHideConfirmAction() {
      this.$bvModal.hide(this.nameModalOfConfirmAction);
    },
    onConfirmModalAction() {
      if (typeof this.deleteItem === 'function') {
        this.deleteItem(this.targetDeleteUserItem)
          .then(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.updateUserItems();
            this.targetDeleteUserItem = null;
          })
          .catch(() => {
            this.$bvModal.hide(this.nameModalOfConfirmAction);
            this.targetDeleteUserItem = null;
          });
      }
    },
    onSaveUserItem(userData) {
      if (typeof this.createItem === 'function') {
        const roles = userData.roles.map((role) => role.id);
        this.createItem({ ...userData, roles }).then(() => {
          this.changeLocaleCurrentUser(userData);
          this.updateUserItems();
        });
      }
    },
    onUpdateUserItem(userData) {
      if (typeof this.updateItem === 'function') {
        const roles = userData.roles.map((role) => role.id);
        this.updateItem({
          id: userData.id,
          data: { ...userData, roles },
        }).then(() => {
          this.changeLocaleCurrentUser(userData);
          this.updateUserItems();
        });
      }
    },
    changeLocaleCurrentUser(userData) {
      const { id, locale } = userData;
      if (id === this.currentUserId) {
        this.$i18n.locale = locale;
      }
    },
  },
};
</script>
