<template>
  <div class="Subdivisions">
    <DictionaryListHead
      type="subdivisions"
      @create="onCreateDictionaryItem"
      @filter="onFilterDictionaryItems"
    ></DictionaryListHead>
    <div class="ItemsList">
      <DictionaryListItem v-for="item in list" :key="item.id">
        <template #title>
          <span>
            {{ item.name }}
          </span>
        </template>
        <template #actions>
          <DictionaryListActions
            type="subdivisions"
            @edit="editDictionaryItem(item.id)"
            @delete="showConfirmAction(item.id)"
          />
        </template>
      </DictionaryListItem>
    </div>
    <DictionaryFormCreateSubdivisions
      :id="nameModalOfAddDictionaryItem"
      :action-type="updateOrSave"
      :item-model="itemDictionaryModel"
      @save="saveDictionaryItem"
      @update="updateDictionaryItem"
      @cancel="closeDictionaryModal"
    />
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.delete')"
      @confirm="confirmModalAction"
      @cancel="hideConfirmAction"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import { mapState, mapActions } from 'vuex';

import { DictionaryTab } from '@/mixins/DictionaryTab';
import DictionaryListHead from '@/views/Estimation/Dictionary/_components/List/DictionaryListHead';
import DictionaryListItem from '@/views/Estimation/Dictionary/_components/List/DictionaryListItem';
import DictionaryListActions from '@/views/Estimation/Dictionary/_components/List/DictionaryListActions';
import DictionaryFormCreateSubdivisions from '@/views/Estimation/Dictionary/_components/Form/DictionaryFormCreateSubdivisions';

import AppModalConfirm from '@/components/App/AppModalConfirm';

export default {
  name: 'DictionaryTabSubdivisions',
  components: {
    DictionaryListHead,
    DictionaryListItem,
    DictionaryListActions,
    DictionaryFormCreateSubdivisions,
    AppModalConfirm,
  },
  mixins: [DictionaryTab],
  data: () => ({
    nameModalOfAddDictionaryItem: 'modal-add-subdivision',
    nameModalOfConfirmAction: 'modal-confirm-subdivision',
    targetDeleteDictionaryItem: null,
    itemDictionaryModel: {},
  }),
  computed: {
    ...mapState('Dictionary', ['subdivisions']),
    dictionaryModel() {
      return this.models.subdivisionsModel;
    },
  },
  watch: {
    subdivisions() {
      this.updateLocalList();
    },
  },
  created() {
    this.updateLocalList();
  },
  mounted() {
    this.getList().then(() => {
      this.itemDictionaryModel = this.dictionaryModel();
    });
  },
  methods: {
    ...mapActions({
      getList: 'Dictionary/getSubdivisionsList',
      createItem: 'Dictionary/createSubdivisionsItem',
      updateItem: 'Dictionary/updateSubdivisionsItem',
      deleteItem: 'Dictionary/deleteSubdivisionsItem',
      filterList: 'Dictionary/getFilterSubdivisionsList',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.subdivisions);
    },
  },
};
</script>
