var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Tab Tab-Reports"},[_c('BaseProfileBlock',{scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('div',{staticClass:"Title"},[_vm._v(" "+_vm._s(_vm.$t('profile.reports'))+" ")])]},proxy:true}])},[_c('BaseTable',{attrs:{"count":_vm.count,"items":_vm.items,"headers":_vm.headers,"limit":10},on:{"get-data":_vm.onGetData},scopedSlots:_vm._u([{key:"body-col-number",fn:function(ref){
var item = ref.item;
return [_vm._v(" №"+_vm._s(item.number)+" ")]}},{key:"body-col-dateStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToDate")(item.dateStart))+" ")]}},{key:"body-col-dateEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToDate")(item.dateEnd))+" ")]}},{key:"body-col-method",fn:function(ref){
var item = ref.item;
return [_c('QuestionaryMethod',{attrs:{"method":item.method}})]}},{key:"body-col-action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"Button-InTables Button",on:{"click":function($event){return _vm.onExportReport(item)}}},[_c('BaseIcon',{staticClass:"me-2",attrs:{"width":"16","height":"16","name":"arrow-circle-broken-down"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('labels.download-pdf'))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }