<template>
  <RestorePasswordEmailSend v-if="!isEmailSend" @send-email="setEmailData" />
  <RestorePasswordEmailCheck v-else :email="email" @resend="setEmailData" />
</template>

<script>
import RestorePasswordEmailSend from '@/views/Auth/_components/RestorePasswordEmailSend';
import RestorePasswordEmailCheck from '@/views/Auth/_components/RestorePasswordEmailCheck';
export default {
  name: 'RestorePasswordEmail',
  components: {
    RestorePasswordEmailCheck,
    RestorePasswordEmailSend,
  },
  data: () => ({
    isEmailSend: false,
    email: '',
  }),
  methods: {
    setEmailData({ email = '', status = false } = {}) {
      this.isEmailSend = status;
      this.email = email;
    },
  },
};
</script>