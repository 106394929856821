import { WhiteListPrefix } from '@/constants/WhiteListPrefix';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    callStackWhiteListPrefixOfActions: WhiteListPrefix || [],
    callStack: [],
  },
  mutations: {
    addActionToCallStack(state, action = '') {
      if (!state.callStack.includes(action)) {
        state.callStack.push(action);
      }
    },
    removeActionFromCallStack(state, action = '') {
      if (state.callStack.includes(action)) {
        state.callStack.splice(state.callStack.indexOf(action), 1);
      }
    },
    setLoading(state, payload = true) {
      state.isLoading = payload;
    },
  },
  actions: {},
  getters: {},
};
