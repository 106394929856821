<template>
  <div
    :id="`popover-target-${popoverId}-${groupId}-${index}`"
    class="QuestionaryParticipant"
    :class="[
      classes[type],
      status,
      { done: isPassed, no_progress: isNext, in_work: isCurrent, 'cursor-default': !item },
    ]"
  >
    <template v-if="item">
      <span>
        {{ [item.lastName, item.firstName] | firstLetters }}
      </span>
      <b-popover
        custom-class="Popover"
        :target="`popover-target-${popoverId}-${groupId}-${index}`"
        triggers="hover"
      >
        <template #title>
          <div class="Popover-Header">
            <div class="Popover-Header-Name">
              {{ [item.lastName, item.firstName, item.middleName] | joinValuesWithSpaces }}
            </div>
            <div :class="['Status', `Status--${status}`]">
              {{ $t(`testing-statuses.${status}`) }}
            </div>
          </div>
        </template>
        <template v-if="participants">
          <div class="Popover-Participants">
            <div
              v-for="(participant, idx) in participants"
              :key="`Participant-${idx}`"
              class="Popover-Participant"
              :class="[`Popover-Participant--${classes[participant.type]}`]"
            >
              <span class="Popover-Participant-Name">
                {{ participant.name }}
              </span>
              <span :class="['Status', `Status--${participant.status}`]">
                {{ $t(`testing-statuses.${participant.status}`) }}
              </span>
            </div>
          </div>
        </template>
      </b-popover>
    </template>
    <template v-else>
      <span> {{ letters[type] }}{{ index }} </span>
    </template>
  </div>
</template>

<script>
import { QuestionaryFirstLetterParticipants } from '@/constants/Directories/QuestionaryFirstLetterParticipants';
import { QuestionaryClassParticipants } from '@/constants/Directories/QuestionaryColorParticipants';

import { firstLetters, joinValuesWithSpaces } from '@/services/Filters';

export default {
  name: 'QuestionaryParticipant',
  filters: { firstLetters, joinValuesWithSpaces },
  props: {
    type: {
      type: [String],
      required: true,
    },
    image: {
      type: [String],
      required: false,
      default: () => null,
    },
    index: {
      type: [String, Number],
      required: false,
      default: () => '',
    },
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    groupId: {
      type: String,
      required: false,
      default: () => '',
    },
    isPassed: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isCurrent: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isNext: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    letters: QuestionaryFirstLetterParticipants,
    classes: QuestionaryClassParticipants,
  }),
  computed: {
    popoverId() {
      return this.item?.id || '';
    },
    status() {
      if (this.item) {
        if (this.item.progressList.length === 1) {
          return this.item.progressList?.[0]?.status || '';
        } else {
          const statusList = [...new Set(this.item.progressList.map((item) => item.status))];
          if (statusList.length > 1) {
            return 'in_work';
          } else {
            return statusList?.[0] || '';
          }
        }
      } else {
        return '';
      }
    },
    participants() {
      return this.item?.progressList.length > 1 ? this.item.progressList : null;
    },
  },
};
</script>