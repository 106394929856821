import { ModelLanguage } from '@/store/Models/ModelLanguage';

export const CompetenceList = {
  questionModel: ({ id = null, text = 'empty', position = null, questionText = {} } = {}) => ({
    id,
    text,
    position,
    questionText: ModelLanguage.base(questionText),
  }),
  questionCopyModel: ({ text = 'empty', position = null, questionText = {} } = {}) => ({
    id: null,
    text,
    position,
    questionText: ModelLanguage.base(questionText),
  }),
  questionListModel: (list = []) => list.map((item) => CompetenceList.questionModel(item)),
  questionListCopyModel: (list = []) => list.map((item) => CompetenceList.questionCopyModel(item)),
  nameTextModel: (model = {}) => ModelLanguage.base(model),
  descriptionTextModel: (model = {}) => ModelLanguage.base(model),
};
