<template>
  <div class="QuestionaryTesting">
    <AppPageHead>
      <template #title>{{ $t('admin.pages.testing.title') }}</template>
      <template #subtitle>{{ subTitle }}</template>
    </AppPageHead>
    <div class="QuestionaryTesting-Head">
      <AppListHead>
        <template #search>
          <b-form-input
            class="Input--transparent Input--no-border Input"
            :placeholder="$t('placeholders.archive-search')"
            type="text"
            @change="onSearch"
          ></b-form-input>
        </template>
        <template #actions>
          <BaseDropdown>
            <template #toggle>
              <span
                class="Button-Filter Button Button--transparent Button--clean Button--width-auto"
              >
                {{ $t('filter') }} <BaseIcon class="ms-2" name="filter-lines" />
              </span>
            </template>
            <AppFilter @filter="onFilter" />
          </BaseDropdown>
        </template>
      </AppListHead>
    </div>
    <div class="QuestionaryTesting-Container">
      <BaseTable
        :key="keyTable"
        :headers="headers"
        :items="questionariesTesting"
        :count="questionariesTestingCount"
        :limit="request.table.limit"
        @get-data="onGetData"
      >
        <template #body-col-number="{ item }"> №{{ item.number }} </template>
        <template #body-col-lastName="{ item }">
          <div class="EmployeeInfo">
            <div class="EmployeeInfo-Avatar">
              <template v-if="item.photoUrl">
                <img :src="item.photoUrl" :alt="item.lastName" />
              </template>
              <template v-else>
                {{ [item.lastName, item.firstName] | firstLetters }}
              </template>
            </div>
            <div class="EmployeeInfo-Name">{{ item.lastName }} {{ item.firstName }}</div>
            <div class="EmployeeInfo-Email">
              {{ item.email }}
            </div>
          </div>
        </template>
        <template #body-col-isAnonymous="{ item }">
          <span :class="{ isAnonymous: item.isAnonymous, isNotAnonymous: !item.isAnonymous }">
            <BaseIcon :name="item.isAnonymous ? 'check' : 'x-close'" />
          </span>
        </template>
        <template #body-col-dateStart="{ item }">
          <BaseIcon name="clock" />
          {{ calculateDate(item.dateStart) }} - {{ calculateDate(item.dateEnd) }}
        </template>
        <template #body-col-status="{ item }">
          <span
            v-if="item.isActive && item.status === 'no_progress'"
            class="Button-Main Button Medium"
            @click="onShowQuestionaryTestingModal(item)"
          >
            {{ $t('base-actions.testing') }}
          </span>
          <template v-else>
            <span
              :class="[
                'Status',
                {
                  'Status-Rated': checkStatus(item.status),
                  'Status-NotRated': !checkStatus(item.status),
                },
              ]"
            >
              <BaseIcon :name="`${checkStatus(item.status) ? 'check' : 'x'}-circle`"></BaseIcon>
              {{ $t(`labels.${item.status}`) }}
            </span>
          </template>
        </template>
      </BaseTable>
      <QuestionaryTestingModal
        :id="questionaryTestingModalId"
        :employee-id="employeeTesting"
        :questionary-id="questionaryTesting"
        @close="onCloseQuestionaryTestingModal"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseTable from '@/components/Base/BaseTable';
import BaseDropdown from '@/components/Base/BaseDropdown';

import AppPageHead from '@/components/App/AppPageHead';
import AppListHead from '@/components/App/AppListHead';
import AppFilter from '@/components/App/AppFilter';

import QuestionaryTestingModal from '@/views/Estimation/Questionary/_components/Modals/QuestionaryTestingModal';

import { vmoment } from '@/services/Helpers';
import { firstLetters } from '@/services/Filters';

import { TESTING_HEADERS } from './config';

const defaultTableParams = {
  limit: 10,
  offset: 0,
  sort: 'dateStart:d',
};

export default {
  name: 'AdminQuestionaryTesting',
  components: {
    BaseIcon,
    BaseDropdown,
    BaseTable,
    AppPageHead,
    AppListHead,
    AppFilter,
    QuestionaryTestingModal,
  },
  filters: { firstLetters },
  data: () => ({
    questionaryTestingModalId: 'questionaryTestingModal',
    questionaryTesting: '',
    employeeTesting: '',
    request: {
      search: '',
      filter: {},
      table: defaultTableParams,
    },
    keyTable: 0,
  }),
  computed: {
    ...mapState('Journal', ['questionariesTesting', 'questionariesTestingCount']),
    subTitle() {
      return '';
    },
    headers() {
      return TESTING_HEADERS.map((header) => {
        const { key } = header;
        return this.$te(`tables.testing.${key}`)
          ? { ...header, name: this.$t(`tables.testing.${key}`) }
          : header;
      });
    },
    requestParams() {
      let payload = {};
      const { search = '', filter = {}, table = {} } = this.request;
      if (search) {
        payload = { ...payload, [`filterList[q]`]: search };
      }
      Object.keys(filter).map((key) => {
        if (filter[key] !== '') {
          const value = ['dateFrom', 'dateTo'].includes(key)
            ? vmoment(filter[key]).unix()
            : filter[key];
          payload = { ...payload, [`filterList[${key}]`]: value };
        }
      });
      Object.keys(table).map((key) => {
        if (table[key] !== '') {
          payload = { ...payload, [`${key}`]: table[key] };
        }
      });
      return payload;
    },
  },
  watch: {
    requestParams(params) {
      this.getQuestionaryTestingList(params);
    },
  },
  methods: {
    ...mapActions('Journal', ['getQuestionaryTestingList']),
    calculateDate(date) {
      return vmoment(date * 1000).format('YYYY-MM-DD');
    },
    checkStatus(status) {
      return status === 'done';
    },
    onSearch(search) {
      this.request.search = search;
      this.request.table = defaultTableParams;
      this.keyTable++;
    },
    onFilter(filter) {
      this.request.filter = filter;
      this.request.table = defaultTableParams;
      this.keyTable++;
    },
    onGetData(table) {
      this.request.table = table;
    },
    onShowQuestionaryTestingModal({ questionaryId = '', employeeId = '' } = {}) {
      if (questionaryId && employeeId) {
        this.questionaryTesting = questionaryId;
        this.employeeTesting = employeeId;
        this.$bvModal.show(this.questionaryTestingModalId);
      }
    },
    onCloseQuestionaryTestingModal() {
      this.$bvModal.hide(this.questionaryTestingModalId);
      this.questionaryTesting = '';
      this.employeeTesting = '';
      this.getQuestionaryTestingList(this.requestParams);
    },
  },
};
</script>
