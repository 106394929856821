<template>
  <b-modal
    :id="id"
    size="md"
    body-class="BaseModal BaseModal--Confirm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="modalText" />
      <!--eslint-enable-->
      <BaseActions
        :actions="actions"
        @read="$emit('read')"
        @confirm="$emit('confirm')"
        @cancel="$emit('cancel')"
      />
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

export default {
  name: 'AppModalNotification',
  components: {
    BaseModalWrap,
    BaseActions,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
    },
    reedAll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    actions() {
      return this.reedAll
        ? [{ action: 'confirm', class: 'Button-Main' }, { action: 'cancel' }]
        : [{ action: 'read', class: 'Button-Main' }];
    },
    modalText() {
      return this.reedAll ? this.$t('header.notification.reedAll') : this.notification?.text;
    },
  },
};
</script>