<template>
  <div class="LayoutDefault">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Default',
};
</script>