<template>
  <b-modal
    :id="id"
    size="sm"
    body-class="BaseModal BaseModal--Logout"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      {{ $t('modals.exit.title') }}
      <BaseActions
        :actions="modalActions"
        @logout="$emit('auth-logout-success')"
        @cancel="$emit('auth-logout-cancel')"
      />
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';

export default {
  name: 'AppModalLogout',
  components: {
    BaseModalWrap,
    BaseActions,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    modalActions() {
      return [{ action: 'logout', class: 'Button-Main' }, { action: 'cancel' }];
    },
  },
};
</script>