<template>
  <div class="BaseSortIcon">
    <div class="BaseSortIcon" :class="{ 'BaseSortIcon-Up': isAsc }">
      <BaseIcon name="sort-up" height="8" width="7" />
    </div>
    <div class="BaseSortIcon" :class="{ 'BaseSortIcon-Down': isDesc }">
      <BaseIcon name="sort-down" height="8" width="7" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

export default {
  name: 'BaseSortIcon',
  components: { BaseIcon },
  props: {
    state: {
      type: Boolean,
      required: true,
    },
    sort: {
      type: String,
      required: false,
      default: 'a',
    },
  },
  computed: {
    isAsc() {
      return this.state && this.sort === 'a';
    },
    isDesc() {
      return this.state && this.sort === 'd';
    },
  },
};
</script>