import { AuthGuard } from '@/router/Auth-guard';

import Structure from '@/views/Structure/Main';
import EmployeeList from '@/views/Structure/Tabs/EmployeeList';
import Tree from '@/views/Structure/Tabs/Tree';
import FiredEmployees from '@/views/Structure/Tabs/FiredEmployees';

export default [
  {
    path: '/structure',
    name: 'structure',
    component: Structure,
    redirect: { name: 'structure:employees' },
    children: [
      {
        path: 'employees',
        name: 'structure:employees',
        component: EmployeeList,
        beforeEnter: AuthGuard.isAdmin,
      },
      {
        path: 'tree',
        name: 'structure:tree',
        component: Tree,
        beforeEnter: AuthGuard.isAdmin,
      },
      {
        path: 'fired-employees',
        name: 'structure:firedEmployees',
        component: FiredEmployees,
        beforeEnter: AuthGuard.isAdmin,
      },
    ],
  },
];
