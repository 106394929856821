<template>
  <div class="LegalPublicOfferAgreement">
    <h4>Пользовательское соглашение</h4>
    <br />
    <p>г. Москва «01» июня 2022 г.</p>
    <br />
    <h5>1. ОБЩИЕ ПОЛОЖЕНИЯ</h5>
    <br />
    <p>
      1.1. Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту
      Интернет-ресурса «HR Galaxy Camellia, модуль 360» (далее -Модуль 360), расположенному по
      адресу <a href="https://360.galaxy-camellia.com/">https://360.galaxy-camellia.com/</a>, и ко
      всем соответствующим сайтам, связанным с сайтом
      <a href="https://360.galaxy-camellia.com/">https://360.galaxy-camellia.com/</a>.
    </p>
    <p>
      1.2. Сайт Интернет-ресурса «Модуль 360» (далее – Сайт) является собственностью общества с
      ограниченной ответственностью «МарфаТех» (реки).
    </p>
    <p>
      1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта Интернет-ресурса
      «Модуль 360» (далее – Администрация сайта) и Пользователем данного Сайта.
    </p>
    <p>
      1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или
      удалять пункты настоящего Соглашения без уведомления Пользователя.
    </p>
    <p>
      1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений,
      внесенных в настоящее Соглашение.
    </p>
    <p>
      1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на
      наличие изменений в нем.
    </p>
    <p>
      1.7. Начиная использовать какой-либо сервис/его отдельные функции, либо пройдя процедуру
      регистрации, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких
      оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения,
      Пользователь не вправе использовать сервисы Модуль 360. В случае если Модуль 360 были внесены
      какие-либо изменения в Соглашение, с которыми Пользователь не согласен, он обязан прекратить
      использование сервисов Модуль 360.
    </p>
    <p>
      1.8. «HR Galaxy Camellia, модуль 360» предназначен для предоставления возможности оценки
      компетенций сотрудника путем опроса делового окружения сотрудника с целью выявления зон
      развития для дальнейшего развития и обучения сотрудника.
    </p>
    <p>1.9. Целью разработки является проведение опросов по методологии 360 для:</p>
    <p>
      – определения зон развития сотрудников (развитие и личностный рост); <br />
      – определения потребностей в обучении сотрудников (по результатам оценки может быть
      сформирован план обучения);<br />
      – подготовки кадрового резерва (по результатам оценки сотрудник может быть определен в
      кадровый резерв); <br />
      – стимулирования диалога «руководитель – подчиненный» (предоставление обратной связи). 
    </p>
    <br />
    <h5>2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</h5>
    <br />
    <p>2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</p>
    <p>
      2.1.1 «Модуль 360» – Интернет-ресурс, расположенный на доменном имени
      <a href="https://360.galaxy-camellia.com/">https://360.galaxy-camellia.com/</a>,
      осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.
    </p>
    <p>
      2.1.2. Интернет-ресурс – сайт, содержащий информацию о Товарах, Продавце, позволяющий
      осуществить выбор, заказ и (или) приобретение Товара.
    </p>
    <p>
      2.1.3. Администрация сайта Интернет-ресурса – уполномоченные сотрудники на управления Сайтом,
      действующие от имени общества с ограниченной ответственностью «МарфаТех».
    </p>
    <p>
      2.1.4. Пользователь сайта Интернет-ресурса (далее ‑ Пользователь) – лицо, имеющее доступ к
      Сайту, посредством сети Интернет и использующее Сайт.
    </p>
    <p>
      2.1.5. Содержание сайта Интернет-ресурса (далее – Содержание) - охраняемые результаты
      интеллектуальной деятельности, включая тексты литературных произведений, их названия,
      предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или
      без текста, графические, текстовые, фотографические, производные, составные и иные
      произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков,
      логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация,
      внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие
      объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте
      Интернет-ресурса.
    </p>
    <p>
      2.1.6. Оценка по компетенциям: метод оценки компетенций сотрудника путем опроса делового
      окружения сотрудника с целью выявления зон развития для дальнейшего развития и обучения
      сотрудника. 
    </p>
    <p>
      2.1.7. Анкета: опросный лист со списком вопросов, ответы на которые позволяют произвести
      оценку по компетенциям. В контексте Модуля 360, в анкете указываются методология оценки,
      оцениваемый сотрудник или сотрудники, оценивающие сотрудники, сроки оценки, и набор
      компетенций, связанных с задачей исследования и содержащих вопросы. 
    </p>
    <p>2.1.8. Отчет: результат оценки сотрудника. </p>
    <p>
      2.1.9. Компетенция: способность специалиста решать определенный класс профессиональных задач.
      В контексте Модуля 360, компетенция характеризуется набором вопросов, по которым ее можно
      оценить. 
    </p>
    <p>
      2.1.10. Опросник: набор вопросов, на которые отвечает оценивающий сотрудник в процессе
      оценки. 
    </p>
    <p>
      2.1.11. Сотрудник: в контексте Модуля 360, сотрудник компании, внесенный в справочник
      “Сотрудники”, имеющий возможность принимать участие в оценках (в том числе, самооценках).
    </p>
    <p>
      2.1.12. SaaS, software as a service – программное обеспечение как услуга: модель обслуживания,
      при которой подписчикам предоставляется готовое прикладное программное обеспечение, полностью
      обслуживаемое провайдером.
    </p>
    <br />
    <h5>3. ПРЕДМЕТ СОГЛАШЕНИЯ</h5>
    <br />
    <p>
      3.1. Предметом настоящего Соглашения является предоставление Пользователю Интернет-ресурса
      доступа к содержащимся на Сайте Товарам и оказываемым услугам.
    </p>
    <p>3.1.1. Интернет-ресурс предоставляет Пользователю следующие виды услуг (сервисов):</p>
    <p>
      • доступ к электронному контенту на платной основе, с правом приобретения (скачивания),
      просмотра контента;<br />
      • проведение оценки по компетенциям – метод оценки компетенций сотрудника путем опроса
      делового окружения сотрудника с целью выявления зон развития для дальнейшего развития и
      обучения сотрудника. Оценка по компетенциям может быть разных видов:<br />
      90 градусов – это оценка только Руководителем;<br />
      180 градусов – это оценка Руководителем и самим Сотрудником;<br />
      360 градусов – это оценка всего делового окружения: Руководитель, Сотрудник, Коллеги,
      Подчиненные, Клиенты.<br />
      • получение результатов проведенной оценки в форме отчета;<br />
      • доступ к средствам поиска и навигации Интернет-ресурса;<br />
      • обратная связь и поддержка посредствам чат-бота;<br />
      • интеграция со сторонними сервисами для рассылки уведомлений и оповещений;<br />
      • предоставление Пользователю возможности размещения сообщений, комментариев, рецензий
      Пользователей, выставления оценок контенту Интернет-ресурса;<br />
      • доступ к информации о Товаре и к информации о приобретении Товара на платной основе;<br />
      • иные виды услуг (сервисов), реализуемые на страницах Интернет-ресурса, включая платные
      услуги (сервисы).
    </p>
    <p>
      3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие)
      на данный момент услуги (сервисы) Интернет-ресурса, а также любые их последующие модификации и
      появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-ресурса.
    </p>
    <p>
      3.2. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь
      считается присоединившимся к настоящему Соглашению.
    </p>
    <p>
      3.3. Использование материалов и сервисов Сайта регулируется нормами действующего
      законодательства Российской Федерации
    </p>
    <br />
    <h5>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h5>
    <br />
    <p>4.1. Администрация сайта вправе:</p>
    <p>
      4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта.
      Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.
    </p>
    <p>
      4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего
      Соглашения.
    </p>
    <p>
      4.1.3. Изменять размер оплаты, взимаемый за предоставление доступа к использованию сайта
      Интернет-ресурса. Изменение стоимости не будет распространяться на Пользователей, имеющих
      регистрацию к моменту изменения размера оплаты, за исключением случаев, особо оговоренных
      Администрацией сайта интернет-ресурса.
    </p>
    <p>
      4.1.4. Собирать, анализировать, использовать, делиться (в том числе на платной основе)
      информацией о Пользователе, содержащейся на Сайте, включая, но не ограничиваясь, информацией о
      контактных и личных данных Пользователя, информацией о действиях Пользователя на Сайте и т.д.
    </p>
    <p>4.2. Пользователь вправе:</p>
    <p>4.2.1. Получить доступ к использованию Сайта.</p>
    <p>
      4.2.2. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары,
      предлагаемые на Сайте.
    </p>
    <p>
      4.2.3. Задавать любые вопросы, относящиеся к услугам Интернет-ресурса посредствам чат-бота.
    </p>
    <p>
      4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не
      запрещенных законодательством Российской Федерации.
    </p>
    <p>4.3. Пользователь Сайта обязуется:</p>
    <p>
      4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет
      непосредственное отношение к предоставляемым услугам данного Сайта.
    </p>
    <p>
      4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при
      использовании Сайта.
    </p>
    <p>
      4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную
      работу Сайта.
    </p>
    <p>
      4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую
      законодательством Российской Федерации информацию о физических либо юридических лицах.
    </p>
    <p>
      4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность
      охраняемой законодательством Российской Федерации информации.
    </p>
    <p>
      4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с
      согласия Администрации сайта.
    </p>
    <p>4.3.7. Не использовать сервисы сайта Интернет-ресурса с целью:</p>
    <p>
      4.3.7. 1. загрузки контента, который является незаконным, нарушает любые права третьих лиц;
      пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому,
      национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения
      и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.
    </p>
    <p>
      4.3.7. 2. побуждения к совершению противоправных действий, а также содействия лицам, действия
      которых направлены на нарушение ограничений и запретов, действующих на территории Российской
      Федерации.
    </p>
    <p>
      4.3.7. 3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
    </p>
    <p>4.3.7. 4. ущемления прав меньшинств.</p>
    <p>
      4.3.7. 5. представления себя за другого человека или представителя организации и (или)
      сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет-ресурса.
    </p>
    <p>
      4.3.7. 6. введения в заблуждение относительно свойств и характеристик какого-либо Товара из
      каталога Интернет-ресурса, размещенного на Сайте.
    </p>
    <p>
      4.3.7. 7. некорректного сравнения Товара, а также формирования негативного отношения к лицам,
      (не) пользующимся определенными Товарами, или осуждения таких лиц.
    </p>
    <p>4.4. Пользователю запрещается:</p>
    <p>
      4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические
      устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или
      отслеживания содержания Сайта данного Интернет-ресурса;
    </p>
    <p>4.4.2. Нарушать надлежащее функционирование Сайта;</p>
    <p>
      4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки
      получения любой информации, документов или материалов любыми средствами, которые специально не
      представлены сервисами данного Сайта;
    </p>
    <p>
      4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
      относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;
    </p>
    <p>
      4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся
      к Сайту.
    </p>
    <p>
      4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом
      другом Пользователе Сайта.
    </p>
    <p>
      4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством
      Российской Федерации, а также подстрекать к любой незаконной деятельности или другой
      деятельности, нарушающей права интернет-ресурса или других лиц.
    </p>
    <br />
    <h5>5. ИСПОЛЬЗОВАНИЕ САЙТА ИНТЕРНЕТ-РЕСУРСА</h5>
    <br />
    <p>
      5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией
      сайта.
    </p>
    <p>
      5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или
      распространено любым способом, а также размещено в глобальной сети «Интернет» без
      предварительного письменного согласия Администрации сайта.
    </p>
    <p>
      5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также
      другими правами, связанными с интеллектуальной собственностью, и законодательством о
      недобросовестной конкуренции.
    </p>
    <p>
      5.4. При использовании некоторых сервисов сайта, может потребоваться создание учётной записи
      Пользователя.
    </p>
    <p>
      5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности
      информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая
      ведётся от имени Пользователя учётной записи.
    </p>
    <p>
      5.6. Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном
      использовании его учётной записи или пароля или любом другом нарушении системы безопасности.
    </p>
    <p>
      5.7. Администрация сайта обладает правом в одностороннем порядке аннулировать учетную запись
      Пользователя, если она не использовалась более 12 (двенадцати) календарных месяцев подряд без
      уведомления Пользователя.
    </p>
    <p>
      5.7. Настоящее Соглашение распространяет свое действия на все дополнительные положения и
      условия о покупке Товара и оказании услуг, предоставляемых на Сайте.
    </p>
    <p>
      5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего
      Соглашения.
    </p>
    <p>
      5.9. Администрация сайта имеет право в любое время без уведомления Пользователя вносить
      изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к
      таким Товарам по их реализации и (или) оказываемым услугам Интернет-ресурсом.
    </p>
    <br />
    <h5>6. ОТВЕТСТВЕННОСТЬ</h5>
    <br />
    <p>
      6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного
      нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного
      доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.
    </p>
    <p>6.2. Администрация сайта не несет ответственности за:</p>
    <p>
      6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой
      силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и
      иных смежных системах.
    </p>
    <p>
      6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их
      работой.
    </p>
    <p>
      6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых
      технических средств для его использования, а также не несет никаких обязательств по
      обеспечению пользователей такими средствами.
    </p>
    <p>6.2.4. Содержание (контент) информации размещенной на Сайте.</p>
    <p>
      6.3. Пользователь самостоятельно несет ответственность за интерпретацию и использование
      контента (информации), размещенного на Сайте.
    </p>
    <br />
    <h5>7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h5>
    <br />
    <p>
      7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта
      информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении
      неправомерного использования Сайта либо для установления (идентификации) Пользователя, который
      может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей
      Сайта.
    </p>
    <p>
      7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую
      посчитает необходимой для выполнения положений действующего законодательства или судебных
      решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности
      название организации, Пользователей.
    </p>
    <p>
      7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее
      законодательство Российской Федерации требует или разрешает такое раскрытие.
    </p>
    <p>
      7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и
      (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или
      содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения
      действия Сайта либо по причине технической неполадки или проблемы.
    </p>
    <p>
      7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за
      прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего
      Соглашения или иного документа, содержащего условия пользования Сайтом.
    </p>
    <br />
    <h5>8. РАЗРЕШЕНИЕ СПОРОВ</h5>
    <br />
    <p>
      8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения
      обязательным условием до обращения в суд является предъявление претензии (письменного
      предложения о добровольном урегулировании спора).
    </p>
    <p>
      8.2. Получатель претензии в течение 30 (тридцати) календарных дней со дня ее получения,
      письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
    </p>
    <p>
      8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться
      в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской
      Федерации.
    </p>
    <p>
      8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение срока
      после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в
      соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой
      иск или основания для иска погашаются исковой давностью.
    </p>
    <br />
    <h5>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h5>
    <br />
    <p>
      9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно
      изменений настоящего Пользовательского соглашения.
    </p>
    <p>
      9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и
      могут быть использованы Администрацией сайта без ограничений.
    </p>
    <br />
    <p>Обновлено «01» июня 2022 г.</p>
  </div>
</template>

<script>
export default {
  name: 'LegalPublicOfferAgreement',
};
</script>