import { AuthGuard } from '@/router/Auth-guard';

// Авторизация
import Login from '@/views/Auth/Login';

// Восстановление пароля
import RestorePassword from '@/views/Auth/RestorePassword';
import RestorePasswordEmail from '@/views/Auth/_components/RestorePasswordEmail';
import RestorePasswordSetNew from '@/views/Auth/_components/RestorePasswordSetNew';

export default [
  // Авторизация
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: AuthGuard.isAuth,
  },
  // Восстановление пароля
  {
    path: '/restore-password',
    name: 'restore-password',
    component: RestorePassword,
    redirect: { name: 'restore-password:email' },
    children: [
      {
        path: '',
        name: 'restore-password:email',
        component: RestorePasswordEmail,
      },
      {
        path: ':token',
        name: 'restore-password:token',
        component: RestorePasswordSetNew,
      },
    ],
  },
  {
    path: '/confirm',
    name: 'api:restore-password',
    props: true,
    beforeEnter: AuthGuard.redirectToSetNewPassword,
  },
];
