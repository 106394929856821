<template>
  <div class="Employee">
    <DictionaryListHead
      type="employees"
      :import-btn="true"
      @create="onCreateDictionaryItem"
      @filter="onFilterDictionaryItems"
      @upload="onImportDictionaryEmployees"
    ></DictionaryListHead>
    <div class="ItemsList">
      <DictionaryListItem v-for="item in list" :key="item.id">
        <template #title>
          <div class="Employee-Header">
            <div class="Left">
              <div class="Employee-Icon">{{ [item.lastName, item.firstName] | firstLetters }}</div>
            </div>
            <div class="Right">
              <div class="Employee-Name">
                {{ [item.lastName, item.firstName, item.middleName] | joinValuesWithSpaces }}
              </div>
              <div class="Employee-Email">
                {{ item.email }}
              </div>
            </div>
          </div>
        </template>
        <template #full-description>
          <div class="Employee-Footer">
            <div class="Employee-Position">
              <div class="Label">{{ $t('labels.employee-position') }}:</div>
              <div class="Value">{{ item.position }}</div>
            </div>
            <div class="Employee-Subdivision">
              <div class="Label">{{ $t('labels.employee-subdivision') }}:</div>
              <div class="Value">{{ item.subdivision }}</div>
            </div>
            <div class="Employee-InCompany">
              <div class="Label">{{ $t('labels.in-company') }}:</div>
              <div class="Value">
                <span>{{ durationDate(item.inCompany) }}</span>
              </div>
            </div>
            <div class="Employee-InPosition">
              <div class="Label">{{ $t('labels.in-position') }}:</div>
              <div class="Value">
                <span>{{ durationDate(item.inPosition) }}</span>
              </div>
            </div>
            <div class="Employee-Experience">
              <div class="Label">{{ $t('labels.work-experience') }}:</div>
              <div class="Value">
                <span>{{ durationDate(item.workExpirience) }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #actions>
          <DictionaryListActions
            type="employees"
            @edit="editDictionaryItem(item.id)"
            @delete="showConfirmAction(item.id)"
          />
        </template>
      </DictionaryListItem>
    </div>
    <DictionaryFormCreateEmployees
      :id="nameModalOfAddDictionaryItem"
      :action-type="updateOrSave"
      :item-model="itemDictionaryModel"
      @save="saveDictionaryItem"
      @update="updateDictionaryItem"
      @cancel="closeDictionaryModal"
    />
    <DictionaryFormImportEmployees
      :id="nameModalOfImportEmployees"
      :errors="importErrors"
      @get-example="onGetExampleUploadFile"
      @upload="onUploadFileOfEmployees"
      @cancel="onCloseImportModal"
    />
    <AppModalConfirm
      :id="nameModalOfConfirmAction"
      :title="$t('modals.directory.delete')"
      @confirm="confirmModalAction"
      @cancel="hideConfirmAction"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DictionaryListHead from '@/views/Estimation/Dictionary/_components/List/DictionaryListHead';
import DictionaryListItem from '@/views/Estimation/Dictionary/_components/List/DictionaryListItem';
import DictionaryListActions from '@/views/Estimation/Dictionary/_components/List/DictionaryListActions';
import DictionaryFormCreateEmployees from '@/views/Estimation/Dictionary/_components/Form/DictionaryFormCreateEmployees';
import DictionaryFormImportEmployees from '@/views/Estimation/Dictionary/_components/Form/DictionaryFormImportEmployees';

import AppModalConfirm from '@/components/App/AppModalConfirm';

import { DictionaryTab } from '@/mixins/DictionaryTab';

import { getExampleFile } from '@/mixins/DownloadFiles';

import { Notification } from '@/services/General/Notification';
import { ObjectFunctions } from '@/services/Helpers/HelperObject';
import { vmoment } from '@/services/Helpers';

import { firstLetters, joinValuesWithSpaces } from '@/services/Filters';

export default {
  name: 'DictionaryTabEmployees',
  components: {
    DictionaryListHead,
    DictionaryListItem,
    DictionaryListActions,
    DictionaryFormCreateEmployees,
    DictionaryFormImportEmployees,
    AppModalConfirm,
  },
  filters: { firstLetters, joinValuesWithSpaces },
  mixins: [DictionaryTab, getExampleFile],
  data: () => ({
    nameModalOfAddDictionaryItem: 'modal-add-employee',
    nameModalOfImportEmployees: 'modal-import-employees',
    nameModalOfConfirmAction: 'modal-confirm-employees',
    targetDeleteDictionaryItem: null,
    itemDictionaryModel: {},
    importErrors: [],
  }),
  computed: {
    ...mapState({
      currentUserInfo: (state) => state.Admin.currentUserInfo,
      employees: (state) => state.Dictionary.employees,
    }),
    dictionaryModel() {
      return this.models.employeesModel;
    },
    currentUserId() {
      return this.currentUserInfo?.id || null;
    },
  },
  watch: {
    employees() {
      this.updateLocalList();
    },
  },
  created() {
    this.updateLocalList();
  },
  mounted() {
    Promise.allSettled([this.getList(), this.getListPositions(), this.getListSubdivisions()]).then(
      () => {
        this.itemDictionaryModel = this.dictionaryModel();
      },
    );
  },
  methods: {
    ...mapActions({
      getList: 'Dictionary/getEmployeesList',
      createItem: 'Dictionary/createEmployeesItem',
      updateItem: 'Dictionary/updateEmployeesItem',
      deleteItem: 'Dictionary/deleteEmployeesItem',
      filterList: 'Dictionary/getFilterEmployeesList',
      getExampleHandler: 'Dictionary/getExampleEmployeesUploadFile',
      importEmployees: 'Dictionary/importEmployees',
      getListPositions: 'Dictionary/getPositionsList',
      getListSubdivisions: 'Dictionary/getSubdivisionsList',
    }),
    updateLocalList() {
      this.list = ObjectFunctions.deepClone(this.employees);
    },
    durationDate(date) {
      if (!date) return this.$t('labels.no-data');
      return vmoment.duration(vmoment(date).diff(vmoment(), 'milliseconds')).humanize();
    },
    onImportDictionaryEmployees() {
      this.$bvModal.show(this.nameModalOfImportEmployees);
    },
    onCloseImportModal() {
      this.$bvModal.hide(this.nameModalOfImportEmployees);
      this.importErrors = [];
    },
    onUploadFileOfEmployees(formData) {
      this.importEmployees(formData).then((response) => {
        if (response.length === 0) {
          this.getList().then(() => {
            Notification.success(this.$t('notifications.success'));
            this.$bvModal.hide(this.nameModalOfImportEmployees);
          });
        } else {
          this.importErrors = response;
        }
      });
    },
    onGetExampleUploadFile() {
      // Метод дергает getExampleHandler
      this.getExampleUploadFile();
    },
  },
};
</script>
