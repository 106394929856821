<template>
  <div v-if="employeeProfileId" class="Profile-Information">
    <BaseProfileBlock>
      <template #head>
        <div class="Title">
          {{ $t('profile.title') }}
        </div>
        <div v-if="isCanEditProfile" class="Action">
          <span class="Button--reset Button" @click="isEditProfile = !isEditProfile">
            <BaseIcon v-if="!isEditProfile" width="12" height="12" name="edit" />
            {{ $t(`base-actions.${!isEditProfile ? 'edit' : 'cancel'}`) }}
          </span>
        </div>
      </template>
      <template v-if="employeeProfile">
        <ProfileInfo v-if="!isEditProfile" :user="employeeProfile" />
        <ProfileInfoForm
          v-else
          :user="employeeProfile"
          @close="isEditProfile = false"
          @save="saveProfileInfoForm"
        />
      </template>
    </BaseProfileBlock>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseProfileBlock from '@/components/Base/BaseProfileBlock';

import ProfileInfo from '@/components/Modules/Profile/_components/ProfileInfo';
import ProfileInfoForm from '@/components/Modules/Profile/_components/ProfileInfoForm';

import { ModelProfile } from '@/store/Models';

export default {
  name: 'UserProfileSide',
  components: {
    BaseIcon,
    BaseProfileBlock,
    ProfileInfo,
    ProfileInfoForm,
  },
  data: () => ({
    employeeProfile: null,
    isEditProfile: false,
  }),
  computed: {
    ...mapState('Users', ['employeeProfileId']),
    ...mapState('Admin', ['currentUserInfo']),
    ...mapGetters('Users', ['userAvailableRoutes']),
    currentEmployeeId() {
      return this.currentUserInfo?.employeeId;
    },
    isCanAnyUploadProfile() {
      return this.checkPermissions(['directory_employee_updateemployeeprofile__invoke']);
    },
    isCanCurrentUploadProfile() {
      return (
        this.checkPermissions(['directory_employee_updatecurrentemployeeprofile__invoke']) &&
        this.employeeProfileId === this.currentEmployeeId
      );
    },
    isCanEditProfile() {
      return this.isCanAnyUploadProfile || this.isCanCurrentUploadProfile;
    },
  },
  mounted() {
    this.getEmployeeData();
  },
  methods: {
    ...mapActions('Users', [
      'getEmployeeProfile',
      'updateAnyEmployeeProfile',
      'updateEmployeeProfile',
    ]),
    getEmployeeData() {
      this.getEmployeeProfile(this.employeeProfileId).then(({ data }) => {
        this.employeeProfile = ModelProfile.ProfilePrepareData(data);
        this.isEditProfile = false;
      });
    },
    saveProfileInfoForm(form) {
      let action = 'updateAnyEmployeeProfile';

      if (this.employeeProfileId === this.currentEmployeeId) {
        action = 'updateEmployeeProfile';
      }

      const data = ModelProfile.ProfileUploadData(form);

      this[action](data).then(() => {
        this.getEmployeeData();
      });
    },
    checkPermissions(permissions) {
      return this.userAvailableRoutes
        ? permissions.some((i) => this.userAvailableRoutes.includes(i))
        : false;
    },
  },
};
</script>
