<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'LayoutLogin',
  created() {
    // Todo: Переработать логику. Данный вариант на скорую руку
    this.$changeTheme('White');
    this.setDarkTheme(false);
    this.$changeBrand('');
    this.setBrand('');
  },
  methods: {
    ...mapMutations('Settings', ['setBrand', 'setDarkTheme']),
  },
};
</script>