<template>
  <div class="AppLogo">
    <img :src="require(`@/assets/Images/Themes/logo-${image}.svg`)" alt="logo" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppLogo',
  props: {
    name: {
      type: String,
      default: 'login',
    },
  },
  computed: {
    ...mapState('Settings', ['isDarkTheme']),
    image() {
      return this.isDarkTheme ? 'dark' : this.name;
    },
  },
};
</script>