import { Api } from '@/services/General/Api';
import { ENDPOINTS_USERS } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    users: [],
    roles: [],
    routes: [],
    isShowingProfile: false,
    employeeProfileId: null,
  },
  mutations: {
    updateUsersList(state, users) {
      state.users = users;
    },
    updateUserRolesList(state, roles) {
      state.roles = roles;
    },
    setAvailableRoutes(state, routes) {
      state.routes = routes;
    },
    setStateOfShowingProfile(state, isShow = false) {
      state.isShowingProfile = isShow;
    },
    setEmployeeProfileId(state, id) {
      state.employeeProfileId = id;
    },
  },
  actions: {
    async getUsersList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_USERS.USERS_GET_LIST }).then(({ data } = {}) => {
          commit('updateUsersList', data);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getUserRolesList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_USERS.USERS_GET_ROLES }).then(({ data } = {}) => {
          commit('updateUserRolesList', data);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getAvailableRoutes({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_USERS.USERS_GET_ROUTES }).then(({ data } = {}) =>
          commit('setAvailableRoutes', data),
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async createUser(_, user = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_USERS.USERS_CREATE_ITEM,
          data: {
            payload: user,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateUser(_, { id = null, data = {} } = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_USERS.USERS_UPDATE_ITEM,
          data: {
            params: { id },
            payload: data,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateUserLocale(_, { id, locale } = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_USERS.USERS_SWITCH_LOCALE,
          data: {
            params: { id },
            payload: { locale },
          },
          notify: false,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteUser(_, id = null) {
      try {
        await Api.delete({
          requestUrl: ENDPOINTS_USERS.USERS_DELETE_ITEM,
          data: {
            params: { id },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async getEmployeeProfile({ commit }, id) {
      try {
        const { data = {} } = await Api.get({
          requestUrl: ENDPOINTS_USERS.USERS_GET_PROFILE,
          data: {
            params: { id },
          },
        });
        return { data };
      } catch (error) {
        throw new Error(error);
      }
    },
    async getEmployeeGeneralProfile({ commit }, id) {
      try {
        const { data = {} } = await Api.get({
          requestUrl: ENDPOINTS_USERS.USERS_GET_GENERAL_INFO,
          data: {
            params: { id },
          },
        });
        return { data };
      } catch (error) {
        throw new Error(error);
      }
    },
    async getEmployeeReportsProfile({ commit }, { id, params = {} } = {}) {
      try {
        const { data = [], count } = await Api.get({
          requestUrl: ENDPOINTS_USERS.USERS_GET_REPORTS,
          data: {
            payload: { params },
            params: { id },
          },
        });
        return { data, count };
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateAnyEmployeeProfile({ commit }, profile) {
      try {
        const { employeeId } = profile;
        await Api.put({
          requestUrl: ENDPOINTS_USERS.USERS_UPDATE_ANY_PROFILE,
          data: {
            payload: profile,
            params: { id: employeeId },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateEmployeeProfile({ commit }, profile) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_USERS.USERS_UPDATE_PROFILE,
          data: {
            payload: profile,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateAnyEmployeeGeneral({ commit }, general) {
      try {
        const { employeeId } = general;
        await Api.put({
          requestUrl: ENDPOINTS_USERS.USERS_UPDATE_ANY_GENERAL,
          data: {
            payload: general,
            params: { id: employeeId },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateEmployeeGeneral({ commit }, general) {
      try {
        await Api.put({
          requestUrl: ENDPOINTS_USERS.USERS_UPDATE_GENERAL,
          data: {
            payload: general,
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {
    userRolesList(state) {
      return state.roles || state.roles?.[0] || [];
    },
    userAvailableRoutes(state) {
      return state.routes.map((route) => route.name);
    },
  },
};
