<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--PositionForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.directory.tabs.positions') }}
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="PositionFormData">
        <div class="PositionFormData-Fields">
          <div class="PositionFormData-Field">
            <div class="Label-Input">{{ $t('labels.position-name') }}:</div>
            <div class="Field">
              <b-form-input
                id="form-name"
                v-model.trim.lazy="$v.form.name.$model"
                :state="validateState('name')"
                class="Input"
                autocomplete="off"
                :placeholder="$t('placeholders.enter-name')"
              ></b-form-input>
            </div>
          </div>
          <div class="PositionFormData-Field">
            <div class="Label-Input">{{ $t('labels.description') }}:</div>
            <div class="Field">
              <b-form-textarea
                id="form-description"
                v-model.trim.lazy="$v.form.description.$model"
                :state="validateState('description')"
                class="Textarea"
                :placeholder="$t('placeholders.enter-text')"
                no-resize
              ></b-form-textarea>
            </div>
          </div>
        </div>
        <div class="PositionFormData-Range">
          <div class="PositionFormData-Range-Head">
            <div class="PositionFormData-Range-Title">{{ $t('labels.competence-group') }}:</div>
            <div class="PositionFormData-Range-Button">
              <span
                class="Button"
                :class="{ 'Button--no-valid': hasCompetenceList }"
                @click="openSelectPositionsModal"
              >
                <BaseIcon class="me-1" width="16" height="16" name="plus" />
                {{ $t('base-actions.add') }}
              </span>
            </div>
          </div>
          <div class="PositionFormData-Range-List">
            <BaseItemList v-for="(competence, index) in form.competenceList" :key="index">
              <span>
                <span class="me-1">{{ index + 1 }}:</span>
                {{ chooseCompetenceName(competence) }}
              </span>
              <span class="Button--reset Button" @click="deleteCompetence(index)">
                <BaseIcon height="16" width="16" name="x-close" />
              </span>
            </BaseItemList>
          </div>
        </div>
      </div>
      <DictionaryFormSelectPositions
        :id="nameModalOfSelectPositions"
        :key="updateKey"
        :items="optionItems"
        :checked-options="checkedOptions"
        @save="saveSelectPositionsModal"
        @cancel="closeSelectPositionsModal"
      />
      <template #footer>
        <BaseActions :actions="modalActions" @save="sendActionType" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseItemList from '@/components/Base/BaseItemList';
import BaseActions from '@/components/Base/BaseActions';

import DictionaryFormSelectPositions from '@/views/Estimation/Dictionary/_components/Form/Modal/DictionaryFormSelectPositions';
import { ObjectFunctions } from '@/services/Helpers';
import { required } from 'vuelidate/lib/validators';

import { toolbarVueEditor } from '@/plugins/VueEditor/OptionsVueEditor';

export default {
  name: 'DictionaryFormCreatePositions',
  components: {
    BaseIcon,
    BaseItemList,
    BaseModalWrap,
    BaseActions,
    DictionaryFormSelectPositions,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    itemModel: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    optionItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    updateKey: 0,
    nameModalOfSelectPositions: 'modal-choose-positions',
    form: {},
    checkedOptions: [],
    toolbarVueEditor: toolbarVueEditor,
  }),
  computed: {
    hasCompetenceList() {
      return (
        !this.validateState('competenceList') &&
        this.form?.competenceList &&
        this.form?.competenceList.length < 1
      );
    },
    modalActions() {
      return [
        { action: 'save', class: 'Button-Main', disabled: this.$v.form.$anyError },
        { action: 'cancel' },
      ];
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      description: {},
      competenceList: {
        required,
      },
    },
  },
  watch: {
    itemModel() {
      this.form = ObjectFunctions.deepClone(this.itemModel);
      this.checkedOptions = this.updateCheckedOptions();
      this.$v.$touch();
    },
  },
  methods: {
    saveSelectPositionsModal(selectedData) {
      this.form.competenceList = selectedData;
      this.closeSelectPositionsModal();
    },
    openSelectPositionsModal() {
      this.$bvModal.show(this.nameModalOfSelectPositions);
    },
    closeSelectPositionsModal() {
      this.$bvModal.hide(this.nameModalOfSelectPositions);
      this.refreshDictionaryFormSelectPositions();
    },
    updateCheckedOptions() {
      return this.form.competenceList.map((item) => {
        return item.id;
      });
    },
    deleteCompetence(index) {
      this.form.competenceList.splice(index, 1);
      this.checkedOptions = this.updateCheckedOptions();
      this.refreshDictionaryFormSelectPositions();
    },
    refreshDictionaryFormSelectPositions() {
      this.checkedOptions = this.updateCheckedOptions();
      this.updateKey++;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    sendActionType() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit(this.actionType, this.form);
      }
    },
    chooseCompetenceName(competence) {
      // Todo: Поле item.name планируется выпиливаться с бека. Задача https://marfatech.atlassian.net/browse/P360-659
      return competence?.nameText?.[this.$i18n.locale] ?? competence?.name ?? '';
    },
  },
};
</script>
