<template>
  <div class="DictionaryListHead">
    <AppListHead>
      <template #search>
        <b-form-input
          v-model.lazy="form.search"
          class="Input--transparent Input--no-border Input"
          :placeholder="$t('placeholders.search')"
          type="text"
          @change="$emit('filter', form)"
        ></b-form-input>
      </template>
      <template #actions>
        <BaseSelect
          v-model="form.sort"
          option-value="name"
          option-key="code"
          :items="sortOptions"
          @input="$emit('filter', form)"
        >
          <template #caret>
            <BaseIcon name="switch-vertical" />
          </template>
        </BaseSelect>
        <span
          v-if="$can(permission.create)"
          class="Button-Main Button OnlyDesktop"
          @click="$emit('create')"
        >
          <BaseIcon name="plus" width="16" height="16" />
          <span class="name ps-2">{{ $t('base-actions.create') }}</span>
        </span>
        <template v-if="importBtn">
          <span v-if="$can(permission.import)" class="Button" @click="$emit('upload')">
            <BaseIcon name="upload" height="14" width="14" />
            <span class="name ps-2">{{ $t('base-actions.upload') }}</span>
          </span>
        </template>
      </template>
    </AppListHead>
    <span
      v-if="$can(permission.create)"
      class="Button-Create Button-Main Button"
      @click="$emit('create')"
    >
      <BaseIcon name="plus" height="24" width="24" />
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseSelect from '@/components/Base/BaseSelect';

import AppListHead from '@/components/App/AppListHead';

export default {
  name: 'DictionaryListHead',
  components: {
    BaseIcon,
    BaseSelect,
    AppListHead,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    importBtn: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    form: {
      search: '',
      sort: 'ASC',
    },
    permissions: {
      competencies: {
        create: ['directory_competence_competence_create'],
      },
      positions: {
        create: ['directory_position_position_create'],
      },
      subdivisions: {
        create: ['directory_subdivision_subdivision_create'],
      },
      employees: {
        create: ['directory_employee_employee_create'],
        import: ['directory_employee_employee_import'],
      },
    },
  }),
  computed: {
    permission() {
      return this.permissions[this.type] || {};
    },
    sortOptions() {
      return [
        { name: this.$t('admin.pages.directory.sort.asc'), code: 'ASC' },
        { name: this.$t('admin.pages.directory.sort.desc'), code: 'DESC' },
      ];
    },
  },
};
</script>