<template>
  <b-modal
    :id="id"
    size="lg"
    body-class="BaseModal BaseModal--TemplateForm"
    content-class="BaseModal-Content"
    centered
    no-fade
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <BaseModalWrap>
      <template #title>
        {{ $t('modals.templates.title') }} <span v-if="title">"{{ title }}"</span>
      </template>
      <template #actions>
        <span class="Button--reset Button" @click="$emit('cancel')">
          <BaseIcon width="20" height="20" name="x-close" />
        </span>
      </template>
      <div class="TemplateFormData">
        <div class="TemplateFormData-Editor">
          <div class="Editor-Wrap" :class="{ 'Editor-Error': !validateStateForm('text') }">
            <VueEditor
              id="form-text"
              ref="editor"
              v-model="form.text"
              :editor-toolbar="toolbarVueEditor"
              @selection-change="onSelectionChange"
              @input="$v.$touch()"
            />
          </div>
        </div>
        <div class="TemplateFormData-Variables">
          <div class="TemplateFormData-Variables-Label">
            { } {{ $t('modals.templates.variables') }}:
          </div>
          <div class="TemplateFormData-Variables-List">
            <BaseDropdown
              v-for="(group, indexGroup) in variablesGroup"
              :key="`Variables-${indexGroup}`"
            >
              <template #toggle>
                <span class="Button Group">{{ $t(`modals.templates.list.${group.id}`) }}</span>
              </template>
              <div class="Variables">
                <div
                  v-for="(item, itemVariable) in group.variableList"
                  :key="`Variable-${group.id}-${itemVariable}`"
                  class="Variables-Item"
                >
                  <div
                    :ref="`id-variable-${indexGroup}-${itemVariable}`"
                    class="Button--width-full Button"
                    @click="onCopyVariable(`id-variable-${indexGroup}-${itemVariable}`, item.id)"
                  >
                    {{ item.id }}
                  </div>
                  <div>
                    <span :id="`id-variable-${indexGroup}-${itemVariable}`">
                      <BaseIcon name="warning" />
                    </span>
                    <b-tooltip
                      :target="`id-variable-${indexGroup}-${itemVariable}`"
                      placement="right"
                    >
                      {{ item.name }}
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </BaseDropdown>
          </div>
        </div>
      </div>
      <template #footer>
        <BaseActions :actions="modalActions" @save="onSaveForm" @cancel="$emit('cancel')" />
      </template>
    </BaseModalWrap>
  </b-modal>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
import BaseModalWrap from '@/components/Base/BaseModalWrap';
import BaseActions from '@/components/Base/BaseActions';
import BaseDropdown from '@/components/Base/BaseDropdown';

import { VueEditor } from 'vue2-editor';

import { required } from 'vuelidate/lib/validators';
import { ObjectFunctions } from '@/services/Helpers';
import { toolbarVueEditor } from '@/plugins/VueEditor/OptionsVueEditor';

export default {
  name: 'TemplatesFormEditDefault',
  components: { BaseIcon, BaseModalWrap, BaseDropdown, BaseActions, VueEditor },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    itemModel: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {},
    variables: [],
    toolbarVueEditor: toolbarVueEditor,
    cursorPosition: null,
  }),
  computed: {
    variablesGroup() {
      return this.itemModel?.variableTypeList || null;
    },
    modalActions() {
      return [{ action: 'save', class: 'Button-Main' }, { action: 'cancel' }];
    },
  },
  validations: {
    form: {
      text: {
        required,
      },
    },
  },
  watch: {
    itemModel() {
      const model = ObjectFunctions.deepClone(this.itemModel);
      this.form = {
        id: model.id,
        text: model.text,
      };
      this.$v.$touch();
    },
  },
  methods: {
    onSelectionChange(selection) {
      const { index = null } = selection || {};
      if (index !== null) {
        this.cursorPosition = index;
      }
    },
    onCopyVariable(nameRef, value) {
      const container = this.$refs[nameRef][0] || this.$refs[nameRef];
      this.$copyText(`${value}`, container).then(() => {
        this.insertCopyVariableToEditor(`${value}`);
      });
    },
    insertCopyVariableToEditor(insertValue) {
      let position = this.cursorPosition || this.$refs?.editor.quill.getLength() || 0;
      this.$refs.editor.quill.insertText(position, insertValue);
    },
    validateStateForm(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSaveForm() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('save', this.form);
      }
    },
  },
};
</script>