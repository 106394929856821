import { Api } from '@/services/General/Api';
import { ENDPOINTS_QUESTIONARY } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    questionaries: [],
    questionarySelectedItem: null,
    questionarySelectedTitle: '',
    questionarySelectedState: false,
    questionariesTesting: [],
    questionariesTestingCount: 0,
  },
  mutations: {
    setQuestionaryList(state, questionaries) {
      state.questionaries = questionaries;
    },
    setQuestionaryTestingList(state, questionaries = []) {
      state.questionariesTesting = questionaries;
    },
    setQuestionaryTestingCount(state, count) {
      state.questionariesTestingCount = count;
    },
    setSelectedQuestionary(state, selected = null) {
      state.questionarySelectedItem = selected;
    },
    setSelectedQuestionaryTitle(state, title = '') {
      state.questionarySelectedTitle = title;
    },
    setSelectedQuestionaryState(state, isShow = false) {
      state.questionarySelectedState = isShow;
    },
  },
  actions: {
    async getQuestionaryList({ commit }, active = 1) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_GET_LIST,
          data: {
            payload: {
              params: {
                active,
              },
            },
          },
        }).then(({ data } = {}) => {
          commit('setQuestionaryList', data);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async filterQuestionaryList(_, filter) {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_GET_LIST,
          data: {
            payload: {
              params: filter,
            },
          },
        });
        return data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getQuestionaryTestingList({ commit }, params) {
      try {
        await Api.get({
          requestUrl: ENDPOINTS_QUESTIONARY.QUESTIONARY_TESTING_LIST,
          data: {
            payload: { params },
          },
        }).then(({ data, count } = {}) => {
          commit('setQuestionaryTestingList', data);
          commit('setQuestionaryTestingCount', count);
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {},
};
