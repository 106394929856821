<template>
  <div class="QuestionaryDetail">
    <div
      class="QuestionaryDetail-Header"
      :class="{ 'QuestionaryDetail-Header--NoMenu': !questionaryMenu }"
    >
      <div class="QuestionaryDetail-Header-Block">
        <QuestionaryNumber :status="questionaryStatus">№ {{ questionaryNumber }}</QuestionaryNumber>
      </div>
      <div class="QuestionaryDetail-Header-Block">
        <QuestionaryMethod :method="questionaryMethod" />
      </div>
      <div v-if="questionaryMenu.length" class="QuestionaryDetail-Header-Block">
        <BaseDropdown>
          <template #toggle>
            <BaseIcon width="22" height="22" name="dots" no-fill no-stroke />
          </template>
          <div class="QuestionaryDetail-Menu">
            <div
              v-for="{ item } in questionaryMenu"
              :key="item.text"
              class="QuestionaryDetail-Menu-Item"
              :class="[item.icon]"
              @click="$emit(item.action)"
            >
              <BaseIcon :name="item.icon" />
              <span :style="item.style">{{ item.text }}</span>
            </div>
          </div>
        </BaseDropdown>
      </div>
    </div>

    <div class="QuestionaryDetail-EmployeesInfo">
      <div class="QuestionaryDetail-Info">
        <div class="QuestionaryDetail-Role">
          {{ questionaryRole }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="QuestionaryDetail-Description" v-html="questionaryDescription"></div>
        <!--eslint-enable-->
      </div>
      <div class="QuestionaryDetail-CreateDate">
        {{ questionaryDateCreated }}
      </div>
    </div>

    <div class="QuestionaryDetail-Data">
      <div class="QuestionaryDetail-Section QuestionaryDetail-QuestionaryDate">
        <div class="LabelName">{{ $t('admin.pages.journal.questionary.datePeriod') }}:</div>
        <div class="DataValue">{{ questionaryDuration }}</div>
      </div>
      <div class="QuestionaryDetail-Section QuestionaryDetail-Employees">
        <div class="LabelName">{{ $t('labels.employees-testing') }}:</div>
        <div class="DataValue">
          <div class="Participants-Group">
            <QuestionaryParticipant
              v-for="(participant, idx) in questionaryEmployees"
              :key="`participants-${idx}`"
              :index="idx + 1"
              :item="participant"
              :group-id="`participants-${idx}`"
              type="employee"
            />
          </div>
        </div>
      </div>
      <div class="QuestionaryDetail-Section QuestionaryDetail-Participants">
        <div class="LabelName">{{ $t('labels.testing') }}:</div>
        <div class="DataValue">
          <div class="Participants">
            <div class="Participants-Names">{{ questionaryParticipantsLabels }}</div>
            <div class="Participants-Groups">
              <div
                v-for="(participants, index) in questionaryParticipantsByGroup"
                :key="`Participants-${index}`"
                class="Participants-Group"
              >
                <QuestionaryParticipant
                  v-for="(participant, idx) in participants"
                  :key="`participants-${index}-${idx}`"
                  :index="idx + 1"
                  :item="participant"
                  :group-id="`participants-${index}-${idx}`"
                  :type="participant.type"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="QuestionaryDetail-Section QuestionaryDetail-Process">
        <div class="LabelName">{{ $t('labels.progress') }}:</div>
        <div class="DataValue">
          <QuestionaryProgress
            v-if="isCanShowProgressBar"
            :total="questionaryProgress.totalCount"
            :passed="questionaryProgress.passedCount"
          />
        </div>
      </div>
      <div class="QuestionaryDetail-Section QuestionaryDetail-Results">
        <div class="LabelName">{{ $t('testing.test-results') }}:</div>
        <div class="DataValue">
          <span class="Anonymously">
            {{ $t('main.anonim') }}
          </span>
        </div>
      </div>
    </div>
    <div class="QuestionaryDetail-Others">
      <QuestionaryInfo
        :questionary="questionary"
        :status="questionaryStatus"
        @questionary-inwork="$emit('questionary-inwork')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseDropdown from '@/components/Base/BaseDropdown';
import QuestionaryMethod from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryMethod';
import QuestionaryNumber from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryNumber';
import QuestionaryParticipant from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryParticipant';
import QuestionaryInfo from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryInfo';
import QuestionaryProgress from '@/views/Estimation/Questionary/_components/QuestionaryDetail/Components/QuestionaryProgress';

import { QuestionaryIconTypeTesting } from '@/constants/Directories/QuestionaryIconTypeTesting';

import { vmoment, differentDate } from '@/services/Helpers';

export default {
  name: 'QuestionaryDetail',
  components: {
    BaseIcon,
    BaseDropdown,
    QuestionaryMethod,
    QuestionaryNumber,
    QuestionaryParticipant,
    QuestionaryInfo,
    QuestionaryProgress,
  },
  props: {
    questionary: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showProgressBarForType: ['inwork', 'done'],
    questionaryIcons: QuestionaryIconTypeTesting,
  }),
  computed: {
    ...mapGetters('Users', ['userAvailableRoutes']),
    actionsMenu() {
      return {
        edit: {
          text: this.$t('modal-nemu.edit'),
          action: 'questionary-edit',
          icon: 'edit',
        },
        duplicate: {
          text: this.$t('modal-nemu.duplicate'),
          action: 'questionary-duplicate',
          icon: 'copy',
        },
        archive: {
          text: this.$t('modal-nemu.archive'),
          action: 'questionary-archive',
          icon: 'archive',
        },
        delete: {
          text: this.$t('modal-nemu.basket'),
          action: 'questionary-delete',
          icon: 'trash',
        },
      };
    },
    questionaryId() {
      return this.questionary?.id || null;
    },
    questionaryStatus() {
      return this.questionary?.status || null;
    },
    questionaryMenu() {
      let menu = [];
      switch (this.questionaryStatus) {
        case 'created': {
          menu = [
            {
              item: this.actionsMenu.edit,
              permissions: ['questionary_questionary_update'],
            },
            {
              item: this.actionsMenu.duplicate,
              permissions: ['questionary_questionary_duplicate'],
            },
            {
              item: this.actionsMenu.archive,
              permissions: ['questionary_questionary_statusupdate'],
            },
            {
              item: this.actionsMenu.delete,
              permissions: ['questionary_questionary_delete'],
            },
          ];
          break;
        }
        case 'inwork': {
          menu = [
            {
              item: this.actionsMenu.edit,
              permissions: ['questionary_questionary_update'],
            },
            {
              item: this.actionsMenu.archive,
              permissions: ['questionary_questionary_statusupdate'],
            },
          ];
          break;
        }
        case 'done': {
          menu = [
            {
              item: this.actionsMenu.archive,
              permissions: ['questionary_questionary_statusupdate'],
            },
          ];
          break;
        }
        default: {
          break;
        }
      }
      return menu.filter(({ permissions }) =>
        permissions.some((i) => this.userAvailableRoutes.includes(i)),
      );
    },
    questionaryDuration() {
      return `${vmoment(this.questionary.dateStart).format('D MMM YYYY')} - ${vmoment(
        this.questionary.dateEnd,
      ).format('D MMM YYYY')}`;
    },
    questionaryMethod() {
      return this.questionary?.method || null;
    },
    questionaryNumber() {
      return this.questionary?.number || '';
    },
    questionaryDateCreated() {
      return this.questionary
        ? vmoment(this.questionary?.createdAt).format('DD.MM.YYYY HH:mm')
        : null;
    },
    questionaryType() {
      return this.questionary?.type || null;
    },
    questionaryRole() {
      if (this.questionaryType === 'surname') {
        const [employee] = this.questionary?.employeeList || null;
        return employee ? `${employee.lastName} ${employee.firstName}` : null;
      }
      return this.questionary?.position?.name || this.questionary?.subdivision?.name || null;
    },
    questionaryEmployees() {
      return this.questionary?.employeeList || [];
    },
    questionaryParticipants() {
      return this.questionary?.participantList || [];
    },
    questionaryParticipantsByGroup() {
      return (
        this.questionary?.participantList.reduce((acc, i) => {
          if (!acc[i.type]) acc[i.type] = [];
          acc[i.type].push(i);
          return acc;
        }, {}) || []
      );
    },
    questionaryParticipantsLabels() {
      return typeof this.questionaryParticipantsByGroup === 'object'
        ? Object.keys(this.questionaryParticipantsByGroup)
            .map(
              (item) =>
                `${this.$t(`participants.${item}`)} (${
                  this.questionaryParticipantsByGroup?.[item].length
                })`,
            )
            .join(', ')
        : null;
    },
    questionaryDescription() {
      const employee = this.questionaryEmployees?.[0];
      const email = employee?.email || null;
      const position = employee?.position || null;
      const subdivision = employee?.subdivision || null;
      const timeInCompany = differentDate(employee?.inCompany) || null;
      const timeInPosition = differentDate(employee?.inPosition) || null;
      const workExpirience = differentDate(employee?.workExpirience) || null;
      return this.questionaryType === 'surname'
        ? `
        <div class="QuestionaryDetail-Description--Email">${email}</div>
        <div class="QuestionaryDetail-Description--PosSub">${
          [position, subdivision].join(', ') || ''
        }</div>
        <div class="QuestionaryDetail-Description--Time">
          <div class="QuestionaryDetail-Section">
            <div class="LabelName">${this.$t('labels.in-company')}:</div>
            <div class="DataValue">${timeInCompany}</div>
          </div>
          <div class="QuestionaryDetail-Section">
            <div class="LabelName">${this.$t('labels.in-position')}:</div>
            <div class="DataValue">${timeInPosition}</div>
          </div>
          <div class="QuestionaryDetail-Section">
            <div class="LabelName">${this.$t('labels.work-experience')}:</div>
            <div class="DataValue">${workExpirience}</div>
          </div>
        </div>
      `
        : ``;
    },
    questionaryIsAnonim() {
      return this.questionary?.isAnonymous || false;
    },
    questionaryProgress() {
      return (
        this.questionary?.progressList.reduce((acc, i) => {
          const { totalCount, passedCount } = i;
          acc = { totalCount, passedCount };
          return acc;
        }, {}) || {}
      );
    },
    isCanShowProgressBar() {
      return this.showProgressBarForType.includes(this.questionaryStatus);
    },
  },
};
</script>
