import Vue from 'vue';
import { Api } from '@/services/General/Api';
import { ENDPOINTS_DICTIONARY, ENDPOINTS_STRUCTURE } from '@/services/Endpoints';

export default {
  namespaced: true,
  state: {
    structureEmployees: {},
    structureSubdivisions: [],
  },
  mutations: {
    setStructureEmployees(state, { subdivision, count }) {
      Vue.set(state.structureEmployees, subdivision, count);
    },
    setStructureSubdivisionsList(state, subdivisions) {
      state.structureSubdivisions = subdivisions;
    },
  },
  actions: {
    async getStructureSubdivisionsList({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_DICTIONARY.STRUCTURE_SUBDIVISIONS_GET_LIST }).then(
          ({ data } = {}) => {
            commit('setStructureSubdivisionsList', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async getStructurePositionsList() {
      try {
        const { data = [] } = await Api.get({
          requestUrl: ENDPOINTS_DICTIONARY.STRUCTURE_POSITIONS_GET_LIST,
        });
        return { data };
      } catch (error) {
        throw new Error(error);
      }
    },
    async getStructureEmployees({ commit }, params = {}) {
      const defaultParams = {
        limit: 1000,
        offset: 0,
      };

      try {
        const { data = [], count } = await Api.get({
          requestUrl: ENDPOINTS_STRUCTURE.EMPLOYEES,
          data: {
            payload: { params: { ...defaultParams, ...params } },
          },
        });
        if (params['filterList[subdivisionId]']) {
          commit('setStructureEmployees', {
            subdivision: params['filterList[subdivisionId]'],
            count,
          });
        }
        return { data, count };
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {
    structureEmployeesCount(state) {
      return Object.values(state.structureEmployees).reduce((acc, i) => {
        acc += i;
        return acc;
      }, 0);
    },
  },
};
