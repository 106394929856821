<template>
  <div class="DictionaryListActions">
    <span
      v-if="$can(permission.edit)"
      class="Button--width-auto Button-InTables Button"
      @click="$emit('edit')"
    >
      <BaseIcon height="16" width="16" name="edit" />
      <span class="Text">{{ $t('base-actions.edit') }}</span>
    </span>
    <span
      v-if="$can(permission.copy)"
      class="Button--width-auto Button-InTables Button"
      @click="$emit('copy')"
    >
      <BaseIcon height="16" width="16" name="copy" />
      <span class="Text">{{ $t('base-actions.copy') }}</span>
    </span>
    <span
      v-if="$can(permission.delete)"
      class="Button--width-auto Button-InTables Button"
      @click="$emit('delete')"
    >
      <BaseIcon height="16" width="16" name="trash" />
      <span class="Text">{{ $t('base-actions.delete') }}</span>
    </span>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';
export default {
  name: 'DictionaryListActions',
  components: { BaseIcon },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    permissions: {
      competencies: {
        edit: ['directory_competence_competence_update'],
        delete: ['directory_competence_competence_delete'],
        copy: ['directory_competence_competence_create'],
      },
      positions: {
        edit: ['directory_position_position_update'],
        delete: ['directory_position_position_delete'],
        copy: [],
      },
      subdivisions: {
        edit: ['directory_subdivision_subdivision_update'],
        delete: ['directory_subdivision_subdivision_delete'],
        copy: [],
      },
      employees: {
        edit: ['directory_employee_employee_update'],
        delete: ['directory_employee_employee_delete'],
        copy: [],
      },
    },
  }),
  computed: {
    permission() {
      return this.permissions[this.type] || {};
    },
  },
};
</script>
